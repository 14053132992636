// import { address, networks } from 'bitcoinjs-lib';
// import * as bs58check from 'bs58check';
// import { sha256 } from 'js-sha256';

export const shortenAddress = (address = '', charsStart = 6, charsEnd = 4): string => {
  if (!address) return '';
  return `${address.slice(0, charsStart)}...${address.slice(address.length - charsEnd)}`;
};

export const shortenHex = (hex: string, length = 4): string =>
  `${hex.substring(0, length + (hex.startsWith('0x') ? 2 : 0))}…${hex.substring(hex.length - length)}`;

export const isDataValid = (data: any) => {
  if (
    data !== null &&
    data !== '' &&
    data !== 'undefined' &&
    data !== 'null' &&
    data !== undefined &&
    !(typeof data === 'number' && isNaN(data))
  ) {
    return true;
  } else {
    return false;
  }
};
export const displayValue = (data: any) => {
  if (
    data !== null &&
    data !== '' &&
    data !== 'undefined' &&
    data !== 'null' &&
    data !== undefined &&
    !(typeof data === 'number' && isNaN(data))
  ) {
    return data;
  } else {
    return '--';
  }
};

export function tranGetParams(param?: { [x: string]: any }) {
  if (!isDataValid(param)) {
    return '';
  }
  const jsonStr = JSON.stringify(param);
  if (jsonStr != '{}') {
    let totalParamStr = '?';
    let mark = '';
    let i = 0;
    for (const key in param) {
      if (i > 0) {
        mark = '&';
      }
      const value = param[key];
      const paramStr = mark + key + '=' + value;
      totalParamStr += paramStr;
      i++;
    }
    return totalParamStr;
  } else {
    return '';
  }
}

// export function formatSatoshiPrice(bitcoinPrice: number, satoshiAmount: number, fixed: number = 2): string {
//   if (!isDataValid(bitcoinPrice) || !isDataValid(satoshiAmount)) {
//     return '--';
//   }
//   const satoshiPrice = (bitcoinPrice / 100000000) * satoshiAmount;

//   if (satoshiPrice >= 1000000) {
//     const millionPart = (satoshiPrice / 1000000)
//       .toFixed(fixed)
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//       .replace(/\.00$/, '');
//     return `${millionPart}M`;
//   } else if (satoshiPrice >= 1000) {
//     const thousandPart = (satoshiPrice / 1000)
//       .toFixed(fixed)
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//       .replace(/\.00$/, '');
//     return `${thousandPart}K`;
//   } else {
//     return satoshiPrice.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }
// }

export function formatSatoshiPrice(bitcoinPrice: number, satoshiAmount: number, fixed: number = 2): string {
  if (!isDataValid(bitcoinPrice) || !isDataValid(satoshiAmount)) {
    return '--';
  }
  const satoshiPrice = (bitcoinPrice / 100000000) * satoshiAmount;

  if (satoshiPrice >= 1000000) {
    const millionPart = (satoshiPrice / 1000000)
      .toFixed(fixed)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      .replace(/\.00$/, '');
    return `${millionPart}M`;
  } else if (satoshiPrice >= 1000) {
    const thousandPart = (satoshiPrice / 1000)
      .toFixed(fixed)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      .replace(/\.00$/, '');
    return `${thousandPart}K`;
  } else {
    return satoshiPrice.toFixed(fixed).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
}

export function addThousandSeparator(number: number) {
  if (!isDataValid(number)) {
    return '--';
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const toXOnly = (pubKey: Buffer) => (pubKey.length === 32 ? pubKey : pubKey.slice(1, 33));
