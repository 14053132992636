import { useState, useEffect, useRef, useCallback } from 'react';
import { message } from 'antd';
import { getChallenge, verifySignature } from 'api';
import type { Capability } from 'sats-connect';
import { useNavigate, useLocation } from 'react-router-dom';
import { AddressPurpose, BitcoinNetworkType, getAddress, BitcoinProvider, signMessage } from 'sats-connect';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateToken } from 'store/features/applicationSlice';
import { changeWalletName, changeAddress, changeSelctWalletName } from 'store/features/applicationSlice';
import { showConncting, hideConncting } from 'store/features/globalSlice';
import _ from 'lodash';

export const useWalletConnect = () => {
  const wallet = useAppSelector((state) => state.application.walletName);
  const selectWalletName = useAppSelector((state) => state.application.selectWalletName);
  const token = useAppSelector((state) => state.application.token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [atomInstalled, setAtomInstalled] = useState(false);
  const [okxInstalled, setOkxInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [publicKey, setPublicKey] = useState('');
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });
  const [network, setNetwork] = useState('livenet');
  const [netXversework, setNetXversework] = useState<BitcoinNetworkType>(BitcoinNetworkType.Mainnet);

  const selfUnisatRef = useRef<{ accounts: string[]; name: string }>({ accounts: [], name: '' });
  const selfUnisat = selfUnisatRef.current;
  const selfAtomRef = useRef<{ accounts: string[]; name: string }>({ accounts: [], name: '' });
  const selfAtom = selfAtomRef.current;
  const selfOkxRef = useRef<{ accounts: string[]; name: string }>({ accounts: [], name: '' });
  const selfOkx = selfOkxRef.current;
  const selfXverseRef = useRef<{ name: string }>({ name: '' });
  const selfXverse = selfXverseRef.current;
  const unisat = (window as any).unisat;
  const atom = (window as any).atom;
  const okxwallet = (window as any).okxwallet;
  const xverse = (window as any).XverseProviders;

  const getBasicInfo = useCallback(async () => {
    if (wallet === 'UniSat Wallet') {
      const [address] = await unisat.getAccounts();
      setAddress(address);
      dispatch(changeAddress(address));
      const publicKey = await unisat.getPublicKey();
      setPublicKey(publicKey);
      // const balance = await unisat.getBalance();
      // setBalance(balance);
      const network = await unisat.getNetwork();
      setNetwork(network);
    } else if (wallet === 'Wizz Wallet') {
      const [address] = await atom.getAccounts();
      setAddress(address);
      dispatch(changeAddress(address));
      const publicKey = await atom.getPublicKey();
      setPublicKey(publicKey);
      // const balance = await atom.getBalance();
      // setBalance(balance);
      const network = await atom.getNetwork();
      setNetwork(network);
    }
  }, [wallet, unisat, dispatch, atom]);

  const handleDisConnect = useCallback(() => {
    dispatch(changeWalletName(''));
    dispatch(changeSelctWalletName(''));
    setConnected(false);
    setAccounts([]);
    setPublicKey('');
    setAddress('');
    dispatch(changeAddress(''));
    dispatch(updateToken({ token: '' }));
    setBalance({
      confirmed: 0,
      unconfirmed: 0,
      total: 0,
    });
    selfOkx.name = '';
    selfOkx.accounts = [];
    selfUnisat.accounts = [];
    selfAtom.accounts = [];
    selfUnisat.name = '';
    selfAtom.name = '';
    setNetwork('livenet');
    // okxwallet?.disconnect();
  }, [dispatch, selfAtom, selfOkx, selfUnisat]);

  const handleUnisatAccountsChanged = _.debounce(
    useCallback(
      (_accounts: string[], accountsChange: boolean = false) => {
        console.log(accountsChange);
        if (_accounts.length > 0) {
          dispatch(showConncting());
          getChallenge()
            .then((res: any) => {
              unisat
                .getPublicKey()
                .then((pubkey: string) => {
                  unisat
                    .signMessage(res.data.challenge)
                    .then((signature: any) => {
                      verifySignature({
                        session_id: res.data.session_id,
                        address: _accounts[0],
                        chain_id: 'bitcoin',
                        wallet: 'unisat',
                        signature,
                        pubkey,
                      })
                        .then((verifySignature: any) => {
                          dispatch(hideConncting());
                          dispatch(updateToken({ token: verifySignature.data }));
                          if (selfUnisat.accounts[0] === _accounts[0]) {
                            return;
                          }
                          selfUnisat.accounts = _accounts;
                          setAccounts(_accounts);
                          setConnected(true);
                          dispatch(changeAddress(_accounts[0]));
                          getBasicInfo();
                        })
                        .catch((err) => {
                          console.log(err);
                          handleDisConnect();
                          dispatch(hideConncting());
                        });
                    })
                    .catch((err: any) => {
                      message.error(err.message);
                      if (accountsChange) {
                        handleDisConnect();
                      }
                      dispatch(hideConncting());
                    });
                })
                .catch(() => {});
            })
            .catch((err) => {
              message.error(err.message);
              handleDisConnect();
              dispatch(hideConncting());
            });
        } else {
          handleDisConnect();
          dispatch(hideConncting());
        }
      },
      [dispatch, getBasicInfo, handleDisConnect, selfUnisat, unisat],
    ),
    100,
  );

  // const handleUnisatNetworkChanged = useCallback(
  //   (network: string) => {
  //     if (selfUnisat.name === 'UniSat Wallet') {
  //       setNetwork(network);
  //       getBasicInfo();
  //     }
  //   },
  //   [getBasicInfo, selfUnisat],
  // );

  // useEffect(() => {
  //   async function checkUnisat() {
  //     if (unisat) {
  //       setUnisatInstalled(true);
  //     } else if (!unisat) return;

  //     unisat.getAccounts().then((accounts: string[]) => {
  //       handleUnisatAccountsChanged(accounts);
  //     });
  //     unisat.on('accountsChanged', handleUnisatAccountsChanged);
  //     unisat.on('networkChanged', handleUnisatNetworkChanged);
  //     return () => {
  //       unisat.removeListener('accountsChanged', handleUnisatAccountsChanged);
  //       unisat.removeListener('networkChanged', handleUnisatNetworkChanged);
  //     };
  //   }
  //   checkUnisat().then();
  // }, [handleUnisatAccountsChanged, handleUnisatNetworkChanged, unisat, wallet]);

  const handleAtomAccountsChanged = _.debounce(
    useCallback(
      (_accounts: string[], accountsChange: boolean = false) => {
        if (_accounts.length > 0) {
          dispatch(showConncting());
          getChallenge()
            .then((res: any) => {
              atom
                .getPublicKey()
                .then((pubkey: string) => {
                  atom
                    .signMessage(res.data.challenge)
                    .then((signature: any) => {
                      verifySignature({
                        session_id: res.data.session_id,
                        address: _accounts[0],
                        chain_id: 'bitcoin',
                        wallet: 'atom',
                        signature,
                        pubkey,
                      })
                        .then((verifySignature: any) => {
                          dispatch(hideConncting());
                          dispatch(updateToken({ token: verifySignature.data }));
                          if (selfAtom.accounts[0] === _accounts[0]) {
                            return;
                          }
                          selfAtom.accounts = _accounts;
                          setAccounts(_accounts);
                          setConnected(true);
                          dispatch(changeAddress(_accounts[0]));
                          getBasicInfo();
                        })
                        .catch((err) => {
                          message.error(err.message);
                          handleDisConnect();
                          dispatch(hideConncting());
                        });
                    })
                    .catch((err: any) => {
                      message.error(err.message);
                      if (accountsChange) {
                        handleDisConnect();
                      }
                      dispatch(hideConncting());
                    });
                })
                .catch(() => {});
            })
            .catch((err) => {
              console.log(err);
              handleDisConnect();
              dispatch(hideConncting());
            });
        } else {
          handleDisConnect();
          dispatch(hideConncting());
        }
      },
      [atom, dispatch, getBasicInfo, handleDisConnect, selfAtom],
    ),
    100,
  );

  const handleOkxAccountChanged = _.debounce(
    useCallback(
      (_accounts: string[], accountsChange: boolean = false) => {
        if (_accounts.length > 0) {
          dispatch(showConncting());
          getChallenge()
            .then((res: any) => {
              okxwallet?.bitcoin
                .getPublicKey()
                .then((pubkey: string) => {
                  okxwallet?.bitcoin
                    .signMessage(res.data.challenge)
                    .then((signature: any) => {
                      verifySignature({
                        session_id: res.data.session_id,
                        address: _accounts[0],
                        chain_id: 'bitcoin',
                        wallet: 'okx',
                        signature,
                        pubkey,
                      })
                        .then((verifySignature: any) => {
                          dispatch(hideConncting());
                          dispatch(updateToken({ token: verifySignature.data }));
                          if (selfOkx.accounts[0] === _accounts[0]) {
                            return;
                          }
                          selfOkx.accounts = _accounts;
                          setAccounts(_accounts);
                          setConnected(true);
                          dispatch(changeAddress(_accounts[0]));
                        })
                        .catch((err) => {
                          message.error(err.message);
                          handleDisConnect();
                          dispatch(hideConncting());
                        });
                    })
                    .catch((err: any) => {
                      message.error(err.message);
                      if (accountsChange) {
                        handleDisConnect();
                      }
                      dispatch(hideConncting());
                    });
                })
                .catch(() => {});
            })
            .catch((err) => {
              console.log(err);
              handleDisConnect();
              dispatch(hideConncting());
            });
        } else {
          handleDisConnect();
          dispatch(hideConncting());
        }
      },
      [dispatch, handleDisConnect, okxwallet?.bitcoin, selfOkx],
    ),
    100,
  );

  // const handleAtomNetworkChanged = useCallback(
  //   (network: string) => {
  //     if (selfAtom.name === 'Wizz Wallet') {
  //       setNetwork(network);
  //       getBasicInfo();
  //     }
  //   },
  //   [getBasicInfo, selfAtom],
  // );

  const onConnectClick = useCallback(async () => {
    await getAddress({
      payload: {
        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: '2140',
        network: {
          type: netXversework,
        },
      },
      onFinish: (response) => {
        const ordinalsAddressItem = response.addresses.find((address) => address.purpose === AddressPurpose.Ordinals);
        if (ordinalsAddressItem?.address) {
          getChallenge()
            .then((res: any) => {
              signMessage({
                payload: {
                  network: {
                    type: netXversework,
                  },
                  address: ordinalsAddressItem?.address,
                  message: res.data.challenge,
                },
                onFinish: (signature) => {
                  // signature
                  console.log(signature);
                  verifySignature({
                    session_id: res.data.session_id,
                    address: ordinalsAddressItem?.address,
                    chain_id: 'bitcoin',
                    wallet: 'xverse',
                    signature,
                  })
                    .then((verifySignature: any) => {
                      dispatch(hideConncting());
                      dispatch(updateToken({ token: verifySignature.data }));
                      setAccounts([ordinalsAddressItem?.address]);
                      setConnected(true);
                      // const paymentAddressItem = response.addresses.find((address) => address.purpose === AddressPurpose.Payment);
                      // console.log(paymentAddressItem);
                      // setAddress(paymentAddressItem?.address || '');
                      // setPublicKey(paymentAddressItem?.publicKey || '');
                      setAddress(ordinalsAddressItem?.address || '');
                      setPublicKey(ordinalsAddressItem?.publicKey || '');
                      dispatch(changeAddress(ordinalsAddressItem?.address || ''));
                      // getBalance https://mempool.space/api/address/bc1pmekfce7ptwzc6yffy9kde53rh2cmrll8rd79hue9alvd2x6dm48su57mh2
                    })
                    .catch((err) => {
                      console.log(err);
                      handleDisConnect();
                      dispatch(hideConncting());
                    });
                },
                onCancel: () => {
                  message.error('Request canceled');
                  handleDisConnect();
                  dispatch(hideConncting());
                },
              });
            })
            .catch((err) => {
              console.log(err);
              handleDisConnect();
              dispatch(hideConncting());
            });
        } else {
          handleDisConnect();
          dispatch(hideConncting());
        }
      },
      onCancel: () => {
        message.error('Request canceled');
        dispatch(changeWalletName(''));
        dispatch(hideConncting());
      },
    });
  }, [dispatch, handleDisConnect, netXversework]);

  useEffect(() => {
    if (!unisat || selectWalletName !== 'UniSat Wallet') return;
    unisat.on('accountsChanged', handleUnisatAccountsChanged);
    // unisat.on('networkChanged', handleUnisatNetworkChanged);
    return () => {
      unisat.removeListener('accountsChanged', handleUnisatAccountsChanged);
      // unisat.removeListener('networkChanged', handleUnisatNetworkChanged);
    };
  }, [handleUnisatAccountsChanged, selectWalletName, unisat]);

  useEffect(() => {
    if (!atom || selectWalletName !== 'Wizz Wallet') return;
    atom.on('accountsChanged', handleAtomAccountsChanged);
    // atom.on('networkChanged', handleAtomNetworkChanged);
    return () => {
      atom.removeListener('accountsChanged', handleAtomAccountsChanged);
      // atom.removeListener('networkChanged', handleAtomNetworkChanged);
    };
  }, [atom, handleAtomAccountsChanged, selectWalletName]);

  useEffect(() => {
    if (!okxwallet || selectWalletName !== 'OKX Wallet') return;
    okxwallet.bitcoin.on('accountsChanged', handleOkxAccountChanged);

    return () => {
      okxwallet.bitcoin.removeListener('accountsChanged', handleOkxAccountChanged);
    };
  }, [handleOkxAccountChanged, okxwallet, selectWalletName]);

  useEffect(() => {
    if (token) return;
    if (wallet === 'UniSat Wallet' && unisat && selfUnisat.name !== wallet) {
      selfUnisat.name = wallet;
      dispatch(changeSelctWalletName(wallet));
      selfAtom.accounts = [];
      selfOkx.accounts = [];
      selfAtom.name = '';
      selfOkx.name = '';
      selfXverse.name = '';
      dispatch(showConncting());
      unisat
        .requestAccounts()
        .then((result: any) => {
          handleUnisatAccountsChanged(result, true);
          // unisat
          //   .getBalance()
          //   .then((res: any) => console.log(res))
          //   .catch((err: any) => message.error(err.message));
        })
        .catch((err: any) => {
          message.error(err.message);
          handleDisConnect();
          dispatch(hideConncting());
          dispatch(changeWalletName(''));
        });
    } else if (wallet === 'Wizz Wallet' && atom && selfAtom.name !== wallet) {
      dispatch(changeSelctWalletName(wallet));
      selfAtom.name = wallet;
      selfUnisat.accounts = [];
      selfOkx.accounts = [];
      selfUnisat.name = '';
      selfOkx.name = '';
      selfXverse.name = '';
      atom
        .requestAccounts()
        .then((result: any) => {
          handleAtomAccountsChanged(result, true);
          // atom
          //   .getBalance()
          //   .then((res: any) => console.log(res))
          //   .catch((err: any) => message.error(err.message));
        })
        .catch((err: any) => {
          message.error(err.message);
          handleDisConnect();
          dispatch(hideConncting());
          dispatch(changeWalletName(''));
        });
    } else if (wallet === 'OKX Wallet' && okxwallet && okxwallet?.bitcoin && selfOkx.name !== wallet) {
      dispatch(changeSelctWalletName(wallet));
      selfOkx.name = wallet;
      selfUnisat.accounts = [];
      selfAtom.accounts = [];
      selfUnisat.name = '';
      selfAtom.name = '';
      selfXverse.name = '';
      dispatch(showConncting());
      okxwallet?.bitcoin
        .requestAccounts()
        .then((result: any) => {
          handleOkxAccountChanged(result, true);
          // okxwallet?.bitcoin
          //   .getBalance()
          //   .then((res: any) => console.log(res))
          //   .catch((err: any) => message.error(err.message));
        })
        .catch((err: any) => {
          message.error(err.message);
          handleDisConnect();
          dispatch(hideConncting());
          dispatch(changeWalletName(''));
        });
    } else if (wallet === 'Xverse Wallet' && xverse) {
      selfXverse.name = wallet;
      selfUnisat.accounts = [];
      selfAtom.accounts = [];
      selfOkx.accounts = [];
      selfUnisat.name = '';
      selfAtom.name = '';
      selfOkx.name = '';
      dispatch(showConncting());
      onConnectClick();
    }
  }, [
    atom,
    handleAtomAccountsChanged,
    handleOkxAccountChanged,
    handleUnisatAccountsChanged,
    selfAtom,
    selfOkx,
    selfUnisat,
    selfXverse,
    unisat,
    wallet,
    okxwallet,
    xverse,
    onConnectClick,
    dispatch,
    token,
    handleDisConnect,
  ]);

  return {
    address,
    handleDisConnect,
    handleAtomAccountsChanged,
    handleOkxAccountChanged,
    handleUnisatAccountsChanged,
    unisatInstalled,
    atomInstalled,
    okxInstalled,
    unisat,
    atom,
    okxwallet,
  };
};
