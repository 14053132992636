import { useI18n } from 'react-simple-i18n';
import enUS from './en-US';
import zhCN from './zh-CN';
import zh_CN from 'antd/locale/zh_CN';
import en_US from 'antd/locale/en_US';

export const langData: { [x: string]: any } = {
  enUS,
  zhCN,
};

export const langInfo = [
  {
    value: 'enUS',
    label: 'English',
  },
  {
    value: 'zhCN',
    label: '简体中文',
  },
];

export const langInfoLabel: { [x: string]: any } = {
  enUS: 'English',
  zhCN: '简体中文',
};

export const antdLang: { [x: string]: any } = {
  enUS: en_US,
  zhCN: zh_CN,
};

export const GetTranslatedGreeting = (text: string) => {
  const { t } = useI18n();
  return t(text);
};
