import { useState } from 'react';
import { shortenAddress } from 'utils';
import { message, Select, ConfigProvider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import { searchTickers } from 'api';
import { useWallet } from 'hooks/useWallet';
import _ from 'lodash';

const SearchSelect = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useI18n();
  const { address } = useWallet();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [respType, setRespType] = useState(0);

  const handleSearch = _.debounce((newValue: string) => {
    if (newValue) {
      setLoading(true);
      searchTickers({ str_search: newValue })
        .then((res: any) => {
          setRespType(res.data.resp_type);
          if (res.data.resp_type === 2) {
            setData([
              {
                atomical_number: res.data.address,
                ticker_name: shortenAddress(res.data.address),
              },
            ]);
          } else {
            setData(res.data.response_tickers || []);
          }
          setLoading(false);
        })
        .catch((e: any) => {
          message.error(e.message);
          setLoading(false);
        });
    } else {
      setData([]);
    }
  }, 300);

  const handleChange = (newValue: any) => {
    if (newValue || newValue === 0) {
      setValue(newValue);
    } else {
      setData([]);
      setValue(newValue);
      setRespType(0);
    }
  };

  const onSelect = (newValue: any) => {
    if (respType === 1) {
      const selectItem = _.filter(data, ['atomical_number', newValue])[0];
      navigate(
        {
          pathname: `/marketplace/listing/${newValue}`,
        },
        { state: { atomicalId: selectItem.atomical_id } },
      );
    } else {
      navigate(
        {
          pathname: `/assets/${newValue}`,
        },
        { state: { address: newValue } },
      );
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: '#0e1011',
          colorBgContainer: '#202527',
        },
        components: {
          Input: {
            colorBorder: '#202527',
          },
        },
      }}
    >
      <Select
        showSearch
        value={value}
        defaultActiveFirstOption={false}
        loading={loading}
        placeholder={t('global.searchPlh')}
        style={{ width: isMobile ? '70%' : '250px' }}
        allowClear
        filterOption={false}
        onSearch={handleSearch}
        suffixIcon={<SearchOutlined />}
        fieldNames={{ label: 'ticker_name', value: 'atomical_number' }}
        onChange={handleChange}
        notFoundContent={null}
        options={data || []}
        onSelect={onSelect}
      />
    </ConfigProvider>
  );
};

export default SearchSelect;
