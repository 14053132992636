export default {
  global: {
    connectWallet: '连接钱包',
    disconnect: '断开连接',
    unisatWallet: 'UniSat钱包',
    wizzWallet: 'Wizz钱包（前ATOM钱包）',
    okxWallet: 'OKX钱包',
    ok: '确定',
    nextStep: '下一步',
    previous: '上一步',
    searchPlh: '搜索代币、收藏品、账户',
    market: '2140Marketplace',
    noData: '暂无数据',
    loading: '加载中...',
    marketDisabled1: '市场现已停用。',
    marketDisabled2: '请关注后续信息。',
    transactionSubmitted: '您的交易已提交！',
  },
  marketpalce: {
    banner1: '原子物市场',
    banner2: '在2140赋能Atomicals资产交易',
    banner3: '欢迎来到2140Marketplace：您的Atomicals资产首选交易中心。',
    banner4: `体验无缝交易，享受高阶功能，包括上架、批量上架、取消上架、拆分、扫单和资产查看。`,
    banner5: '2140Marketplace',
    banner6: '赋能Atomicals资产交易',
    listSplit: '上架/拆分',
    all: '全部',
    myFavorites: '我的收藏',
    token: '代币',
    price: '价格',
    dayChange: '24小时变动',
    totalVolume: '总交易量',
    marketCap: '市值',
    totalSupply: '总供应量',
    holders: '持有者',
  },
  assets: {
    arc20balance: 'ARC20余额',
    tokens: '代币',
    btcBalance: 'BTC余额',
    floorPrice: '地板价',
    value: '价值',
    action: '操作',
    balance: '余额',
  },
  listing: {
    listed: '已上架',
    history: '历史',
    myOrder: '我的订单',
    tokenDetails: '代币详情',
    totalVolume: '总成交量',
    dayVolume: '24小时成交量',
    minted: '铸造',
    totalSupply: '总供应量',
    sweep: '扫单',
    list: '上架',
    split: '拆分',
    lowToHigh: '价格：从低到高',
    highToLow: '价格：从高到低',
    buy: '购买',
    mintAmount: '铸造数量',
    maxMints: '最大铸造量',
    mintHeight: '铸币高度',
    commitBitwork: '提交比特工作',
    revealBitwork: '揭示比特工作',
    deployedBy: '部署者',
    deployedAt: '部署时间',
    receiveAddress: '接收地址',
    receiveAddressPlh: '默认显示当前钱包地址',
    receiveAddressTip: '无效的比特币地址',
    invalid: '无效',
    slow: '慢',
    avg: '平均',
    fast: '快',
    custom: '自定义',
    serviceFee: '服务费',
    timeLimitedFree: '限时免费',
    totalCost: '总费用:',
    listForSale: '上架卖出',
    sellPrice: '出售价格',
    sell: '出售',
    sellPriceTip: '请输入价格',
    sellAmount: '出售数量',
    limitedTimeFree: '限时免费',
    totalAmount: '总量',
    selectUTXO: '选择要拆分的UTXO',
    splitSize: '选择拆分大小',
    splitDetails: '拆分详情',
    sales: '卖出',
    amount: '数量',
    unitPrice: '单价',
    seller: '卖家',
    buyer: '买家',
    time: '时间',
    unlist: '取消上架',
    sold: '已售出',
    event: '事件',
    checkAll: '全选',
    unlistSelect: '取消上架选择',
    atomicalId: 'Atomical Id',
    networkFee: '网络费用:',
    removedFavorites: '已从收藏夹中移除',
    addFavorites: '已添加到收藏夹',
    noListed: '未找到已上架订单',
  },
  menu: {
    marketpalce: '市场',
    assets: '资产',
    language: '语言',
  },
};
