import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { closeWalletModal } from 'store/features/globalSlice';
import { changeWalletName } from 'store/features/applicationSlice';
import { Button, Modal, Flex, ConfigProvider, Image } from 'antd';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import unisat from 'assets/common/unisat.png';
import atom from 'assets/common/atom.png';
import wizz from 'assets/common/wizz.svg';
import okx from 'assets/common/okx.png';
import xverse from 'assets/common/xverse.png';

const connectBtnStyle: React.CSSProperties = {
  height: '64px',
  borderRadius: '8px',
  boxShadow: 'unset',
  textAlign: 'left',
};

export default function WalletModal(): JSX.Element | null {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const walletModalOpen = useAppSelector((state) => state.global.walletModalOpen);

  const wallets = [
    { name: 'UniSat Wallet', label: t('global.unisatWallet'), icon: unisat },
    { name: 'Wizz Wallet', label: t('global.wizzWallet'), icon: wizz },
    { name: 'OKX Wallet', label: t('global.okxWallet'), icon: okx },
    // { name: 'Xverse Wallet', icon: xverse },
  ];

  return (
    <Modal
      title={t('global.connectWallet')}
      footer={null}
      width={384}
      focusTriggerAfterClose={false}
      open={walletModalOpen}
      centered
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{ header: { background: '#17191D' }, content: { background: '#17191D', borderRadius: 8 } }}
      onCancel={() => dispatch(closeWalletModal())}
    >
      <ConfigProvider
        theme={{
          token: { colorPrimary: '#1B1F27' },
        }}
      >
        <Flex vertical={true} gap={16} style={{ padding: '12px 0' }}>
          {wallets.map(({ name, label, icon }) => (
            <Button
              key={name}
              type="primary"
              style={connectBtnStyle}
              onClick={() => {
                dispatch(changeWalletName(name));
                dispatch(closeWalletModal());
              }}
            >
              <Image width={48} src={icon} preview={false} />
              <span style={{ marginLeft: '20px', fontSize: '16px' }}>{label}</span>
            </Button>
          ))}
        </Flex>
      </ConfigProvider>
    </Modal>
  );
}
