import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { Button, Modal, Flex } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { shortenAddress, addThousandSeparator } from 'utils';
import moment from 'moment';

const connectBtnStyle: React.CSSProperties = {
  height: '36px',
  borderRadius: '4px',
  marginTop: '16px',
  width: '100%',
};

export default function CurrencyDetailsModal({
  open,
  onCancel,
  tickerDetail,
}: {
  open: boolean;
  onCancel: () => void;
  tickerDetail: { [x: string]: any };
}): JSX.Element {
  const { t } = useI18n();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const viewportHeight = window.innerHeight;
  const bodyHeight = document.body.offsetHeight;
  // if (!isMobile && viewportHeight >= bodyHeight) {
  //   document.body.style.overflowY = 'scroll';
  // }
  const { mint_amount, deployed_by, max_mints, deployed_at } = tickerDetail;
  const { mint_height, commit_bitwork, reveal_bitwork, atomical_id } = tickerDetail;

  // const detailList = [
  //   { title: 'Deployed', value: '2023/03/09 13:32:14' },
  //   { title: 'Inscription ID', value: '9b664b...9333i0' },
  //   { title: 'Deployed by', value: 'bc1prt...ulcn' },
  //   { title: 'Inscription starting no.', value: '#357097' },
  //   { title: 'Inscription edning no.', value: '#34539796' },
  //   { title: 'limit per mint', value: '100,000,000' },
  //   { title: 'Total supply', value: '100,000,000,000' },
  //   { title: 'Minted', value: displayValue(mint_ed) },
  //   { title: 'Decimal', value: '80' },
  // ];
  const detailList = [
    // { title: 'Atomicals Id', value: shortenAddress(atomical_id, 6, 4) },
    { title: t('listing.mintAmount'), value: addThousandSeparator(mint_amount) },
    { title: t('listing.maxMints'), value: addThousandSeparator(max_mints) },
    { title: t('listing.mintHeight'), value: mint_height },
    { title: t('listing.commitBitwork'), value: commit_bitwork || '--' },
    { title: t('listing.revealBitwork'), value: reveal_bitwork || '--' },
    { title: t('listing.deployedBy'), value: shortenAddress(deployed_by, 10, 8) || '--' },
    { title: t('listing.deployedAt'), value: deployed_at || '--' },
  ];

  return (
    <Modal
      title={t('listing.tokenDetails')}
      footer={null}
      width={584}
      focusTriggerAfterClose={false}
      open={open}
      centered
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{ header: { background: '#2B2F35' }, content: { background: '#2B2F35', borderRadius: 8 } }}
      onCancel={onCancel}
    >
      <Flex vertical={true} gap={8} style={{ padding: '12px 0 0' }}>
        {detailList.map(({ title, value }) => (
          <Flex key={title} align="center" justify="space-between" flex={1}>
            <span style={{ color: '#858E9C' }}>{title}</span>
            <span style={{ color: '#C7C9CE' }}>{value}</span>
          </Flex>
        ))}
      </Flex>
      <Button type="primary" style={connectBtnStyle} onClick={onCancel}>
        <span style={{ fontSize: '16px', color: '#1C2025', fontWeight: 700 }}>{t('global.ok')}</span>
      </Button>
    </Modal>
  );
}
