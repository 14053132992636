import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
export interface GlobalState {
  isLoading: boolean;
  walletModalOpen: boolean;
  toastInfo: {
    open: boolean;
    type: string;
    value: string;
  };
  tradeInfo: boolean;
  connecting: boolean;
  openSweepPage: boolean;
  openBuyPage: boolean;
  openSellPage: boolean;
  reloadFollowPage: number;
  marketDisable: boolean;
}

const initialState: GlobalState = {
  isLoading: false,
  walletModalOpen: false,
  toastInfo: {
    open: false,
    type: 'success',
    value: '',
  },
  tradeInfo: false,
  connecting: false,
  openSweepPage: false,
  openBuyPage: false,
  openSellPage: false,
  reloadFollowPage: 1,
  marketDisable: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showLoad: (state) => {
      state.isLoading = true;
    },
    hideLoad: (state) => {
      state.isLoading = false;
    },
    showWalletModal: (state) => {
      state.walletModalOpen = true;
    },
    closeWalletModal: (state) => {
      state.walletModalOpen = false;
    },
    updateToastInfo: (state, action) => {
      const { toastInfo } = action.payload;
      state.toastInfo = { ...state.toastInfo, ...toastInfo };
    },
    showTradeInfo: (state) => {
      state.tradeInfo = true;
    },
    hideTradeInfo: (state) => {
      state.tradeInfo = false;
    },
    showConncting: (state) => {
      state.connecting = true;
    },
    hideConncting: (state) => {
      state.connecting = false;
    },
    showSweepPage: (state) => {
      state.openSweepPage = true;
    },
    hideSweepPage: (state) => {
      state.openSweepPage = false;
    },
    showMarket: (state) => {
      state.marketDisable = false;
    },
    hideMarket: (state) => {
      state.marketDisable = true;
    },
    showBuyPage: (state) => {
      state.openBuyPage = true;
    },
    hideBuyPage: (state) => {
      state.openBuyPage = false;
    },
    showSellPage: (state) => {
      state.openSellPage = true;
    },
    hideSellPage: (state) => {
      state.openSellPage = false;
    },
    reloadFollow: (state, action) => {
      const time = action.payload;
      state.reloadFollowPage = time;
    },
  },
});

export const {
  showLoad,
  hideLoad,
  updateToastInfo,
  showWalletModal,
  closeWalletModal,
  showTradeInfo,
  hideTradeInfo,
  showConncting,
  hideConncting,
  showSweepPage,
  hideSweepPage,
  showBuyPage,
  hideBuyPage,
  showSellPage,
  hideSellPage,
  reloadFollow,
  showMarket,
  hideMarket,
} = globalSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default globalSlice.reducer;
