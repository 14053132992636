import { GET, POST, GETURL } from 'utils/request';
import urls from 'utils/urls';

export async function getTickerList(params: { [x: string]: any }) {
  return GET(urls.tickerList, params);
}

export async function getMyFavorites() {
  return GET(urls.myFavorites);
}

export async function getChallenge(params?: { [x: string]: any }) {
  return GET(urls.challenge, params);
}

export async function verifySignature(body: { [x: string]: any }) {
  return POST(urls.verifySignature, body);
}

export async function getTickerDetail(tickerId: string) {
  return GET(urls.getTickerDetail(tickerId));
}

export async function followuser(params?: { [x: string]: any }) {
  return POST(urls.followuser, params);
}

export async function getTickerListing(tickerId: string, params?: { [x: string]: any }) {
  return GET(urls.getTickerList(tickerId), params);
}

export async function sweepList(tickerId: string, params?: { [x: string]: any }) {
  return GET(urls.getSweepList(tickerId), params);
}

export async function getHistoryList(tickerId: string, params?: { [x: string]: any }) {
  return GET(urls.getHistoryList(tickerId), params);
}

export async function unlist(params?: { [x: string]: any }) {
  return POST(urls.unlist, params);
}

export async function getMyorders(params?: { [x: string]: any }) {
  return GET(urls.myorders, params);
}

export async function getAtomicals() {
  return GET(urls.atomicals);
}

export async function getArc20(tickerId: string) {
  return GET(urls.arc20(tickerId));
}

export async function getArc20split(atomicalId: string) {
  return GET(urls.arc20split(atomicalId));
}

export async function splitutxo(params?: { [x: string]: any }) {
  return POST(urls.splitutxo, params);
}

export async function confirmsplitutxo(params?: { [x: string]: any }) {
  return POST(urls.confirmsplitutxo, params);
}

export async function estimatefee() {
  return GET(urls.estimatefee);
}

export async function marketfeebp(params?: { [x: string]: any }) {
  return GET(urls.marketfeebp, params);
}

export async function bulkbuy(params?: { [x: string]: any }) {
  return POST(urls.bulkbuy, params);
}

export async function confirmbulkbuy(params?: { [x: string]: any }) {
  return POST(urls.confirmbulkbuy, params);
}

export async function bulksell(params?: { [x: string]: any }) {
  return POST(urls.bulksell, params);
}

export async function confirmbulksell(params?: { [x: string]: any }) {
  return POST(urls.confirmbulksell, params);
}

export async function searchTickers(params?: { [x: string]: any }) {
  return GET(urls.searchTickers, params);
}

export async function getAddressInfo(address: string) {
  return GET(urls.addressInfo(address));
}

export async function getNetworkGas(url: string) {
  return GETURL(url);
}

export async function cancelpayment(params?: { [x: string]: any }) {
  return POST(urls.cancelpayment, params);
}

export async function logout() {
  return GET(urls.logout);
}
