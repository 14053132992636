import { useState, useEffect, useCallback } from 'react';
import {
  Flex,
  Avatar,
  Checkbox,
  Button,
  ConfigProvider,
  Progress,
  Card,
  Table,
  Image,
  message,
  Pagination,
  Empty,
  Spin,
} from 'antd';
import { useI18n } from 'react-simple-i18n';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import type { ColumnsType, TableProps } from 'antd/es/table';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import btc from 'assets/common/btc.png';
import mempool from 'assets/common/mempool.png';
import empty from 'assets/common/empty.svg';
import { CopyIcon } from 'assets/icon';
import { CustomLocale, indicator } from 'constant';
import { getMyorders } from 'api';
import useCopyClipboard from 'hooks/useCopyClipboard';
import { shortenAddress, addThousandSeparator, formatSatoshiPrice } from 'utils';
import { showWalletModal } from 'store/features/globalSlice';
import { useWallet } from 'hooks/useWallet';
import { useAppDispatch } from 'hooks/redux';
import _ from 'lodash';

interface DataType {
  key: React.Key;
  icon: string;
  token: string;
  price: number;
  risesFallsDay: string;
  totalTradingDay: string;
  totalValue: string;
  marketValue: string;
  totalSupply: string;
  tokenHolders: string;
  operate: string;
}

const orderCardTitleStyle: React.CSSProperties = {
  fontSize: 12,
  fontWeight: 700,
  color: '#848E9C',
};

const MyOrder = ({
  tab,
  seconds,
  unlistToken,
  atomicalNumber,
}: {
  tab: number;
  seconds: number;
  atomicalNumber: number;
  unlistToken: (list: string[], callback?: () => void) => void;
}) => {
  const [, setCopied] = useCopyClipboard();
  const { t } = useI18n();
  const { address } = useWallet();
  const dispatch = useAppDispatch();
  const [myOrderList, setMyOrderList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [type, setType] = useState('all');
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isPad = useMediaQuery({ query: '(max-width: 992px)' });
  const headerNumber = isMobile ? 3 : isPad ? 6 : 9;
  const typeList = ['all', 'listed', 'unlist', 'sold'];
  const typeListLabel = [t('marketpalce.all'), t('listing.listed'), t('listing.unlist'), t('listing.sold')];
  const orders_no = myOrderList.map((item: any) => item.order_no);
  const checkAll =
    _.isEmpty(_.difference(selectedRowKeys, orders_no)) && _.isEmpty(_.difference(orders_no, selectedRowKeys));
  const indeterminate = selectedRowKeys.length > 0 && selectedRowKeys.length < myOrderList.length;

  const myordersGet = useCallback(
    (params: { [x: string]: any } = {}) => {
      isMobile ? setSpinning(true) : setLoading(true);
      getMyorders({
        page_size: pageSize,
        page_index: page - 1,
        atomical_number: atomicalNumber,
        type,
        ...params,
      })
        .then((res: any) => {
          setMyOrderList(res.data.SalesList);
          setTotalCount(res.data.Total);
          setLoading(false);
          setSpinning(false);
        })
        .catch((e: any) => {
          setLoading(false);
          setSpinning(false);
          message.error(e.message);
        });
    },
    [atomicalNumber, isMobile, page, pageSize, type],
  );

  useEffect(() => {
    if (address) {
      myordersGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (seconds === 0 && page === 1 && tab === 3 && address) {
      myordersGet();
    }
  }, [myordersGet, page, seconds, tab, address]);

  useEffect(() => {
    if (tab === 3 && address) {
      myordersGet();
    }
  }, [myordersGet, tab, address]);

  const columnsOrder: ColumnsType<DataType> = [
    {
      title: t('marketpalce.token'),
      dataIndex: 'ticker_name',
      render: (text, record) => {
        return (
          <Flex align="center" gap={10}>
            <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>{text}</span>
            <span style={{ fontSize: 8, fontWeight: 500, color: '#848E9C' }}>{'arc20'}</span>
          </Flex>
        );
      },
    },
    {
      title: t('listing.event'),
      dataIndex: 'order_status',
      render: (text, record) => {
        return (
          <span
            style={{ color: text === 1 ? '#30BD65' : text === 2 ? '#5D6470' : '#EB4B6D', textTransform: 'capitalize' }}
          >
            {typeListLabel[text]}
          </span>
        );
      },
    },
    {
      title: t('marketpalce.price'),
      dataIndex: 'unit_price',
      render: (text, record) => {
        return <span style={{ fontWeight: 700 }}>{`${text} sats`}</span>;
      },
    },
    {
      title: t('listing.amount'),
      dataIndex: 'amount',
      render: (text, record) => {
        return <span style={{ fontWeight: 700 }}>{addThousandSeparator(text)}</span>;
      },
    },
    {
      title: t('listing.buyer'),
      dataIndex: 'buyer',
      render: (text, record) => (
        <Flex align="center" gap={4} onClick={() => setCopied(text)}>
          <span style={{ fontSize: 14 }}>{shortenAddress(text) || '--'}</span>
          {text ? <CopyIcon style={{ cursor: 'pointer' }} /> : null}
        </Flex>
      ),
    },
    {
      title: t('listing.seller'),
      dataIndex: 'seller',
      render: (text, record) => (
        <Flex align="center" gap={4} onClick={() => setCopied(text)}>
          <span style={{ fontSize: 14 }}>{shortenAddress(text)}</span>
          <CopyIcon style={{ cursor: 'pointer' }} />
        </Flex>
      ),
    },
    {
      title: t('listing.time'),
      dataIndex: 'time',
      render: (text) => {
        return <span style={{ fontSize: 12, fontWeight: 700 }}>{text}</span>;
      },
    },
    {
      title: t('assets.action'),
      dataIndex: 'tx_hash',
      render: (text, record: any) => {
        return text ? (
          <Image
            width={14}
            style={{ cursor: 'pointer' }}
            src={mempool}
            preview={false}
            onClick={() => window.open(`https://mempool.space/tx/${text}`)}
          />
        ) : record.order_status !== 1 ? (
          '--'
        ) : (
          <ConfigProvider
            theme={{ token: { colorPrimary: '#EB4B6D' }, components: { Button: { paddingInline: 0, fontSize: 12 } } }}
          >
            <Button
              type="primary"
              shape="round"
              size="small"
              ghost
              onClick={() => unlistToken([record.order_no], () => myordersGet())}
              style={{ marginRight: '-10px' }}
            >
              {t('listing.unlist')}
            </Button>
          </ConfigProvider>
        );
      },
    },
  ];

  const handleChange = (value: string) => {
    setType(value);
    setMyOrderList([]);
    myordersGet({ type: value, page_index: 0, page_size: 10 });
    setPage(1);
    setPageSize(10);
    setTotalCount(0);
    setSelectedRowKeys([]);
  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    const { current, pageSize } = pagination;
    onPageChange(current, pageSize);
  };

  const onPageChange = (current: any, page_size: any) => {
    setPage(page_size === pageSize ? current : 1);
    setPageSize(page_size);
    myordersGet({
      page_index: page_size === pageSize ? current - 1 : 0,
      page_size,
    });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onCheckAllChange = (e: any) => {
    const orders_no = myOrderList.map((item: any) => item.order_no);
    setSelectedRowKeys(e.target.checked ? orders_no : []);
  };

  const onCheckChange = (e: any, order_no: any) => {
    if (e.target.checked) {
      setSelectedRowKeys(_.union(selectedRowKeys, [order_no]));
    } else {
      setSelectedRowKeys(_.difference(selectedRowKeys, [order_no]));
    }
  };

  return (
    <>
      <Flex
        vertical={true}
        style={{
          display: tab === 3 && address ? 'flex' : 'none',
          borderTop: isMobile ? 'unset' : '0.2px solid #343B44',
          padding: isMobile ? '8px 0 20px' : '20px 0',
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} spinning={spinning} fullscreen />
        <Flex
          align={isMobile ? 'flex-start' : 'center'}
          gap={isMobile ? 16 : 2}
          justify="space-between"
          vertical={isMobile ? true : false}
        >
          <Flex
            gap={isMobile ? 8 : 16}
            style={{ width: '100%' }}
            align="center"
            wrap="wrap"
            justify={isMobile ? 'space-between' : 'flex-start'}
          >
            <ConfigProvider
              theme={{
                token: { borderRadius: 4 },
                components: { Button: { defaultBg: '#5D6470', boxShadow: 'none' } },
              }}
            >
              {typeList.map((el, i) => (
                <Button
                  key={el}
                  style={{
                    borderWidth: 0,
                    color: type === el ? '#000' : '#fff',
                    textTransform: 'capitalize',
                  }}
                  type={type === el ? 'primary' : 'default'}
                  onClick={() => handleChange(el)}
                >
                  {typeListLabel[i]}
                </Button>
              ))}
              {myOrderList.length > 0 && isMobile && type === 'listed' && (
                <Checkbox
                  style={{ height: 32, alignItems: 'center' }}
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t('listing.checkAll')}
                </Checkbox>
              )}
            </ConfigProvider>
            <ConfigProvider
              theme={{
                token: { borderRadius: 4, colorPrimary: '#30bd65' },
                components: { Button: { boxShadow: 'none' } },
              }}
            >
              {selectedRowKeys.length > 0 && type === 'listed' ? (
                <Button
                  style={{ color: '#000', marginRight: 'auto' }}
                  type={'primary'}
                  onClick={() => unlistToken(selectedRowKeys, () => myordersGet())}
                >
                  {t('listing.unlistSelect')}
                </Button>
              ) : null}
            </ConfigProvider>
          </Flex>
          <Flex gap={16} align="center">
            <ConfigProvider
              theme={{
                token: { borderRadius: 4 },
                components: { Progress: { circleTextColor: seconds > 4 ? '#4CC675' : '#ff4d4f' } },
              }}
            >
              <MediaQuery minWidth={576}>
                <Button icon={<ReloadOutlined />} onClick={() => myordersGet()} style={{ boxShadow: 'unset' }} />
                <Progress
                  type="circle"
                  percent={seconds * 10}
                  strokeWidth={4}
                  strokeColor={seconds > 4 ? '#4CC675' : '#ff4d4f'}
                  format={() => seconds}
                  size={30}
                />
              </MediaQuery>
            </ConfigProvider>
          </Flex>
        </Flex>
        <MediaQuery minWidth={576}>
          <Table
            bordered={false}
            showSorterTooltip={false}
            size="small"
            loading={{
              spinning: loading,
              ...indicator,
            }}
            locale={CustomLocale(loading)}
            style={{ marginTop: '24px' }}
            pagination={{ pageSize, current: page, total: totalCount, showSizeChanger: true }}
            rowKey="order_no"
            {...(type === 'listed' && { rowSelection })}
            columns={
              isMobile
                ? columnsOrder.slice(0, headerNumber)
                : type === 'listed'
                  ? _.filter(columnsOrder, (e) => e.title !== t('listing.buyer'))
                  : columnsOrder.slice(0, type === 'unlist' ? columnsOrder.length - 1 : columnsOrder.length)
            }
            dataSource={myOrderList}
            onChange={onChange}
          />
        </MediaQuery>
        <MediaQuery maxWidth={576}>
          <Flex gap={16} className="myOrder listBox">
            {myOrderList.map((el: any, i) => (
              <Card key={i}>
                <Flex vertical={true} style={{ height: '100%' }} justify="space-between">
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    <Flex align="flex-start" vertical style={{ width: '45%' }}>
                      <span style={{ ...orderCardTitleStyle, color: '#FDD535', textTransform: 'uppercase' }}>
                        {el.ticker_name}
                      </span>
                      <span style={{ fontSize: 10, color: '#848E9C' }}>ARC20</span>
                    </Flex>
                    <Flex align="center" justify="space-between" style={{ width: '55%' }}>
                      <Flex align="flex-start" vertical>
                        <span style={orderCardTitleStyle}>{t('listing.atomicalId')}</span>
                        <span style={{ fontSize: 12 }}>{shortenAddress(el.atomical_id)}</span>
                      </Flex>
                      <Flex align="flex-end" vertical>
                        <span style={orderCardTitleStyle}>{t('listing.event')}</span>
                        <span
                          style={{
                            color: el.order_status === 1 ? '#30BD65' : el.order_status === 2 ? '#5D6470' : '#EB4B6D',
                            textTransform: 'capitalize',
                            fontSize: 12,
                          }}
                        >
                          {typeListLabel[el.order_status]}
                        </span>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex justify="space-between" style={{ width: '100%' }}>
                    <Flex align="flex-start" vertical style={{ width: '45%' }}>
                      <span style={orderCardTitleStyle}>{t('listing.amount')}</span>
                      <span style={{ fontSize: 10, fontWeight: 700, color: '#EAECEF' }}>
                        {addThousandSeparator(el.amount)}
                      </span>
                    </Flex>
                    <Flex justify="space-between" style={{ width: '55%' }}>
                      <Flex align="flex-start" vertical>
                        <span style={orderCardTitleStyle}>{t('marketpalce.price')}</span>
                        <Flex align="center" gap={4}>
                          <Avatar size={14} src={btc} />
                          <span style={{ fontSize: 12 }}>{(el.unit_price / 100000000).toFixed(8)}</span>
                        </Flex>
                        <span style={{ fontSize: 10, color: '#848E9C' }}>{`$${formatSatoshiPrice(
                          el.btc_usdt_price,
                          el.unit_price,
                          4,
                        )}`}</span>
                      </Flex>
                      <Flex align="flex-end" vertical>
                        <span style={orderCardTitleStyle}>{t('listing.seller')}</span>
                        <span style={{ fontSize: 12 }}>{shortenAddress(el.seller)}</span>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    {/* <span style={{ fontSize: isMobile ? 12 : 14, fontWeight: 500 }}>ATOM</span> */}
                    <Flex align="flex-start" vertical style={{ width: '45%' }}>
                      <span style={orderCardTitleStyle}>{t('listing.buyer')}</span>
                      <span style={{ fontSize: 10, color: '#EAECEF' }}>{shortenAddress(el.buyer) || '--'}</span>
                    </Flex>
                    <Flex align="center" justify="space-between" style={{ width: '55%' }}>
                      <Flex align="flex-start" vertical>
                        <span style={orderCardTitleStyle}>{t('listing.time')}</span>
                        <span style={{ fontSize: 10, fontWeight: 700 }}>{el.time}</span>
                      </Flex>
                      <Flex align="flex-end" vertical>
                        <span style={orderCardTitleStyle}>{el.tx_hash ? 'Transaction' : 'Action'}</span>
                        {el.tx_hash ? (
                          <Image
                            width={14}
                            style={{ cursor: 'pointer' }}
                            src={mempool}
                            preview={false}
                            onClick={() => window.open(`https://mempool.space/tx/${el.tx_hash}`)}
                          />
                        ) : el.order_status !== 1 ? (
                          '--'
                        ) : (
                          <ConfigProvider
                            theme={{
                              token: { colorPrimary: '#EB4B6D' },
                              components: { Button: { paddingInline: 0, fontSize: 12 } },
                            }}
                          >
                            <Button
                              type="primary"
                              shape="round"
                              size="small"
                              ghost
                              onClick={() => unlistToken([el.order_no], () => myordersGet())}
                              style={{ marginRight: '-10px' }}
                            >
                              {t('listing.unlist')}
                            </Button>
                          </ConfigProvider>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                {type === 'listed' && (
                  <Checkbox
                    checked={_.includes(selectedRowKeys, el.order_no)}
                    style={{ position: 'absolute', right: 4, top: 2 }}
                    onChange={(e) => onCheckChange(e, el.order_no)}
                  />
                )}
              </Card>
            ))}
          </Flex>
          {myOrderList.length === 0 && (
            <Flex style={{ height: 160 }} justify="center" align="center">
              <Empty
                image={empty}
                imageStyle={{ height: 100 }}
                description={<span style={{ fontWeight: 700, color: '#798391' }}>{t('global.noData')}</span>}
              />
            </Flex>
          )}
          {totalCount > 0 && (
            <Flex style={{ height: 40 }} justify="flex-end" align="flex-end">
              <Pagination
                current={page}
                pageSize={pageSize}
                defaultCurrent={1}
                onChange={onPageChange}
                total={totalCount}
                size="small"
                showSizeChanger
                onShowSizeChange={() => {}}
              />
            </Flex>
          )}
        </MediaQuery>
        {/* <MediaQuery minWidth={576}>
      <Flex gap={16} style={{ marginTop: 16 }} align="center">
        <Button style={{ color: '#000' }} type="primary">
          <span style={{ marginRight: 40, fontSize: 14 }}>Buy 2 ITEM</span>
          <span style={{ fontSize: 12 }}>0.48 BTC</span>
        </Button>
        <span>OR $ 2408.01</span>
      </Flex>
    </MediaQuery> */}
      </Flex>
      <Flex
        style={{ width: '100%', height: 120, display: tab === 3 && !address ? 'flex' : 'none' }}
        justify="center"
        align="center"
      >
        <Button
          style={{ color: '#000', paddingInline: isMobile ? 6 : 14, textTransform: 'uppercase' }}
          type="primary"
          onClick={() => dispatch(showWalletModal())}
        >
          {t('global.connectWallet')}
        </Button>
      </Flex>
    </>
  );
};

export default MyOrder;
