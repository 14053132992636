import { useState, memo, useEffect, useRef } from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { Button, Modal, Flex, ConfigProvider, Input, Divider, Slider, Form, Avatar, message, Spin, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { useWallet } from 'hooks/useWallet';
import { marketfeebp, bulksell, confirmbulksell, getArc20 } from 'api';
import { formatSatoshiPrice, shortenAddress } from 'utils';
import { updateErrorTxids } from 'store/features/txidSlice';
import empty from 'assets/common/empty.svg';
import _ from 'lodash';
import './index.css';

type FieldType = {
  tokenname?: string;
  price?: string;
  amount?: string;
  recevieaddress?: string;
  remember?: string;
};

const tagPrimaryStyle: React.CSSProperties = {
  height: '22px',
  padding: '0 6px',
  borderRadius: '4px',
  color: '#FDD535',
  background: '#fdd53566',
  alignItems: 'center',
  fontSize: 10,
  fontWeight: 700,
};

export default memo(function SellModal({
  open,
  onCancel,
  tickerName,
  floorPrice,
  btcUsdtPrice,
  tickerIcon,
  tickerId,
  atomicalId,
  reload,
}: {
  open: boolean;
  onCancel: () => void;
  tickerName: string;
  floorPrice: number;
  btcUsdtPrice: number;
  tickerId: number;
  tickerIcon: string;
  atomicalId: string;
  reload?: () => void;
}): JSX.Element | null {
  const { t } = useI18n();
  const { address, signPsbt } = useWallet();
  const errorTxids = useAppSelector((state) => state.txid.errorTxids);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [price, setPrice] = useState(0);
  const [makerFee, setMakerFee] = useState(0);
  const [pending, setPending] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [utxo, setUtxo] = useState<any[]>([]);
  const [listscripthash, setListscripthash] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const usdtValue = formatSatoshiPrice(btcUsdtPrice, price, 3);
  const allSats = useRef(0);

  useEffect(() => {
    marketfeebp({ ticker_id: tickerId })
      .then((res: any) => {
        setMakerFee(res.data.maker_fee_bp / 100);
      })
      .catch((e: any) => {
        message.error(e.message);
      });
  }, [tickerId]);

  useEffect(() => {
    setLoading(true);
    getArc20(atomicalId)
      .then((arc20: any) => {
        const utxos = arc20?.data?.utxos?.map((item: any) => ({ ...item, utxyId: `${item.txid}${item.vout}` })) || [];
        setUtxo(utxos);
        setListscripthash(utxos);
        setSliderValue(utxos.length);
        setLoading(false);
      })
      .catch((e: any) => {
        message.error(e.message);
        setUtxo([]);
        setLoading(false);
      });
  }, [atomicalId]);

  useEffect(() => {
    allSats.current = 0;
    utxo.forEach((element) => {
      allSats.current = allSats.current + element.value;
    });
  }, [utxo]);

  const delUtxo = (ux: any[]) => {
    if (utxo.length < 2) return;
    const newUtxo = _.differenceBy(utxo, ux, 'utxyId');
    setUtxo(newUtxo);
    setSliderValue(newUtxo.length);
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setPending(true);
    const { recevieaddress, price } = values;
    const sells = utxo.map((item) => ({
      atomical_number: Number(tickerId),
      atom_utxo_txid: item.txid,
      atom_utxo_value: item.value,
      atom_utxo_vout: item.vout,
      price: Number(price),
      seller_receive_address: recevieaddress,
    }));
    bulksell({
      atomical_number: Number(tickerId),
      sells: sells,
    })
      .then((res: any) => {
        if (res.code === 32021) {
          message.error(res.message);
          setPending(false);
          dispatch(updateErrorTxids([res.data.invalid_utxo_txid]));
        } else {
          signPsbt(res.data.unsigned_psbt, (psbt) => {
            if (psbt === 'error') {
              setPending(false);
            } else {
              confirmbulksell({
                id: res.data.id,
                signed_pstb: psbt,
              })
                .then((confirmRes: any) => {
                  message.success('Your list is now complete!');
                  setPending(false);
                  reload && reload();
                  onCancel();
                  // setOpenTradeResult(true);
                })
                .catch((e: any) => {
                  message.error(e.message);
                  setPending(false);
                });
            }
          });
        }
      })
      .catch((e: any) => {
        message.error(e.message);
        setPending(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={
        !isMobile ? (
          <span style={{ color: '#D0E2EF', fontSize: 18, fontWeight: 700 }}>{t('listing.listForSale')}</span>
        ) : (
          ''
        )
      }
      closable={!isMobile}
      footer={null}
      width={640}
      focusTriggerAfterClose={false}
      open={open}
      centered
      destroyOnClose={true}
      // afterOpenChange={(open) => {
      //   if (isMobile) {
      //     document.body.style.overflow = open ? 'hidden' : 'auto';
      //   } else {
      //     document.body.style.height = open ? 'var(--doc-height)' : 'unset';
      //   }
      // }}
      afterOpenChange={(open) => {
        if (!isMobile) {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      className="smSellModal"
      {...(isMobile && { transitionName: '', maskTransitionName: '' })}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
      onCancel={() => {
        onCancel();
        setPrice(0);
      }}
    >
      <Flex
        style={{
          height: 64,
          borderBottom: '0.5px solid #292D35',
          paddingInline: 16,
          display: isMobile ? 'flex' : 'none',
        }}
        align="center"
        justify="space-between"
      >
        <ConfigProvider theme={{ components: { Button: { colorText: '#848E9C' } } }}>
          <Button
            onClick={onCancel}
            style={{
              boxShadow: 'unset',
              borderColor: isMobile ? '#181A1F' : '',
              display: isMobile ? 'contents' : 'unset',
            }}
            shape="circle"
            icon={<LeftOutlined />}
          />
        </ConfigProvider>
        <span style={{ fontSize: 17, color: '#E3E5E9', fontWeight: 700, textTransform: 'uppercase' }}>
          {t('listing.listForSale')}
        </span>
        <div></div>
      </Flex>
      <Flex className="sellModalBox">
        <Flex vertical={true} align="center" className="sellModalInfo">
          <Flex style={{ width: '100%' }} align="center" gap={8}>
            {/* <Image width={24} src={broom} preview={false} /> */}
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    handleColor: '#FDD535',
                    handleActiveColor: '#FDD535',
                    trackBg: '#FDD535',
                    trackHoverBg: '#FDD535',
                    railBg: '#848E9C',
                    railHoverBg: '#848E9C',
                    railSize: 2,
                  },
                },
              }}
            >
              <Slider
                onChange={(value) => {
                  setSliderValue(value);
                  setUtxo(listscripthash.slice(0, value));
                }}
                tooltip={{ formatter: null }}
                defaultValue={1}
                value={sliderValue}
                max={listscripthash.length}
                min={1}
                style={{ flex: 1 }}
              />
            </ConfigProvider>
            <span style={{ minWidth: 20, color: '#858E9C', fontWeight: 700, textAlign: 'center' }}>{utxo.length}</span>
          </Flex>
          <ConfigProvider
            theme={{
              ...(!isMobile && {
                token: {
                  colorBgBase: '#0e1011',
                  colorBgContainer: '#101114',
                },
              }),
              components: {
                Input: {
                  colorBorder: isMobile ? '#2B3139' : '#101114',
                  colorText: '#fff',
                  paddingBlock: isMobile ? 8 : 14,
                  borderRadius: 8,
                },
                Form: {
                  labelColor: '#858E9C',
                },
              },
            }}
          >
            <Form
              name="bid"
              layout="vertical"
              requiredMark={false}
              style={{ width: '100%' }}
              initialValues={{ remember: true, recevieaddress: address }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label={
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    <span>{t('marketpalce.price')}</span>
                    <span>{`${t('assets.floorPrice')}（${floorPrice} sats/${tickerName}）`}</span>
                  </Flex>
                }
                labelCol={{ span: 24 }}
                labelAlign="right"
                getValueFromEvent={(e) => {
                  const value = e.target.value;
                  if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                    setPrice(Number(value));
                    return value;
                  } else {
                    return price || '';
                  }
                }}
                rules={[{ required: true }]}
                colon={false}
                name="price"
              >
                <Input
                  placeholder={t('listing.sellPrice')}
                  suffix={
                    <Flex align="center" gap={8}>
                      <span style={{ fontSize: 16, paddingLeft: 10 }}>
                        sats / <span style={{ fontSize: 16 }}>{tickerName}</span>
                      </span>
                      <span style={{ fontWeight: 700, color: '#858E9C' }}>{`$${usdtValue}`}</span>
                    </Flex>
                  }
                />
              </Form.Item>

              <Form.Item<FieldType> label={t('listing.sellAmount')} name="amount">
                <Spin indicator={<LoadingOutlined />} spinning={loading}>
                  <Flex vertical className="broomList" gap={24}>
                    {utxo.length > 0 ? (
                      <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
                        {utxo.map((item) => (
                          <Flex
                            key={item.utxyId}
                            justify="space-between"
                            align="center"
                            // style={{ opacity: errorTxids.includes(item.txid) ? 0.3 : 1 }}
                          >
                            <Flex gap={isMobile ? 4 : 16} style={{ width: '33%' }} align="center">
                              <Avatar
                                size={24}
                                {...(tickerIcon && { src: tickerIcon })}
                                style={{ fontSize: 12, textTransform: 'uppercase' }}
                              >
                                {tickerName.slice(0, 1)}
                              </Avatar>
                              <span style={{ fontSize: isMobile ? 10 : 14, textTransform: 'uppercase' }}>
                                {tickerName}
                              </span>
                              {errorTxids.includes(item.txid) ? (
                                <span className="tag" color="warning">
                                  {t('listing.invalid')}
                                </span>
                              ) : null}
                            </Flex>
                            <span style={{ fontSize: isMobile ? 10 : 14 }}>{item.value}</span>
                            <Flex
                              gap={4}
                              align="center"
                              style={{ width: '33%', marginRight: 8 }}
                              justify="space-between"
                            >
                              <span
                                style={{
                                  color: '#848E9C',
                                  textAlign: 'right',
                                  marginRight: isMobile ? 12 : 30,
                                  fontSize: isMobile ? 10 : 14,
                                }}
                              >
                                {`#${shortenAddress(item.txid)}`}
                              </span>
                              {utxo.length > 1 ? (
                                <Button
                                  size="small"
                                  onClick={() => delUtxo([item])}
                                  style={{ border: 'none', boxShadow: 'none' }}
                                  icon={<DeleteOutlined />}
                                  type="default"
                                />
                              ) : null}
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    ) : (
                      <Flex style={{ height: isMobile ? 92 : 120, width: '100%' }} justify="center" align="center">
                        <Empty
                          image={loading ? null : empty}
                          imageStyle={{ height: isMobile ? 64 : 80 }}
                          description={
                            <span style={{ fontWeight: 700, color: '#798391' }}>
                              {loading ? '' : t('global.noData')}
                            </span>
                          }
                        />
                      </Flex>
                    )}
                  </Flex>
                </Spin>
                {/* <Flex vertical className="broomList" gap={24}>
                  <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
                    {utxo.map((item) => (
                      <Flex
                        key={item.utxyId}
                        justify="space-between"
                        align="center"
                        // style={{ opacity: errorTxids.includes(item.txid) ? 0.3 : 1 }}
                      >
                        <Flex gap={isMobile ? 4 : 16} style={{ width: '33%' }} align="center">
                          <Avatar
                            size={24}
                            {...(tickerIcon && { src: tickerIcon })}
                            style={{ fontSize: 12, textTransform: 'uppercase' }}
                          >
                            {tickerName.slice(0, 1)}
                          </Avatar>
                          <span style={{ fontSize: isMobile ? 10 : 14, textTransform: 'uppercase' }}>{tickerName}</span>
                          {errorTxids.includes(item.txid) ? (
                            <span className="tag" color="warning">
                              Invalid
                            </span>
                          ) : null}
                        </Flex>
                        <span style={{ fontSize: isMobile ? 10 : 14 }}>{item.value}</span>
                        <Flex gap={4} align="center" style={{ width: '33%', marginRight: 8 }} justify="space-between">
                          <span
                            style={{
                              color: '#848E9C',
                              textAlign: 'right',
                              marginRight: isMobile ? 12 : 30,
                              fontSize: isMobile ? 10 : 14,
                            }}
                          >
                            {`#${shortenAddress(item.txid)}`}
                          </span>
                          {utxo.length > 1 ? (
                            <Button
                              size="small"
                              onClick={() => delUtxo([item])}
                              style={{ border: 'none', boxShadow: 'none' }}
                              icon={<DeleteOutlined />}
                              type="default"
                            />
                          ) : null}
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex> */}
              </Form.Item>

              <Form.Item<FieldType>
                label={t('listing.receiveAddress')}
                name="recevieaddress"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ fontSize: isMobile ? 9 : 14 }}
                  placeholder={t('listing.receiveAddress')}
                  suffix={<EditOutlined style={{ color: '#858E9C', fontSize: 22 }} />}
                />
              </Form.Item>
              <Flex className="gasInfoCard" vertical justify="space-between">
                <Flex justify="space-between">
                  <span>{t('listing.serviceFee')}</span>
                  <Flex style={tagPrimaryStyle}>{t('listing.limitedTimeFree')}</Flex>
                  {/* <Flex vertical align="flex-end">
                    <span style={{ textTransform: 'uppercase' }}>{`${makerFee.toFixed(2)}% / ${tickerName}`}</span>
                    <span style={{ fontSize: 12, fontWeight: 700, color: '#858E9C' }}>{`$${formatSatoshiPrice(
                      btcUsdtPrice,
                      (allSats.current * price * makerFee) / 100,
                      3,
                    )}`}</span>
                    <span style={{ fontSize: 10, color: '#858E9C' }}>(Only charged when order filled)</span>
                  </Flex> */}
                </Flex>
                <Divider style={{ margin: 0 }} />
                <Flex justify="space-between">
                  <span>{t('listing.totalAmount')}</span>
                  <Flex vertical align="flex-end">
                    <span>{`${price ? allSats.current * price : 0} sats`}</span>
                    <span style={{ fontSize: 12, fontWeight: 700, color: '#858E9C' }}>{`$${formatSatoshiPrice(
                      btcUsdtPrice,
                      allSats.current * price,
                      3,
                    )}`}</span>
                  </Flex>
                </Flex>
              </Flex>

              {/* <Form.Item<FieldType>
                name="remember"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                style={{ textAlign: 'center' }}
                className="checkApprove"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Should approve all balances')),
                  },
                ]}
              >
                <Checkbox>Approve all balances</Checkbox>
              </Form.Item> */}

              <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
                <ConfigProvider theme={{ token: { colorPrimary: '#EB4B6D' } }}>
                  <Button
                    type="primary"
                    loading={pending}
                    disabled={!utxo.length}
                    htmlType="submit"
                    style={{ width: '220px', height: 48 }}
                  >
                    {t('listing.listForSale')}
                  </Button>
                </ConfigProvider>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </Flex>
      </Flex>
    </Modal>
  );
});
