import store from 'store';
import { langData } from 'locales';
import { showMarket, hideMarket } from 'store/features/globalSlice';
import { tranGetParams } from 'utils';
import { changeWalletName, changeAddress, changeSelctWalletName, updateToken } from 'store/features/applicationSlice';

// request
async function Request<T>(url: string, config: RequestInit): Promise<T> {
  try {
    // const rootUrl = process.env.NODE_ENV === 'production' ? 'https://atomapi.2140.com' : '/apis';
    const rootUrl = process.env.NODE_ENV === 'production' ? 'https://testapiatom.2140.com' : '/apis';
    const response = await fetch(`${rootUrl}${url}`, config);
    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch(changeWalletName(''));
        store.dispatch(changeSelctWalletName(''));
        store.dispatch(changeAddress(''));
        store.dispatch(updateToken({ token: '' }));
      }
      throw new Error(`Network Error: ${response.status} ${response.statusText}`);
    }
    const data: any = await response.json();
    store.dispatch(showMarket());
    if (data?.code !== 10000) {
      if (data?.code === 10600) {
        store.dispatch(hideMarket());
        // if (window.location.pathname !== '/marketplace') {
        //   window.location.pathname = '/marketplace';
        // }
        if (window.location.hash !== '#/marketplace') {
          window.location.hash = '#/marketplace';
        }
        const state = store.getState();
        const { lang } = state.application;
        const text = langData[lang]['global']['marketDisabled1'];
        throw new Error(text);
      }
      throw new Error(`${data?.msg || ''}`);
    } else {
      return data;
    }
  } catch (error: any) {
    throw new Error(`${error?.message || ''}`);
  }
}

// request
async function RequestAllUrl<T>(url: string, config: RequestInit): Promise<T> {
  try {
    const rootUrl = process.env.NODE_ENV === 'production' ? url : '/gas';
    const response = await fetch(`${rootUrl}`, config);
    if (!response.ok) {
      throw new Error(`Network Error: ${response.status} ${response.statusText}`);
    }
    const data: any = await response.json();
    return data;
  } catch (error: any) {
    console.log('error', error.message);
    throw new Error(`${error?.message || ''}`);
  }
}

export const GETURL = async <T>(url: string, params?: { [x: string]: any }): Promise<T> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return RequestAllUrl<T>(`${url}${tranGetParams(params)}`, options);
};

export const GET = async <T>(url: string, params?: { [x: string]: any }): Promise<T> => {
  const state = store.getState();
  const { token } = state.application;
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: token }),
    },
  };

  return Request<T>(`${url}${tranGetParams(params)}`, options);
};

// POST
export async function POST<T>(url: string, data?: any): Promise<T> {
  const state = store.getState();
  const { token } = state.application;
  return Request<T>(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: token }),
      // ...(userId && { userId: userId }),
    },
    ...(data && { body: JSON.stringify(data) }),
  });
}

// PUT
export async function PUT<T>(url: string, data: any): Promise<T> {
  const state = store.getState();
  const { token } = state.application;
  return Request<T>(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: token }),
    },
    body: JSON.stringify(data),
  });
}

// DELETE
export async function del<T>(url: string): Promise<T> {
  return Request<T>(url, {
    method: 'DELETE',
  });
}
