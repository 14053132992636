import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { toXOnly } from 'utils';
import { Psbt, address as bitcoinjsAddress, initEccLib } from 'bitcoinjs-lib';
import * as ecc from '@bitcoin-js/tiny-secp256k1-asmjs';
initEccLib(ecc as any);

export const useWallet = () => {
  const wallet = useAppSelector((state) => state.application.walletName);
  const { connecting } = useAppSelector((state) => state.global);
  const { address } = useAppSelector((state) => state.application);
  const dispatch = useAppDispatch();
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [atomInstalled, setAtomInstalled] = useState(false);
  const [okxInstalled, setOkxInstalled] = useState(false);
  const [xverseInstalled, setXverseInstalled] = useState(false);
  const unisat = (window as any).unisat;
  const atom = (window as any).atom;
  const okxwallet = (window as any).okxwallet;
  const xverse = (window as any).XverseProviders;

  const signPsbt = useCallback(
    (psbt: string, callback?: (pasbt: string) => void) => {
      if (wallet === 'UniSat Wallet') {
        unisat
          .signPsbt(psbt)
          .then((res: any) => {
            callback && callback(res);
          })
          .catch((err: any) => {
            message.error(err.message);
            callback && callback('error');
          });
      } else if (wallet === 'Wizz Wallet') {
        atom
          .signPsbt(psbt)
          .then((res: any) => {
            callback && callback(res);
          })
          .catch((err: any) => {
            callback && callback('error');
            message.error(err.message);
          });
      } else if (wallet === 'OKX Wallet') {
        if (address.startsWith('bc1p')) {
          okxwallet?.bitcoin
            .getPublicKey()
            .then((pubkey: string) => {
              const finalPsbt = Psbt.fromBase64(psbt);
              const tapInternalKey = toXOnly(Buffer.from(pubkey, 'hex'));
              const paymentScript = bitcoinjsAddress.toOutputScript(address);
              const signingIndexes: number[] = [];
              finalPsbt.data.inputs.forEach((item, index) => {
                if (paymentScript.toString('hex') === item.witnessUtxo?.script.toString('hex')) {
                  signingIndexes.push(index);
                }
              });
              for (const i of signingIndexes) {
                finalPsbt.data.inputs[i].tapInternalKey = tapInternalKey;
              }
              const psbtBase64 = finalPsbt.toBase64();
              const psbtBinary = Buffer.from(psbtBase64, 'base64');
              const psbtHex = psbtBinary.toString('hex');

              okxwallet?.bitcoin
                .signPsbt(psbtHex)
                .then((res: any) => {
                  callback && callback(res);
                })
                .catch((err: any) => {
                  callback && callback('error');
                  message.error(err.message);
                });
            })
            .catch(() => {});
        } else {
          okxwallet?.bitcoin
            .signPsbt(psbt)
            .then((res: any) => {
              callback && callback(res);
            })
            .catch((err: any) => {
              callback && callback('error');
              message.error(err.message);
            });
        }
      }
    },
    [address, atom, okxwallet?.bitcoin, unisat, wallet],
  );

  useEffect(() => {
    unisat ? setUnisatInstalled(true) : setUnisatInstalled(false);
    atom ? setAtomInstalled(true) : setAtomInstalled(false);
    okxwallet ? setOkxInstalled(true) : setOkxInstalled(false);
    xverse ? setXverseInstalled(true) : setXverseInstalled(false);
  }, [unisat, atom, okxwallet, xverse]);

  return {
    address,
    connecting,
    unisatInstalled,
    atomInstalled,
    okxInstalled,
    xverseInstalled,
    unisat,
    atom,
    okxwallet,
    xverse,
    signPsbt,
  };
};
