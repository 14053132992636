import { useState, useEffect, useCallback } from 'react';
import { Flex, Select, Image, Button, ConfigProvider, Progress, Table, message, Avatar } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useI18n } from 'react-simple-i18n';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { CopyIcon } from 'assets/icon';
import { CustomLocale, indicator } from 'constant';
import { getHistoryList } from 'api';
import moment from 'moment';
import btc from 'assets/common/btc.png';
import mempool from 'assets/common/mempool.png';
import { shortenAddress, addThousandSeparator, formatSatoshiPrice } from 'utils';
import useCopyClipboard from 'hooks/useCopyClipboard';

interface DataType {
  key: React.Key;
  icon: string;
  token: string;
  price: number;
  risesFallsDay: string;
  totalTradingDay: string;
  totalValue: string;
  marketValue: string;
  totalSupply: string;
  tokenHolders: string;
  operate: string;
}

const History = ({ tab, seconds }: { tab: number; seconds: number }) => {
  const [, setCopied] = useCopyClipboard();
  const { t } = useI18n();
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [type, setType] = useState('all');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const { tickerId } = useParams();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isPad = useMediaQuery({ query: '(max-width: 992px)' });
  const headerNumber = isMobile ? 3 : isPad ? 6 : 9;

  const historyListGet = useCallback(
    (params: { [x: string]: any } = {}) => {
      setLoading(true);
      getHistoryList(tickerId as string, {
        page_size: pageSize,
        page_index: page - 1,
        type,
        ...params,
      })
        .then((res: any) => {
          setHistoryList(res.data.SalesList);
          setTotalCount(res.data.Total);
          setLoading(false);
        })
        .catch((e: any) => {
          setLoading(false);
          message.error(e.message);
        });
    },
    [page, pageSize, tickerId, type],
  );

  useEffect(() => {
    historyListGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seconds === 0 && page === 1 && tab === 2) {
      historyListGet();
    }
  }, [historyListGet, page, seconds, tab]);

  useEffect(() => {
    if (tab === 2) {
      historyListGet();
    }
  }, [historyListGet, tab]);

  const columns: ColumnsType<DataType> = [
    {
      title: t('marketpalce.token'),
      dataIndex: 'ticker_name',
      render: (text, record) => {
        return <span style={{ fontSize: 16, textTransform: 'uppercase' }}>{text}</span>;
      },
    },
    {
      title: t('listing.amount'),
      dataIndex: 'amount',
      render: (text, record) => {
        return (
          <Flex align="center" gap={2}>
            <span style={{ fontWeight: 700 }}>{addThousandSeparator(text)}</span>
          </Flex>
        );
      },
    },
    // {
    //   title: 'Price',
    //   dataIndex: 'totalTradingDay',
    //   render: (text, record) => {
    //     return (
    //       <span style={{ color: record.key === '2' ? '#30BD65' : '#EB4B6D' }}>
    //         {record.key === '2' ? 'BUY' : 'SELL'}
    //       </span>
    //     );
    //   },
    // },
    {
      title: t('marketpalce.price'),
      dataIndex: 'price',
      render: (text, record: any) => {
        return (
          <Flex vertical={true}>
            <Flex gap={4} align="center">
              <Avatar size={13} src={btc} />
              <span style={{ fontSize: 14, fontWeight: 700 }}>{(text / 100000000).toFixed(4)}</span>
            </Flex>
            <span style={{ fontSize: 10, fontWeight: 700, color: '#858E9C' }}>
              {`$${formatSatoshiPrice(record.btc_usdt_price, text)}`}
            </span>
          </Flex>
        );
      },
    },
    {
      title: t('listing.unitPrice'),
      dataIndex: 'unit_price',
      render: (text, record: any) => {
        return (
          <Flex vertical={true}>
            <Flex gap={4} align="center">
              <span style={{ fontSize: 14, fontWeight: 700 }}>{`${text} sats`}</span>
            </Flex>
            <span style={{ fontSize: 10, fontWeight: 700, color: '#858E9C' }}>
              {`$${formatSatoshiPrice(record.btc_usdt_price, text)}`}
            </span>
          </Flex>
        );
      },
    },
    {
      title: t('listing.seller'),
      dataIndex: 'seller',
      render: (text) => {
        return (
          <Flex align="center" gap={8}>
            <span style={{ fontWeight: 700 }}>{shortenAddress(text)}</span>
            <div style={{ cursor: 'pointer' }} onClick={() => setCopied(text)}>
              <CopyIcon />
            </div>
          </Flex>
        );
      },
    },
    {
      title: t('listing.buyer'),
      dataIndex: 'buyer',
      render: (text) => {
        return (
          <Flex align="center" gap={8}>
            <span style={{ fontWeight: 700 }}>{shortenAddress(text)}</span>
            <div style={{ cursor: 'pointer' }} onClick={() => setCopied(text)}>
              <CopyIcon />
            </div>
          </Flex>
        );
      },
    },
    {
      title: t('listing.time'),
      dataIndex: 'time',
      render: (text, record: any) => {
        return (
          <Flex gap={16}>
            <span style={{ fontSize: 12, fontWeight: 700 }}>{text}</span>
            {record?.tx_hash ? (
              <Image
                width={14}
                style={{ cursor: 'pointer' }}
                src={mempool}
                preview={false}
                onClick={() => window.open(`https://mempool.space/tx/${record.tx_hash}`)}
              />
            ) : null}
          </Flex>
        );
      },
    },
  ];

  const handleChange = (value: string) => {
    setType(value);
    historyListGet({ page_index: 0, type: value });
  };

  const handleTokenChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Flex
      vertical={true}
      style={{
        display: tab === 2 ? 'flex' : 'none',
        borderTop: isMobile ? 'unset' : '0.2px solid #343B44',
        padding: isMobile ? '8px 0 20px' : '20px 0',
      }}
    >
      <Flex align="center" justify="space-between">
        <Flex gap={16} align="center">
          {/* <Select
            defaultValue="all"
            style={{ width: 120, borderRadius: 4 }}
            onChange={handleChange}
            options={[
              { value: 'all', label: 'All' },
              { value: 'sold', label: 'Sold' },
              { value: 'listed', label: 'Listed' },
            ]}
          /> */}
          <Button style={{ color: '#000' }} type={'primary'}>
            {t('listing.sales')}
          </Button>
        </Flex>
        <Flex gap={16} align="center">
          <MediaQuery minWidth={576}>
            <ConfigProvider
              theme={{
                token: { borderRadius: 4 },
                components: { Progress: { circleTextColor: seconds > 4 ? '#4CC675' : '#ff4d4f' } },
              }}
            >
              <Button onClick={() => historyListGet()} style={{ boxShadow: 'unset' }} icon={<ReloadOutlined />} />
              <Progress
                type="circle"
                percent={seconds * 10}
                strokeWidth={4}
                strokeColor={seconds > 4 ? '#4CC675' : '#ff4d4f'}
                format={() => seconds}
                size={30}
              />
            </ConfigProvider>
          </MediaQuery>
          <MediaQuery maxWidth={576}>
            <Button style={{ boxShadow: 'unset' }} icon={<SearchOutlined />} />
          </MediaQuery>
        </Flex>
      </Flex>
      <Table
        bordered={false}
        showSorterTooltip={false}
        size="small"
        loading={{
          spinning: loading,
          ...indicator,
        }}
        locale={CustomLocale(loading)}
        style={{ marginTop: '24px' }}
        columns={columns.slice(0, headerNumber)}
        dataSource={historyList}
        rowKey="order_no"
        onChange={onChange}
      />
    </Flex>
  );
};

export default History;
