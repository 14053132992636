export default {
  global: {
    connectWallet: 'Connect Wallet',
    disconnect: 'Disconnect',
    unisatWallet: 'UniSat Wallet',
    wizzWallet: 'Wizz Wallet(Previously Atom)',
    okxWallet: 'OKX Wallet',
    ok: 'OK',
    nextStep: 'next step',
    previous: 'previous',
    searchPlh: 'Search tokens, collections, accounts',
    market: '2140Market',
    noData: 'No Data',
    loading: 'Loading...',
    marketDisabled1: 'The Marketplace is now disabled.',
    marketDisabled2: 'Please stay tuned.',
    transactionSubmitted: 'Your transaction has been submitted!',
  },
  marketpalce: {
    banner1: 'Atomicals Marketplace',
    banner2: 'Empowering Atomicals Assets Trading in 2140',
    banner3: 'Welcome to 2140 Marketplace: Your Premier Hub for Atomicals Assets.',
    banner4: `Experience seamless transactions with advanced features including List, Bulk List, Unlist, Split, Sweep, and Asset Viewing.`,
    banner5: '2140 Marketplace',
    banner6: 'Empowering Atomicals Assets Trading',
    listSplit: 'List / Split',
    all: 'All',
    myFavorites: 'My Favorites',
    token: 'Token',
    price: 'Price',
    dayChange: '24H Change',
    totalVolume: 'Total Volume',
    marketCap: 'Market Cap',
    totalSupply: 'Total Supply',
    holders: 'Holders',
  },
  assets: {
    arc20balance: 'ARC20 Balance',
    tokens: 'Tokens',
    btcBalance: 'BTC Balance',
    floorPrice: 'Floor Price',
    value: 'Value',
    action: 'Action',
    balance: 'Balance',
  },
  listing: {
    listed: 'Listed',
    history: 'History',
    myOrder: 'My order',
    tokenDetails: 'Token Details',
    totalVolume: 'Total Volume',
    dayVolume: '24H Volume',
    minted: 'Minted',
    totalSupply: 'Total Supply',
    sweep: 'Sweep',
    list: 'List',
    split: 'Split',
    lowToHigh: 'Price: low to high',
    highToLow: 'Price: high to low',
    buy: 'Buy',
    mintAmount: 'Mint amount',
    maxMints: 'Max mints',
    mintHeight: 'Mint height',
    commitBitwork: 'Commit bitwork',
    revealBitwork: 'Reveal bitwork',
    deployedBy: 'Deployed by',
    deployedAt: 'Deployed at',
    receiveAddress: 'Receive Address',
    receiveAddressPlh: 'Default display of current wallet address',
    receiveAddressTip: 'Invalid Bitcoin address',
    invalid: 'Invalid',
    slow: 'Slow',
    avg: 'Avg',
    fast: 'Fast',
    custom: 'Custom',
    serviceFee: 'Service Fee',
    timeLimitedFree: 'Time limited free',
    totalCost: 'Total Cost:',
    listForSale: 'LIST FOR SALE',
    sellPrice: 'Sell Price',
    sell: 'Sell',
    sellPriceTip: 'Please enter price',
    sellAmount: 'Sell Amount',
    limitedTimeFree: 'Limited time free',
    totalAmount: 'Total Amount',
    selectUTXO: 'Select UTXO to split',
    splitSize: 'Select the split size',
    splitDetails: 'Split details',
    sales: 'Sales',
    amount: 'Amount',
    unitPrice: 'Unit Price',
    seller: 'Seller',
    buyer: 'Buyer',
    time: 'Time',
    unlist: 'Unlist',
    sold: 'Sold',
    event: 'Event',
    checkAll: 'Check all',
    unlistSelect: 'Unlist Select',
    atomicalId: 'Atomical Id',
    networkFee: 'Network Fee:',
    removedFavorites: 'Removed from favorites',
    addFavorites: 'Added to favorites',
    noListed: 'No Listed Orders Found',
  },
  menu: {
    marketpalce: 'MarketPlace',
    assets: 'Assets',
    language: 'Language',
  },
};
