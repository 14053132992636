import { useState, useEffect } from 'react';
import { useI18n } from 'react-simple-i18n';
import { router, languages } from './config';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateLanguage } from 'store/features/applicationSlice';
import { showWalletModal } from 'store/features/globalSlice';
import { useWallet } from 'hooks/useWallet';
import { Flex } from 'antd';
import './index.css';

const menuItemStyle: React.CSSProperties = {
  // borderBottom: '2px solid red',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
};

const Menu = ({ isWeb, isHome }: { isWeb?: boolean; isHome?: boolean }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.application);
  const { address } = useWallet();
  const [checkPath, setpCheckPath] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsePath, setpCollapsePath] = useState('');

  useEffect(() => {
    setpCheckPath(pathname);
    // document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const selectPath = (path: string, children: Array<{ title: string; path: string }>) => {
    if (path === 'language') {
      setpCollapsePath(collapsePath === 'language' ? '' : 'language');
      return;
    }
    if (children.length) {
      setpCollapsePath(path === collapsePath ? '' : path);
    } else {
      if (!path.includes(collapsePath)) {
        setpCollapsePath('');
      }
      if (path === '/assets' && !address) {
        dispatch(showWalletModal());
      } else {
        if (path === '/assets') {
          navigate({ pathname: `${path}/${address}` });
        } else {
          navigate({ pathname: path });
        }
      }
    }
  };

  const changeLang = (lang: string) => {
    dispatch(updateLanguage(lang));
  };

  return (
    <Flex align="center" justify="center" gap={28}>
      {router.map(({ title, path, children }) => {
        return (
          <div
            key={title}
            className={checkPath.includes(path) ? 'menuCheckItem' : 'menuItem'}
            onClick={() => selectPath(path, children)}
          >
            {t(title)}
            {/* <ListItemButton sx={{ pl: 4 }} onClick={() => selectPath(path, children)} disableRipple>
              <ListItemText
                primary={t(title)}
                sx={{
                  flex: 'unset',
                  '& .MuiTypography-root': {
                    fontSize: 18,
                    fontWeight: 700,
                  },
                  color: path === collapsePath || checkPath.includes(path) ? 'text.text3' : 'background.paper',
                }}
              />
              {!children?.length ? null : path === collapsePath ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton> */}
            {/* {children.length ? (
              <Collapse in={path === collapsePath} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  sx={{
                    width: '100%',
                  }}
                >
                  {children.map((el) => (
                    <ListItemButton
                      disableRipple
                      key={el.title}
                      sx={{ pl: 6, pt: 0, pb: 0 }}
                      onClick={() => selectPath(el.path, [])}
                    >
                      <ItemText checkpath={checkPath} path={el.path}>
                        {t(el.title)}
                      </ItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            ) : null} */}
          </div>
        );
      })}
    </Flex>
  );
};

export default Menu;
