const urls = {
  // listscripthash: `/proxy/blockchain.atomicals.listscripthash?params=["9debd61e9b5b326e543244ebc636a6f8fed83a824ae78d0c2a56c4ba03ed881b",false]`,
  challenge: `/api/v1/auth/getchallenge`,
  verifySignature: `/api/v1/auth/verify`,
  tickerList: `/api/v1/atom/tickers`,
  myFavorites: `/api/v1/atom/tickers/follow`,
  getTickerDetail: (tickerId: string) => `/api/v1/atom/tickers/${tickerId}`,
  getTickerList: (tickerId: string) => `/api/v1/atom/market/${tickerId}/listing`,
  getSweepList: (tickerId: string) => `/api/v1/atom/market/${tickerId}/sweeplisting`,
  getHistoryList: (tickerId: string) => `/api/v1/atom/market/${tickerId}/sales`,
  myorders: `/api/v1/atom/orders/myorders`,
  followuser: `/api/v1/atom/tickers/followuser`,
  arc20: (tickerId: string) => `/api/v1/atom/assets/arc20/${tickerId}`,
  arc20split: (atomicalId: string) => `/api/v1/atom/assets/arc20split/${atomicalId}`,
  splitutxo: `/api/v1/atom/tool/splitutxo`,
  confirmsplitutxo: `/api/v1/atom/tool/confirmsplitutxo`,
  atomicals: `/api/v1/atom/assets/atomicals`,
  bulkbuy: `/api/v1/atom/orders/bulkbuy`,
  confirmbulkbuy: `/api/v1/atom/orders/confirmbulkbuy`,
  bulksell: `/api/v1/atom/orders/bulksell`,
  confirmbulksell: `/api/v1/atom/orders/confirmbulksell`,
  marketfeebp: `/api/v1/atom/util/marketfeebp`,
  estimatefee: `/api/v1/atom/util/estimatefee`,
  searchTickers: `/api/v1/atom/tickers/search`,
  addressInfo: (address: string) => `/api/v1/atom/assets/bitcoin/${address}`,
  unlist: `/api/v1/atom/orders/unlist`,
  cancelpayment: `/api/v1/atom/orders/cancelpayment`,
  logout: `/api/v1/auth/logout`,
};

export default urls;
