import { useState, useEffect, useCallback } from 'react';
import {
  Flex,
  Divider,
  Avatar,
  Select,
  Button,
  ConfigProvider,
  Progress,
  Card,
  message,
  Spin,
  Empty,
  Pagination,
} from 'antd';
import { RightOutlined, ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useI18n } from 'react-simple-i18n';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { displayValue, formatSatoshiPrice, addThousandSeparator, shortenAddress, isDataValid } from 'utils';
import BuyModal from 'components/BuyModal';
import SweepModal from 'components/SweepModal';
import SplitModal from 'components/SplitModal';
import History from 'components/History';
import MyOrder from 'components/MyOrder';
import SellModal from 'components/SellModal';
import TradeResultModal from 'components/TradeResultModal';
import Buy from 'pages/buy';
import Sweep from 'pages/sweep';
import moment from 'moment';
import { useWallet } from 'hooks/useWallet';
import CurrencyDetailsModal from 'components/CurrencyDetailsModal';
import { getTickerIcon } from 'utils/tickerIcon';
import { showSellPage, hideSellPage } from 'store/features/globalSlice';
import { showBuyPage, showSweepPage, hideBuyPage, hideSweepPage, reloadFollow } from 'store/features/globalSlice';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import btc from 'assets/common/btc.png';
import empty from 'assets/common/empty.svg';
import { StarIcon, BroomIcon } from 'assets/icon';
import { showWalletModal } from 'store/features/globalSlice';
import { getTickerDetail, getTickerListing, followuser, unlist, sweepList } from 'api';
import './index.css';

const tagStyle: React.CSSProperties = {
  height: '24px',
  padding: '0 8px',
  border: '0.5px solid rgba(255, 255, 255, 0.10)',
  borderRadius: '4px',
  color: '#848E9C',
  alignItems: 'center',
};

const Listing = () => {
  const { address } = useWallet();
  const [tab, setTab] = useState(1);
  const { t } = useI18n();
  const [isBuy, setIsBuy] = useState(true);
  const [openSweep, setOpenSweep] = useState(false);
  const [openSplit, setOpenSplit] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);
  const [open, setOpen] = useState(false);
  const [tickerList, setTickerList] = useState([]);
  const [selectTickerList, setSelectTickerList] = useState<any[]>([]);
  const [tickerDetail, setTickerDetail] = useState<{ [x: string]: any }>({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectOption, setSelectOption] = useState({ orderby: 'price', desc: false });
  const [selectValue, setSelectValue] = useState('0');
  const [desc, setDesc] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openSell, setOpenSell] = useState(false);
  const [buyType, setBuyType] = useState('sweep');
  const [openTradeResult, setOpenTradeResult] = useState(false);
  const [tradeResult, setTradeResult] = useState<any>({});
  const { openSellPage } = useAppSelector((state) => state.global);
  const navigate = useNavigate();
  const { tickerId } = useParams();
  const { pathname, state } = useLocation();
  const dispatch = useAppDispatch();
  const { openSweepPage, openBuyPage } = useAppSelector((state) => state.global);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isPad = useMediaQuery({ query: '(max-width: 992px)' });
  const { volume_total, volume_24h, market_cap, floor_price, holders, ticker_icon } = tickerDetail;
  const { mint_ed, ticker_name, is_follow, atomical_number, btc_usdt_price, max_supply } = tickerDetail;
  const [isFollow, setIsFollow] = useState(!!is_follow);

  const tickerListingGet = useCallback(
    (params: { [x: string]: any } = {}) => {
      getTickerListing(tickerId as string, {
        page_index: page - 1,
        page_size: pageSize,
        ...selectOption,
        ...params,
      })
        .then((res: any) => {
          setTickerList(res.data.listing_orders);
          setTotalCount(res.data.total_count);
          setSpinning(false);
        })
        .catch((e: any) => {
          message.error(e.message);
          setSpinning(false);
        });
    },
    [page, pageSize, selectOption, tickerId],
  );

  const getSweepListing = (callback?: () => void) => {
    sweepList(tickerId as string, {
      page_index: 0,
      page_size: 50,
    })
      .then((res: any) => {
        setSelectTickerList(res.data.listing_orders);
        setSpinning(false);
        if (res.data.listing_orders?.length) {
          callback && callback();
        } else {
          message.warning('No Listed Orders Found');
          dispatch(hideSellPage());
          setOpenSweep(false);
        }
      })
      .catch((e: any) => {
        message.error(e.message);
        setSpinning(false);
      });
  };

  const unlistToken = (orderNos: string[], callback?: () => void) => {
    if (tab === 1) {
      setSpinning(true);
    }
    unlist({ order_nos: orderNos })
      .then((res: any) => {
        tickerListingGet({ page_index: 0, page_size: 20 });
        message.success('Unlist successfully');
        callback && callback();
      })
      .catch((e: any) => {
        message.error(e.message);
      });
  };

  const reloadListscripthash = () => {
    setSpinning(true);
    getTickerDetail(tickerId as string)
      .then((res: any) => {
        setTickerDetail(res.data);
        setSpinning(false);
        setIsFollow(res.data.is_follow);
        tickerListingGet({
          page_index: 0,
          page_size: 20,
          orderby: 'price',
          desc: false,
        });
      })
      .catch((e: any) => {
        message.error(e.message);
        setSpinning(false);
      });
  };

  useEffect(() => {
    setSpinning(true);
    getTickerDetail(tickerId as string)
      .then((res: any) => {
        setTickerDetail(res.data);
        setIsFollow(res.data.is_follow);
        tickerListingGet();
      })
      .catch((e: any) => {
        message.error(e.message);
        setSpinning(false);
      });
  }, [tickerId, tickerListingGet]);

  useEffect(() => {
    const timerId = setInterval(() => {
      seconds === 0 ? setSeconds(10) : setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0 && page === 1 && tab === 1) {
      tickerListingGet();
    }
  }, [page, seconds, tab, tickerListingGet]);

  const tabs = [
    { title: t('listing.listed'), value: 1 },
    { title: t('listing.history'), value: 2 },
    { title: t('listing.myOrder'), value: 3 },
  ];

  const overviews = [
    {
      title: t('listing.totalVolume'),
      icon: btc,
      total: isDataValid(volume_total / 100000000)
        ? displayValue((volume_total / 100000000).toFixed(isMobile ? 4 : 8))
        : displayValue(volume_total / 100000000),
      usdt: volume_total,
    },
    {
      title: t('listing.dayVolume'),
      icon: btc,
      total: isDataValid(volume_24h / 100000000)
        ? displayValue((volume_24h / 100000000).toFixed(isMobile ? 4 : 8))
        : displayValue(volume_24h / 100000000),
      usdt: volume_24h,
    },
    {
      title: t('marketpalce.marketCap'),
      icon: btc,
      total: isDataValid(market_cap / 100000000)
        ? displayValue((market_cap / 100000000).toFixed(isMobile ? 4 : 8))
        : displayValue(market_cap / 100000000),
      usdt: market_cap,
    },
    {
      title: t('assets.floorPrice'),
      icon: null,
      total: addThousandSeparator(floor_price),
      usdt: floor_price,
    },
    // {
    //   title: 'Transaction',
    //   icon: null,
    //   total: displayValue(market_cap),
    // },
    {
      title: t('marketpalce.holders'),
      icon: null,
      total: addThousandSeparator(holders),
      usdt: '',
    },
  ];

  const options = [
    { value: '0', label: t('listing.lowToHigh') },
    { value: '1', label: t('listing.highToLow') },
    // { value: '2', label: 'New to Old' },
    // { value: '3', label: 'Old to New' },
  ];

  const selectOptions = [
    { orderby: 'price', desc: false },
    { orderby: 'price', desc: true },
    { orderby: 'time', desc: false },
    { orderby: 'time', desc: true },
  ];

  const collectChange = () => {
    setIsFollow(!isFollow);
    followuser({ atomical_number: atomical_number, is_active: isFollow ? 0 : 1 })
      .then(() => {
        dispatch(reloadFollow(moment().valueOf()));
        message.success(isFollow ? t('listing.removedFavorites') : t('listing.addFavorites'));
      })
      .catch((e: any) => {
        setIsFollow(!isFollow);
        message.error(e.message);
      });
  };

  const sweep = () => {
    if (!address) {
      dispatch(showWalletModal());
    } else {
      if (tickerList.length) {
        setBuyType('sweep');
        setSpinning(true);
        getSweepListing(() => {
          if (isMobile) {
            dispatch(showSweepPage());
          } else {
            setOpenSweep(true);
          }
        });
      } else {
        message.warning(t('listing.noListed'));
      }
    }
  };

  const handleChange = (value: string) => {
    setSelectOption(selectOptions[Number(value)]);
    setSelectValue(value);
    setSpinning(true);
    setPage(1);
    tickerListingGet({ ...selectOptions[Number(value)], page_index: 0 });
  };

  const onChange = (current: any, page_size: any) => {
    setSpinning(true);
    setPage(page_size === pageSize ? current : 1);
    setPageSize(page_size);
    tickerListingGet({
      page_index: page_size === pageSize ? current - 1 : 0,
      page_size,
    });
  };

  const goSell = () => {
    if (!address) {
      dispatch(showWalletModal());
    } else {
      setOpenSell(true);
    }
  };

  const goSplit = () => {
    if (!address) {
      dispatch(showWalletModal());
    } else {
      setOpenSplit(true);
    }
  };

  return (
    <>
      <Flex flex={1} vertical={true} style={{ width: '100%' }}>
        <Outlet />
      </Flex>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} spinning={spinning} fullscreen />
      {openSweepPage ? (
        <Sweep
          tickerList={selectTickerList}
          open={openSweepPage}
          onCancel={() => dispatch(hideSweepPage())}
          isBuy={isBuy}
          tickerId={tickerId || ''}
          tickerName={ticker_name || '--'}
          floorPrice={floor_price || 0}
          btcUsdtPrice={btc_usdt_price || 0}
          tickerIcon={getTickerIcon(ticker_name)}
          getSweepListing={getSweepListing}
          showTrade={() => setOpenTradeResult(true)}
          changeTradeResult={(result) => setTradeResult(result)}
          buyType={buyType}
          reloadTickerListing={tickerListingGet}
        />
      ) : null}
      <Buy open={openBuyPage} onCancel={() => dispatch(hideBuyPage())} />
      <TradeResultModal open={openTradeResult} tradeResult={tradeResult} onCancel={() => setOpenTradeResult(false)} />
      <Flex
        flex={1}
        vertical={true}
        className="listingBox"
        style={{
          display:
            pathname === `/marketplace/listing/${tickerId}` && !openSweepPage && !openBuyPage && !openSellPage
              ? 'flex'
              : 'none',
        }}
      >
        <CurrencyDetailsModal open={open} tickerDetail={tickerDetail} onCancel={() => setOpen(false)} />
        <BuyModal open={openBuy} onCancel={() => setOpenBuy(false)} />
        {openSweep ? (
          <SweepModal
            open={openSweep}
            tickerId={tickerId || ''}
            tickerList={selectTickerList}
            onCancel={() => setOpenSweep(false)}
            tickerName={ticker_name || '--'}
            floorPrice={floor_price || 0}
            btcUsdtPrice={btc_usdt_price || 0}
            tickerIcon={getTickerIcon(ticker_name)}
            isBuy={isBuy}
            getSweepListing={getSweepListing}
            showTrade={() => setOpenTradeResult(true)}
            changeTradeResult={(result) => setTradeResult(result)}
            buyType={buyType}
            reloadTickerListing={tickerListingGet}
          />
        ) : null}
        {openSplit ? (
          <SplitModal
            open={openSplit}
            tickerId={tickerId || ''}
            onCancel={() => setOpenSplit(false)}
            tickerName={ticker_name || '--'}
            btcUsdtPrice={btc_usdt_price || 0}
            tickerIcon={getTickerIcon(ticker_name)}
            atomicalId={state?.atomicalId || ''}
            showTrade={() => setOpenTradeResult(true)}
            changeTradeResult={(result) => setTradeResult(result)}
          />
        ) : null}
        {openSell ? (
          <SellModal
            open={openSell}
            tickerName={ticker_name || '--'}
            floorPrice={floor_price || 0}
            btcUsdtPrice={btc_usdt_price || 0}
            tickerIcon={getTickerIcon(ticker_name)}
            tickerId={Number(tickerId) || 0}
            atomicalId={state?.atomicalId || ''}
            onCancel={() => setOpenSell(false)}
            reload={reloadListscripthash}
          />
        ) : null}
        <Flex gap={64} align="center" style={{ paddingTop: isMobile ? '13px' : '60px' }}>
          <Flex gap={32} align="center">
            {tabs.map((item) => (
              <div
                key={item.title}
                style={{
                  fontSize: 17,
                  color: tab === item.value ? '#fff' : '#848E9C',
                  fontWeight: 500,
                  cursor: 'pointer',
                  padding: '8px 0',
                  borderBottom: tab === item.value ? '3px solid #FED535' : '3px solid #181a1f',
                }}
                onClick={() => setTab(item.value)}
              >
                {item.title}
              </div>
            ))}
          </Flex>
        </Flex>
        <Flex
          style={{
            height: isMobile ? '256px' : '128px',
            paddingTop: isMobile ? '27px' : '0',
            // borderTop: isMobile ? '0.5px solid #2B3139' : 'unset',
            // borderBottom: isMobile ? '0.5px solid #2B3139' : 'unset',
          }}
          gap={isMobile ? 26 : 0}
          vertical={isMobile ? true : false}
          align="center"
        >
          <Flex vertical={true} style={{ width: isMobile ? '100%' : '260px' }} justify="space-between">
            <Flex align="center" justify="space-between">
              <Flex align="center" gap={8}>
                <Avatar
                  size={30}
                  {...(getTickerIcon(ticker_name) && { src: getTickerIcon(ticker_name) })}
                  style={{ fontSize: 18, textTransform: 'uppercase' }}
                >
                  {ticker_name?.slice(0, 1)}
                </Avatar>
                <span style={{ fontSize: '22px', fontWeight: 700, textTransform: 'uppercase' }}>
                  {displayValue(ticker_name).length > 12
                    ? shortenAddress(displayValue(ticker_name), 3, 4)
                    : displayValue(ticker_name)}
                </span>
                {address ? (
                  <div style={{ cursor: 'pointer' }} onClick={collectChange}>
                    <StarIcon className={isFollow ? 'collect' : ''} />
                  </div>
                ) : null}
              </Flex>
              <Flex align="center" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                <span style={{ fontSize: '10px', color: '#858E9C' }}>{t('listing.tokenDetails')}</span>
                <RightOutlined style={{ color: '#858E9C' }} />
              </Flex>
            </Flex>
            <div
              style={{
                height: '3px',
                background: '#30BD65',
                width: '100%',
                borderRadius: '1.5px',
                margin: '12px 0 3px',
              }}
            ></div>
            <Flex align="center" justify="space-between">
              <span style={{ fontSize: '7px', color: '#848E9C' }}>{`${t('listing.minted')}：${displayValue(
                mint_ed,
              )}`}</span>
              <span style={{ fontSize: '7px', color: '#848E9C' }}>{`${t('listing.totalSupply')}：${addThousandSeparator(
                max_supply,
              )}`}</span>
            </Flex>
          </Flex>
          <MediaQuery minWidth={576}>
            <Divider type="vertical" style={{ marginInline: '24px', height: '40px' }} />
          </MediaQuery>
          <Flex
            align="center"
            style={{ columnGap: isMobile ? 4 : 40, rowGap: isMobile ? 24 : 40, ...(isMobile && { width: '100%' }) }}
            wrap="wrap"
          >
            {overviews.map(({ title, icon, total, usdt }) => (
              <Flex
                vertical={true}
                key={title}
                align="flex-start"
                gap={4}
                style={{ width: isMobile ? '30%' : 'unset' }}
              >
                <span style={{ fontSize: '12px', color: '#848E9C' }}>{title}</span>
                <Flex align="center" gap={6}>
                  {icon && <Avatar size={16} src={icon} />}
                  <span style={{ fontSize: '14px', fontWeight: 700 }}>{total}</span>
                </Flex>
                {title !== t('marketpalce.holders') ? (
                  <span style={{ fontSize: '10px', color: '#848E9C' }}>{`$${formatSatoshiPrice(
                    btc_usdt_price,
                    usdt,
                  )}`}</span>
                ) : (
                  <div style={{ height: 12 }}></div>
                )}
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex
          vertical={true}
          style={{
            display: tab === 1 ? 'flex' : 'none',
            borderTop: isMobile ? 'unset' : '0.2px solid #343B44',
            padding: isMobile ? '8px 0 20px' : '20px 0',
          }}
        >
          <Flex align="center" justify="space-between">
            <Flex
              gap={isMobile ? 0 : 16}
              align="center"
              style={{ width: isMobile ? '100%' : 'unset' }}
              justify={isMobile ? 'space-between' : 'unset'}
            >
              {/* <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#2B3139',
                    borderRadius: 4,
                  },
                }}
              >
                <Button
                  style={{
                    color: isBuy ? '#FDD535' : '#fff',
                    boxShadow: 'unset',
                    paddingInline: isMobile ? 6 : 14,
                  }}
                  onClick={() => setIsBuy(true)}
                  type={isBuy ? 'primary' : 'default'}
                >
                  BUY
                </Button>
                <Button
                  style={{ color: !isBuy ? '#FDD535' : '#fff', boxShadow: 'unset', paddingInline: isMobile ? 6 : 14 }}
                  onClick={() => setIsBuy(false)}
                  type={isBuy ? 'default' : 'primary'}
                >
                  SELL
                </Button>
              </ConfigProvider> */}
              <MediaQuery maxWidth={576}>
                <Select
                  defaultValue="0"
                  value={selectValue}
                  style={{ width: 160, borderRadius: 4 }}
                  onChange={handleChange}
                  options={options}
                />
              </MediaQuery>
              <Button
                style={{ color: '#000', paddingInline: isMobile ? 6 : 14 }}
                type="primary"
                icon={<BroomIcon />}
                onClick={sweep}
              >
                {isMobile ? '' : t('listing.sweep')}
              </Button>
              <Button
                style={{ color: '#000', paddingInline: isMobile ? 20 : 14 }}
                type="primary"
                onClick={goSell}
                // onClick={() => {
                //   navigate(
                //     {
                //       pathname: `/marketplace/listing/${tickerId}/shelf-Inscription`,
                //     },
                //     {
                //       state: {
                //         tickerId: tickerId,
                //         tickerName: ticker_name,
                //         tickerIcon: ticker_icon,
                //         floorPrice: floor_price,
                //         listscripthash: listscripthash,
                //         btcUsdtPrice: btc_usdt_price,
                //         atomicalId: state?.atomicalId || '',
                //       },
                //     },
                //   );
                //   // if (isBuy) {
                //   //   if (isMobile) {
                //   //     dispatch(showBuyPage());
                //   //   } else {
                //   //     setOpenBuy(true);
                //   //   }
                //   // } else {
                //   //   navigate(
                //   //     {
                //   //       pathname: `/marketplace/listing/${tickerId}/shelf-Inscription`,
                //   //     },
                //   //     {
                //   //       state: {
                //   //         tickerId: tickerId,
                //   //         tickerName: ticker_name,
                //   //         tickerIcon: ticker_icon,
                //   //         floorPrice: floor_price,
                //   //         listscripthash: listscripthash,
                //   //         btcUsdtPrice: btc_usdt_price,
                //   //       },
                //   //     },
                //   //   );
                //   // }
                // }}
              >
                {/* {isBuy ? 'BID' : 'List'} */}
                {t('listing.list')}
              </Button>
              <Button style={{ color: '#000', paddingInline: isMobile ? 6 : 14 }} type="primary" onClick={goSplit}>
                {t('listing.split')}
              </Button>
            </Flex>
            <MediaQuery minWidth={576}>
              <Flex gap={16} align="center">
                <ConfigProvider
                  theme={{
                    token: { borderRadius: 4 },
                    components: { Progress: { circleTextColor: seconds > 4 ? '#4CC675' : '#ff4d4f' } },
                  }}
                >
                  <Select
                    defaultValue="0"
                    value={selectValue}
                    style={{ width: 170, borderRadius: 4 }}
                    onChange={handleChange}
                    options={options}
                  />
                  <Button style={{ boxShadow: 'unset' }} onClick={() => tickerListingGet()} icon={<ReloadOutlined />} />
                  <Progress
                    type="circle"
                    percent={seconds * 10}
                    strokeWidth={4}
                    strokeColor={seconds > 4 ? '#4CC675' : '#ff4d4f'}
                    format={() => seconds}
                    size={30}
                  />
                </ConfigProvider>
              </Flex>
            </MediaQuery>
          </Flex>
          {isBuy && (
            <Flex className="listBox" gap={12}>
              {tickerList.map((el: any, i) => (
                <Card key={el.order_no}>
                  <Flex vertical={true} style={{ height: '100%' }} justify="space-between">
                    <Flex align="center" justify="space-between">
                      <span style={{ fontSize: isMobile ? 12 : 14, fontWeight: 500, textTransform: 'uppercase' }}>
                        {displayValue(ticker_name)}
                      </span>
                      {/* <Flex align="center" gap={8}>
                        <Flex
                          style={{ ...tagStyle, padding: isMobile ? '0 4px' : '0 8px', fontSize: isMobile ? 12 : 14 }}
                        >
                          #128201022999
                        </Flex>
                      </Flex> */}
                    </Flex>
                    <span style={{ fontSize: isMobile ? 18 : 22, fontWeight: 700 }}>
                      {addThousandSeparator(el.atom_utxo_value)}
                    </span>
                    <Flex vertical={true}>
                      <span
                        style={{
                          fontSize: isMobile ? 12 : 14,
                          color: '#848E9C',
                          lineHeight: '20px',
                        }}
                      >
                        {`${el.price.toFixed(2)} sats / ${displayValue(ticker_name)}`}
                      </span>
                      <span style={{ fontSize: 10, color: '#848E9C' }}>{`$${formatSatoshiPrice(
                        btc_usdt_price,
                        el.price,
                        4,
                      )} / ${displayValue(ticker_name)}`}</span>
                    </Flex>
                    <Flex vertical={true} gap={isMobile ? 8 : 16}>
                      <Flex
                        style={{
                          ...tagStyle,
                          height: isMobile ? '32px' : '40px',
                          padding: '0 4px',
                        }}
                        justify="space-between"
                      >
                        <Flex gap={4} align="center">
                          <Avatar size={16} src={btc} />
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: isMobile ? 10 : 12,
                              color: '#fff',
                              lineHeight: isMobile ? '14px' : '20px',
                            }}
                          >
                            {`${((el.price * el.atom_utxo_value) / 100000000).toFixed(isMobile ? 6 : 8)} BTC`}
                          </span>
                        </Flex>
                        <span style={{ fontSize: isMobile ? 9 : 10, color: '#858E9C', lineHeight: '10px' }}>
                          {`$${formatSatoshiPrice(btc_usdt_price, el.price * el.atom_utxo_value, 3)}`}
                        </span>
                        {/* <span style={{ fontSize: isMobile ? 10 : 12, color: '#fff' }}>Total value</span>
                        <Flex vertical={true} align="flex-start">
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: isMobile ? 10 : 14,
                              color: '#fff',
                              lineHeight: isMobile ? '14px' : '20px',
                            }}
                          >
                            {`${((el.price * el.atom_utxo_value) / 100000000).toFixed(8)} BTC`}
                          </span>
                          <span style={{ fontSize: isMobile ? 9 : 10, color: '#858E9C', lineHeight: '10px' }}>
                            {`$${formatSatoshiPrice(btc_usdt_price, el.price * el.atom_utxo_value, 3)}`}
                          </span>
                        </Flex> */}
                      </Flex>

                      <ConfigProvider theme={{ token: { colorPrimary: isBuy ? '#30BD65' : '#EB4B6D' } }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            if (!address) {
                              dispatch(showWalletModal());
                            } else if (el.seller_address === address) {
                              unlistToken([el.order_no]);
                            } else {
                              setBuyType('buy');
                              setSelectTickerList([el]);
                              if (isMobile) {
                                dispatch(showSweepPage());
                              } else {
                                setOpenSweep(true);
                              }
                            }
                          }}
                          style={{ height: isMobile ? '28px' : '32px', textTransform: 'uppercase' }}
                        >
                          {el.seller_address === address
                            ? t('listing.unlist')
                            : isBuy
                              ? `${t('listing.buy')} ${displayValue(ticker_name)}`
                              : `${t('listing.sell')} ${displayValue(ticker_name)}`}
                        </Button>
                      </ConfigProvider>
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Flex>
          )}
          {(!isBuy || tickerList.length === 0) && (
            <Flex style={{ height: 160 }} justify="center" align="center">
              <Empty
                image={empty}
                imageStyle={{ height: 100 }}
                description={<span style={{ fontWeight: 700, color: '#798391' }}>{t('global.noData')}</span>}
              />
            </Flex>
          )}
          {totalCount > 0 && isBuy && (
            <Flex style={{ height: 40 }} justify="flex-end" align="flex-end">
              <Pagination
                current={page}
                pageSize={pageSize}
                defaultCurrent={1}
                onChange={onChange}
                total={totalCount}
                size="small"
                showSizeChanger
              />
            </Flex>
          )}
          {/* <MediaQuery minWidth={576}>
            <Flex gap={16} style={{ marginTop: 16 }} align="center">
              <Button style={{ color: '#000' }} type="primary">
                <span style={{ marginRight: 40, fontSize: 14 }}>Buy 2 ITEM</span>
                <span style={{ fontSize: 12 }}>0.48 BTC</span>
              </Button>
              <span>OR $ 2408.01</span>
            </Flex>
          </MediaQuery> */}
        </Flex>
        <History tab={tab} seconds={seconds} />
        <MyOrder tab={tab} seconds={seconds} atomicalNumber={Number(tickerId)} unlistToken={unlistToken} />
      </Flex>
    </>
  );
};

export default Listing;
