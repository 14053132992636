import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useI18n } from 'react-simple-i18n';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { showWalletModal } from 'store/features/globalSlice';
import MarketDisableModal from 'components/MarketDisableModal';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useWallet } from 'hooks/useWallet';
import { Layout, Flex } from 'antd';
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';
import './index.css';

const { Content } = Layout;

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  height: 'calc(100%)',
  width: '100%',
  color: '#fff',
  backgroundColor: '#181A1F',
  padding: '64px 16px 0',
  position: 'relative',
};

const layoutStyle = {
  flex: 1,
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#181A1F',
  alignItems: 'center',
};

const Layouts: FC<PropsWithChildren> = () => {
  const { t } = useI18n();
  const { tradeInfo, openSweepPage, openBuyPage, openSellPage } = useAppSelector((state) => state.global);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const { pathname } = useLocation();
  const { address } = useWallet();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   const documentHeight = () => {
  //     const doc = document.documentElement;
  //     doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  //   };
  //   documentHeight();
  //   window.addEventListener('resize', documentHeight);
  // }, []);

  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    documentHeight();
    window.addEventListener('resize', documentHeight);
  }, []);

  // useEffect(() => {
  //   if (pathname !== '/marketplace' && !address) {
  //     // dispatch(showWalletModal());
  //     navigate('/marketplace');
  //   }
  // }, [pathname, address, dispatch, navigate]);

  return (
    <Layout className="layoutsBox" style={{ ...layoutStyle, minHeight: isMobile ? 'unset' : '100vh' }}>
      <MarketDisableModal />
      {openSweepPage || openBuyPage || openSellPage ? null : <AppBar />}
      <Content
        style={{
          ...contentStyle,
          maxWidth: pathname.includes('/shelf-Inscription') ? 'unset' : '1200px',
          padding: openSweepPage || openBuyPage || openSellPage ? 0 : '64px 16px 0',
        }}
      >
        {openSweepPage || openBuyPage || openSellPage ? null : <div className="topCard"></div>}
        <Outlet />
      </Content>
      {openSweepPage || openBuyPage || openSellPage ? null : <Footer />}
      <MediaQuery maxWidth={576}>
        <Flex className={`buyItemInfo ${tradeInfo ? '' : 'hidden'}`}>
          <Flex gap={4}>
            <span style={{ fontWeight: 700 }}>SELL</span>
            <span>2</span>
            <span style={{ fontWeight: 700 }}>ITEM</span>
          </Flex>
          <Flex vertical>
            <Flex gap={2}>
              <span>0.48</span>
              <span style={{ fontWeight: 700 }}>BTC</span>
            </Flex>
            <span style={{ fontSize: 10, color: '#00000080' }}>$ 2408.01</span>
          </Flex>
        </Flex>
      </MediaQuery>
    </Layout>
  );
};
export default Layouts;
