import { useRef, useState } from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppDispatch } from 'hooks/redux';
import { useAppSelector } from 'hooks/redux';
import { Modal, Flex } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useMediaQuery } from 'react-responsive';

export default function MarketDisableModal(): JSX.Element | null {
  const { t } = useI18n();
  const addressRef = useRef<InputRef>(null);
  const [sliderValue, setSliderValue] = useState(0);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const { marketDisable } = useAppSelector((state) => state.global);

  return (
    <Modal
      title={''}
      footer={null}
      width={480}
      focusTriggerAfterClose={false}
      open={marketDisable}
      centered
      destroyOnClose={true}
      closable={false}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
    >
      <Flex vertical gap={20} align="center" justify="center" style={{ padding: '5px 25px 25px' }}>
        <WarningOutlined style={{ fontSize: 40, color: '#faad14' }} />
        <span style={{ fontSize: 20 }}>{t('global.marketDisabled1')}</span>
        <span style={{ fontSize: 20 }}>{t('global.marketDisabled2')}</span>
      </Flex>
    </Modal>
  );
}
