import { useState, useEffect } from 'react';
import { Flex, Button, ConfigProvider, Image, Divider, Avatar, Table, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useI18n } from 'react-simple-i18n';
import { CaretDownOutlined, CaretUpOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { showAsset, hideAsset } from 'store/features/applicationSlice';
import { CustomLocale, indicator } from 'constant';
import btc from 'assets/common/btc.png';
import { useWallet } from 'hooks/useWallet';
import SplitModal from 'components/SplitModal';
import SellModal from 'components/SellModal';
import TradeResultModal from 'components/TradeResultModal';
import mempool from 'assets/common/mempool.png';
import { CopyIcon } from 'assets/icon';
import { shortenAddress, addThousandSeparator, formatSatoshiPrice } from 'utils';
import { getTickerIcon } from 'utils/tickerIcon';
import { getAddressInfo } from 'api';
import useCopyClipboard from 'hooks/useCopyClipboard';
import _ from 'lodash';
import './index.css';

interface DataType {
  key: React.Key;
  icon: string;
  token: string;
  price: number;
  risesFallsDay: string;
  totalTradingDay: string;
  totalValue: string;
  marketValue: string;
  totalSupply: string;
  tokenHolders: string;
  operate: string;
}

const Assets = () => {
  const navigate = useNavigate();
  const walletInfo = useWallet();
  const { t } = useI18n();
  const { address } = useParams();
  const { pathname, state } = useLocation();
  const { assetDisplay } = useAppSelector((state) => state.application);
  const dispatch = useAppDispatch();
  const [, setCopied] = useCopyClipboard();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isPad = useMediaQuery({ query: '(max-width: 992px)' });
  const headerNumber = isMobile ? 3 : isPad ? 6 : 9;
  const [addressInfo, setAddressInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [openSplit, setOpenSplit] = useState(false);
  const [openSell, setOpenSell] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<any>({});
  const [openTradeResult, setOpenTradeResult] = useState(false);
  const [tradeResult, setTradeResult] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    getAddressInfo(state?.address || address)
      .then((info: any) => {
        setAddressInfo(info.data);
        setLoading(false);
      })
      .catch((e: any) => {
        message.error(e.message);
        setLoading(false);
      });
  }, [state?.address, address]);

  const overviews = [
    {
      title: t('assets.arc20balance'),
      icon: btc,
      total: assetDisplay ? (addressInfo.atom_total_balance || 0) / 100000000 : '******',
      usdt: assetDisplay
        ? `$${formatSatoshiPrice(addressInfo.btc_usdt_price, addressInfo.atom_total_balance)}`
        : '******',
    },
    {
      title: t('assets.btcBalance'),
      icon: btc,
      total: assetDisplay ? (addressInfo.ex_atom_balance || 0) / 100000000 : '******',
      usdt: assetDisplay ? `$${formatSatoshiPrice(addressInfo.btc_usdt_price, addressInfo.ex_atom_balance)}` : '******',
    },
    {
      title: t('assets.tokens'),
      icon: null,
      total: assetDisplay ? addressInfo.token_count || 0 : '******',
      usdt: '',
    },
  ];

  const goSplitOrList = (type: string, record: any) => {
    setTokenInfo(record);
    if (type === 'list') {
      setOpenSell(true);
    } else {
      setOpenSplit(true);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t('marketpalce.token'),
      ...(!isMobile && { width: '50%' }),
      dataIndex: 'ticker_name',
      render: (text, record: any) => {
        return (
          <Flex
            align="center"
            gap={8}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(
                {
                  pathname: `/marketplace/listing/${record.atomical_number}`,
                },
                { state: { atomicalId: record.atomical_id } },
              );
            }}
          >
            <Avatar
              size={24}
              {...(getTickerIcon(record.ticker_name) && { src: getTickerIcon(record.ticker_name) })}
              style={{ fontSize: 12, textTransform: 'uppercase' }}
            >
              {text.slice(0, 1)}
              {/* <span style={{ fontSize: 12, textTransform: 'uppercase', lineHeight: '16px' }}>{text.slice(0, 1)}</span> */}
            </Avatar>
            <span style={{ fontSize: 14, fontWeight: 500, textTransform: 'uppercase' }}>
              {text.length > 12 ? shortenAddress(text, 3, 4) : text}
            </span>
          </Flex>
        );
      },
    },
    {
      title: t('assets.balance'),
      dataIndex: 'balance',
      sorter: (a, b) => a.price - b.price,
      render: (text) => {
        return <span style={{ fontSize: 14, fontWeight: 700 }}>{`${addThousandSeparator(text)}`}</span>;
        // return (
        //   <Flex vertical={true}>
        //     <span style={{ fontSize: 14, fontWeight: 700 }}>{`${text} sate`}</span>
        //     <span style={{ fontSize: 10, fontWeight: 400, color: '#858E9C' }}>{'$124.2'}</span>
        //   </Flex>
        // );
      },
    },
    {
      title: t('assets.floorPrice'),
      dataIndex: 'floor_price',
      // sorter: (a, b) => a.price - b.price,
      render: (text, record: any) => {
        return (
          <Flex vertical={true}>
            <span style={{ fontSize: 14, fontWeight: 700 }}>{`${text} sate`}</span>
            <span style={{ fontSize: 10, fontWeight: 400, color: '#858E9C' }}>{`$${formatSatoshiPrice(
              record.btc_usdt_price,
              text,
              4,
            )}`}</span>
          </Flex>
        );
      },
    },
    {
      title: t('marketpalce.dayChange'),
      dataIndex: 'hourly_change_percentage',
      render: (text, record) => {
        return (
          <Flex align="center" gap={4}>
            {text.includes('-') ? (
              <CaretDownOutlined style={{ color: '#EB4B6D' }} />
            ) : (
              <CaretUpOutlined style={{ color: '#30BD65' }} />
            )}
            <span style={{ fontSize: 14, fontWeight: 700, color: text.includes('-') ? '#EB4B6D' : '#30BD65' }}>
              {text}
            </span>
          </Flex>
        );
      },
    },
    {
      title: t('assets.value'),
      dataIndex: 'balance',
      render: (text, record) => {
        return <span style={{ fontSize: 14, fontWeight: 700 }}>{text}</span>;
      },
    },
  ];

  if (walletInfo.address === address) {
    columns.push({
      title: t('assets.action'),
      dataIndex: '',
      render: (text, record) => {
        return (
          <Flex gap={8} align="center">
            <Button style={{ color: '#000' }} type="primary" size="small" onClick={() => goSplitOrList('list', record)}>
              {t('listing.list')}
            </Button>
            <Button
              style={{ color: '#000' }}
              type="primary"
              size="small"
              onClick={() => goSplitOrList('split', record)}
            >
              {t('listing.split')}
            </Button>
          </Flex>
        );
      },
    });
  }

  const onChange: TableProps<DataType>['onChange'] = (pagination) => {
    setPageSize(pagination.pageSize || 10);
  };

  return (
    <Flex flex={1} vertical={true} className="assetsBox">
      {openSplit ? (
        <SplitModal
          open={openSplit}
          tickerId={tokenInfo.atomical_number || ''}
          onCancel={() => setOpenSplit(false)}
          tickerName={tokenInfo.ticker_name || '--'}
          btcUsdtPrice={tokenInfo.btc_usdt_price || 0}
          tickerIcon={getTickerIcon(tokenInfo.ticker_name)}
          atomicalId={tokenInfo.atomical_id || ''}
          showTrade={() => setOpenTradeResult(true)}
          changeTradeResult={(result) => setTradeResult(result)}
        />
      ) : null}
      {openSell ? (
        <SellModal
          open={openSell}
          tickerName={tokenInfo.ticker_name || '--'}
          floorPrice={tokenInfo.floor_price || 0}
          btcUsdtPrice={tokenInfo.btc_usdt_price || 0}
          tickerIcon={getTickerIcon(tokenInfo.ticker_name)}
          tickerId={tokenInfo.atomical_number || 0}
          atomicalId={tokenInfo.atomical_id || ''}
          onCancel={() => setOpenSell(false)}
        />
      ) : null}
      <TradeResultModal open={openTradeResult} tradeResult={tradeResult} onCancel={() => setOpenTradeResult(false)} />
      <Flex align="center" gap={isMobile ? 8 : 14}>
        <ConfigProvider theme={{ components: { Button: { colorText: '#848E9C' } } }}>
          <Button
            onClick={() => navigate(-1)}
            style={{
              boxShadow: 'unset',
              borderColor: isMobile ? '#181A1F' : '',
              display: isMobile ? 'contents' : 'unset',
            }}
            shape="circle"
            icon={<LeftOutlined />}
          />
          <span style={{ fontSize: isMobile ? 16 : 24, color: '#848E9C', fontWeight: 700 }}>{t('menu.assets')}</span>
          <Button
            onClick={() => (assetDisplay ? dispatch(hideAsset()) : dispatch(showAsset()))}
            style={{
              boxShadow: 'unset',
              border: 'unset',
              display: 'contents',
            }}
            shape="circle"
            icon={!assetDisplay ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          />
        </ConfigProvider>
      </Flex>
      <Flex
        style={{
          height: isMobile ? '165px' : '114px',
          paddingTop: isMobile ? '24px' : '0',
          paddingBottom: isMobile ? 20 : 10,
          // borderTop: isMobile ? '0.5px solid #2B3139' : 'unset',
          borderBottom: '0.5px solid #343B44',
          marginBottom: isMobile ? 4 : 24,
        }}
        gap={isMobile ? 26 : 0}
        vertical={isMobile ? true : false}
        align="center"
      >
        <Flex vertical={true} style={{ width: isMobile ? '100%' : '250px' }} justify="space-between">
          <Flex align="center" justify="space-between">
            <Flex align="center" gap={16}>
              <span style={{ fontSize: 16 }}>{shortenAddress(state?.address || address, 8, 6)}</span>
              <CopyIcon style={{ cursor: 'pointer' }} onClick={() => setCopied(state?.address || address)} />
            </Flex>
            <Image
              src={mempool}
              style={{ cursor: 'pointer' }}
              preview={false}
              width={16}
              onClick={() => window.open(`https://mempool.space/address/${state?.address || address}`)}
            />
          </Flex>
          <div
            style={{
              height: '3px',
              background: '#30BD65',
              width: '100%',
              borderRadius: '1.5px',
              margin: '12px 0 3px',
            }}
          ></div>
          {/* <Flex align="center" justify="space-between">
            <span style={{ fontSize: '8px', color: '#848E9C' }}>MINTED：12,000,000</span>
            <span style={{ fontSize: '8px', color: '#848E9C' }}>TOTAL SUPPLY：12,000,000</span>
          </Flex> */}
        </Flex>
        <MediaQuery minWidth={576}>
          <Divider type="vertical" style={{ marginInline: '24px', height: '40px' }} />
        </MediaQuery>
        <Flex
          align="center"
          style={{
            columnGap: isMobile ? 0 : 40,
            rowGap: isMobile ? 0 : 40,
            width: '100%',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}
          wrap="wrap"
        >
          {overviews.map(({ title, icon, total, usdt }) => (
            <Flex vertical={true} key={title} align="flex-start" gap={4} style={{ width: isMobile ? '33%' : 'unset' }}>
              <span style={{ fontSize: '12px', color: '#848E9C' }}>{title}</span>
              <Flex align="center" gap={6}>
                {icon && <Avatar size={16} src={icon} />}
                <span style={{ fontSize: '14px', fontWeight: 700 }}>{total}</span>
              </Flex>
              <div style={{ fontSize: '10px', color: '#848E9C', height: 14 }}>{usdt}</div>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Table
        bordered={false}
        showSorterTooltip={false}
        size="small"
        pagination={{ pageSize }}
        columns={
          !isMobile
            ? columns
            : _.remove(columns, (n, i) => {
                return i < 2 || i === columns.length - 1;
              })
        }
        dataSource={addressInfo.tokens || []}
        onChange={onChange}
        rowKey="atomical_id"
        loading={{
          spinning: loading,
          ...indicator,
        }}
        locale={CustomLocale(loading)}
      />
    </Flex>
  );
};

export default Assets;
