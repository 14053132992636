import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { showWalletModal } from 'store/features/globalSlice';
import { updateLanguage } from 'store/features/applicationSlice';
import { useI18n } from 'react-simple-i18n';
import WalletModal from 'components/WalletModal';
import Menu from 'components/Menu';
import SearchSelect from 'components/SearchSelect';
import MobileMenu from 'components/MobileMenu';
import { useWalletConnect } from 'hooks/useWalletConnect';
import { useWallet } from 'hooks/useWallet';
import { shortenAddress } from 'utils';
import { Layout, Flex, message, Button, Dropdown, Image, Select, ConfigProvider, Drawer } from 'antd';
import type { MenuProps } from 'antd';
import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import Headroom from 'components/common/Headroom';
import { WalletIcon, DownIcon } from 'assets/icon';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import logo from 'assets/common/logo.png';
import gas from 'assets/common/gas.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { estimatefee, logout } from 'api';
import { langInfo, langInfoLabel } from 'locales';

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  // textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 0,
  // lineHeight: '64px',
  position: 'fixed',
  top: 0,
  zIndex: 4,
  width: '100%',
  backgroundColor: '#181A1F',
  maxWidth: '1200px',
  padding: '0 16px',
};

const headroomStyle: React.CSSProperties = {
  height: 64,
  top: 64,
  zIndex: 2,
  width: '100%',
  backgroundColor: '#181A1F',
  padding: '0 16px 0 9px',
  justifyContent: 'space-between',
  gap: 24,
  // position: 'absolute',
};

const connectStyle: React.CSSProperties = {
  // color: '#fff',
  fontWeight: 400,
  fontSize: 12,
};

const AppBar = (): JSX.Element => {
  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { handleDisConnect } = useWalletConnect();
  const { address, connecting } = useWallet();
  const { lang } = useAppSelector((state) => state.application);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const timerId = useRef<any>();
  const [fee, setFee] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  const selectPath = (path: string) => {
    if (path === '/assets' && !address) {
      dispatch(showWalletModal());
    } else {
      if (path === '/assets') {
        navigate({ pathname: `${path}/${address}` });
      } else {
        navigate({ pathname: path });
      }
    }
  };

  const items: MenuProps['items'] = [
    {
      label: <a onClick={() => selectPath('/marketplace')}>MarketPlace</a>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <a onClick={() => selectPath('/assets')}>Assets</a>,
      key: '1',
    },
  ];

  const langItems: MenuProps['items'] = langInfo.map((item) => ({
    label: <a>{item.label}</a>,
    key: item.value,
  }));

  const getEstimatefee = () => {
    estimatefee()
      .then((res: any) => {
        const { ProposeGasPrice } = res.data;
        setFee(ProposeGasPrice || 0);
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    getEstimatefee();
    timerId.current = setInterval(() => {
      getEstimatefee();
    }, 10000);

    return () => clearInterval(timerId.current);
  }, []);

  const disConnect = () => {
    logout()
      .then(() => {
        handleDisConnect();
      })
      .catch((e: any) => {
        message.error(e.message);
      });
  };
  return (
    <>
      {pathname === '/marketplace' ? (
        <MediaQuery maxWidth={576}>
          <ConfigProvider
            theme={{
              token: {
                colorBgBase: '#0e1011',
                colorBgContainer: '#202527',
              },
              components: {
                Input: {
                  colorBorder: '#202527',
                },
              },
            }}
          >
            <Headroom style={{ ...headroomStyle, borderBottom: isMobile ? '0.5px solid #2B3139' : 'unset' }}>
              <Select
                defaultValue="arc20"
                style={{ width: 100 }}
                variant={'borderless'}
                suffixIcon={<DownIcon style={{ pointerEvents: 'none' }} />}
                options={[
                  // { value: 'brc20', label: 'BRC20' },
                  { value: 'arc20', label: 'ARC20' },
                ]}
              />
              <SearchSelect />
              {/* <Input
                placeholder="Search tokens, collections, accounts"
                style={{ width: '100%' }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                suffix={<SearchOutlined style={{ cursor: 'pointer', color: '#575b5c' }} />}
              /> */}
            </Headroom>
          </ConfigProvider>
        </MediaQuery>
      ) : null}
      <Header style={headerStyle} className="header">
        <WalletModal />
        <Flex align="center" justify="space-between">
          <Flex align="center" gap={80}>
            <Image
              style={{ cursor: 'pointer' }}
              width={isMobile ? 64 : 80}
              src={logo}
              preview={false}
              onClick={() => navigate('/marketplace')}
            />
            <MediaQuery minWidth={768}>
              <Menu />
            </MediaQuery>
          </Flex>
          <MediaQuery minWidth={992}>
            <ConfigProvider
              theme={{
                token: {
                  colorBgBase: '#0e1011',
                  colorBgContainer: '#202527',
                },
                components: {
                  Input: {
                    colorBorder: '#202527',
                  },
                },
              }}
            >
              <SearchSelect />
              {/* <Input
                placeholder="Search tokens, collections, accounts"
                style={{ width: '250px' }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                suffix={
                  <SearchOutlined
                    onClick={() => dispatch(changeSearchTickerValue(searchValue))}
                    style={{ cursor: 'pointer', color: '#575b5c' }}
                  />
                }
              /> */}
            </ConfigProvider>
          </MediaQuery>
          <Flex align="center" gap={32}>
            <MediaQuery minWidth={576}>
              <Select
                defaultValue="arc20"
                style={{ width: 100 }}
                variant={'borderless'}
                suffixIcon={<DownIcon style={{ pointerEvents: 'none' }} />}
                options={[
                  // { value: 'brc20', label: 'BRC20' },
                  { value: 'arc20', label: 'ARC20' },
                ]}
              />
            </MediaQuery>
            <Flex align="center" gap={16}>
              <MediaQuery minWidth={576}>
                <Flex align="center" gap={8}>
                  <Image width={25} src={gas} preview={false} />
                  <div>{`${fee} Gwei`}</div>
                </Flex>
              </MediaQuery>
              <MediaQuery minWidth={576}>
                <Dropdown
                  menu={{
                    items: langItems,
                    selectable: true,
                    selectedKeys: [lang],
                    onClick: ({ key }) => dispatch(updateLanguage(key)),
                  }}
                  trigger={['click']}
                  placement="bottom"
                  overlayClassName="appBarLocale"
                >
                  <Flex gap={4} align="center" style={{ height: 30, cursor: 'pointer' }}>
                    <Flex style={{ paddingBlock: 6 }}>
                      <GlobalOutlined style={{ fontSize: 20 }} />
                    </Flex>
                    <span style={{ fontSize: 14 }}>{langInfoLabel[lang]}</span>
                  </Flex>
                </Dropdown>
              </MediaQuery>
              {address ? (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <a target="_blank" rel="noopener noreferrer" onClick={disConnect}>
                            {t('global.disconnect')}
                          </a>
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                >
                  <Button
                    icon={<WalletIcon />}
                    style={{ ...connectStyle, width: isMobile ? 160 : 170, fontSize: 13 }}
                    ghost
                    type="primary"
                    loading={connecting}
                  >
                    {shortenAddress(address, 6, 8)}
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  icon={<WalletIcon />}
                  style={{ ...connectStyle, width: isMobile ? 160 : 170, textTransform: 'uppercase' }}
                  ghost
                  onClick={() => dispatch(showWalletModal())}
                  type="primary"
                  loading={connecting}
                >
                  {t('global.connectWallet')}
                </Button>
              )}
              {/* <MediaQuery maxWidth={576}>
                <Dropdown menu={{ items }} trigger={['click']}>
                  <Flex style={{ paddingBlock: 6 }}>
                    <MenuOutlined style={{ fontSize: 20 }} />
                  </Flex>
                </Dropdown>
              </MediaQuery> */}
              <MediaQuery maxWidth={576}>
                <MenuOutlined style={{ fontSize: 20 }} onClick={() => setOpenDrawer(true)} />
              </MediaQuery>
              <MediaQuery maxWidth={576}>
                <Drawer
                  styles={{ content: { backgroundColor: '#181A1F' }, body: { padding: 0 } }}
                  size="large"
                  onClose={() => setOpenDrawer(false)}
                  open={openDrawer}
                >
                  <MobileMenu onClose={() => setOpenDrawer(false)} />
                </Drawer>
              </MediaQuery>
            </Flex>
          </Flex>
        </Flex>
      </Header>
    </>
  );
};

export default AppBar;
