import { useRef, useState } from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppDispatch } from 'hooks/redux';
import { Modal, Flex, Image, Avatar } from 'antd';
import type { InputRef } from 'antd';
import { shortenAddress } from 'utils';
import { useMediaQuery } from 'react-responsive';
import mempool from 'assets/common/mempool.png';
import './index.css';

export default function TradeResultModal({
  open,
  onCancel,
  tradeResult,
}: {
  open: boolean;
  onCancel: () => void;
  tradeResult: any;
}): JSX.Element | null {
  const { t } = useI18n();
  const addressRef = useRef<InputRef>(null);
  const [sliderValue, setSliderValue] = useState(0);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return (
    <Modal
      title={
        <span
          style={{
            color: '#D0E2EF',
            fontSize: isMobile ? 18 : 24,
            fontWeight: isMobile ? 400 : 700,
            textTransform: 'uppercase',
          }}
        >
          {tradeResult?.title || ''}
        </span>
      }
      footer={null}
      width={552}
      focusTriggerAfterClose={false}
      open={open}
      centered
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
      onCancel={onCancel}
    >
      <Flex vertical={true} align="center" className="tradeResultModalBox">
        <Flex style={{ width: '100%', padding: isMobile ? '9px 0 15px' : '16px 0 24px' }}>
          <Flex vertical className="broomList" gap={24}>
            <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
              {tradeResult.list?.map((item: any) => (
                <Flex key={item?.order_no} justify="space-between" align="center">
                  <Flex gap={isMobile ? 8 : 16} style={{ width: isMobile ? '27%' : '33%' }}>
                    <Avatar
                      size={24}
                      {...(tradeResult?.tickerIcon && { src: tradeResult?.tickerIcon })}
                      style={{ fontSize: 12, textTransform: 'uppercase' }}
                    >
                      {tradeResult?.tickerName?.slice(0, 1) || ''}
                    </Avatar>
                    <span style={{ fontSize: isMobile ? 10 : 14 }}>{tradeResult?.tickerName || ''}</span>
                  </Flex>
                  <span style={{ fontSize: isMobile ? 10 : 14 }}>{item?.atom_utxo_value}</span>
                  {tradeResult?.tradeType === 'sweep' && (
                    <span
                      style={{
                        color: '#848E9C',
                        width: isMobile ? '39%' : '33%',
                        textAlign: 'right',
                        marginRight: isMobile ? 12 : 30,
                        fontSize: isMobile ? 10 : 14,
                      }}
                    >
                      {`${item.price.toFixed(2)} sats / ${tradeResult?.tickerName}`}
                    </span>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap={4} style={{ paddingBottom: 14 }} align="center">
          <span style={{ fontSize: isMobile ? 14 : 16, fontWeight: 700, alignSelf: 'flex-start' }}>
            {t('global.transactionSubmitted')}
          </span>
          <Flex gap={8} align="center">
            <span style={{ fontSize: isMobile ? 14 : 16, color: '#848E9C' }}>
              {(tradeResult?.data?.txid && shortenAddress(tradeResult?.data?.txid, 9, 11)) || ''}
            </span>
            <Image
              width={14}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                tradeResult?.data?.txid && window.open(`https://mempool.space/tx/${tradeResult?.data?.txid}`)
              }
              src={mempool}
              preview={false}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
