import bitvm from 'assets/tickerIcon/bitvm.png';
import quark from 'assets/tickerIcon/quark.jpg';
import atom from 'assets/tickerIcon/atom.png';
import infinity from 'assets/tickerIcon/infinity.png';
import abtc from 'assets/tickerIcon/abtc.jpeg';
import doge from 'assets/tickerIcon/doge.png';
import icals from 'assets/tickerIcon/icals.jpeg';
import pepe from 'assets/tickerIcon/pepe.jpeg';
import rekt from 'assets/tickerIcon/rekt.png';
import atomical from 'assets/tickerIcon/atomical.jpeg';
import o from 'assets/tickerIcon/o.png';
import up from 'assets/tickerIcon/7.png';
import atomi from 'assets/tickerIcon/atomi.jpeg';
import atomicals from 'assets/tickerIcon/atomicals.jpeg';
import avm from 'assets/tickerIcon/avm.png';
import btc from 'assets/tickerIcon/btc.png';
import btcs from 'assets/tickerIcon/btcs.jpeg';
import cats from 'assets/tickerIcon/cats.jpeg';
import desat from 'assets/tickerIcon/desat.png';
import dots from 'assets/tickerIcon/dots.png';
import dragon from 'assets/tickerIcon/dragon.png';
import electron from 'assets/tickerIcon/electron.jpeg';
import gem from 'assets/tickerIcon/gem.webp';
import luck from 'assets/tickerIcon/luck.png';
import luke from 'assets/tickerIcon/luke.webp';
import neutron from 'assets/tickerIcon/neutron.jpeg';
import nft from 'assets/tickerIcon/nft.jpg';
import pizza from 'assets/tickerIcon/pizza.png';
import qubit from 'assets/tickerIcon/qubit.png';
import realm from 'assets/tickerIcon/realm.png';
import rune from 'assets/tickerIcon/rune.jpeg';
import vmpx from 'assets/tickerIcon/vmpx.jpeg';

const tickerIcons: { [x: string]: any } = {
  bitvm: bitvm,
  quark: quark,
  atom: atom,
  infinity: infinity,
  abtc: abtc,
  doge: doge,
  icals: icals,
  pepe: pepe,
  rekt: rekt,
  atomical: atomical,
  o: o,
  '7': up,
  atomi: atomi,
  atomicals: atomicals,
  avm: avm,
  btc: btc,
  btcs: btcs,
  cats: cats,
  desat: desat,
  dots: dots,
  dragon: dragon,
  electron: electron,
  gem: gem,
  luck: luck,
  luke: luke,
  neutron: neutron,
  nft: nft,
  pizza: pizza,
  qubit: qubit,
  realm: realm,
  rune: rune,
  vmpx: vmpx,
};

export function getTickerIcon(tickerName: string) {
  return tickerIcons[tickerName] || '';
}
