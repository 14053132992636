import { Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useI18n } from 'react-simple-i18n';
import empty from 'assets/common/empty.svg';

const CustomLocale = (loading: boolean) => {
  const { t } = useI18n();

  return {
    emptyText: <Empty image={empty} description={loading ? t('global.loading') : t('global.noData')} />,
    loadingText: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
  };
};

const indicator = {
  indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
};

export { CustomLocale, indicator };
