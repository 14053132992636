import { useRef, useState, useEffect, useCallback, SetStateAction } from 'react';
import { useI18n } from 'react-simple-i18n';
import { Button, Modal, Flex, Radio, Spin, Input, Divider, Empty, Avatar, message, ConfigProvider, Select } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { EditOutlined, LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { MotorcycleIcon, CarIcon, RocketIcon } from 'assets/icon';
import { useMediaQuery } from 'react-responsive';
import empty from 'assets/common/empty.svg';
import { formatSatoshiPrice } from 'utils';
import { useWallet } from 'hooks/useWallet';
import { estimatefee, splitutxo, confirmsplitutxo, getArc20split } from 'api';
import _ from 'lodash';
import './index.css';

export default function SplitModal({
  open,
  onCancel,
  tickerName,
  btcUsdtPrice,
  tickerIcon,
  tickerId,
  showTrade,
  changeTradeResult,
  atomicalId,
}: {
  open: boolean;
  onCancel: () => void;
  tickerName: string;
  btcUsdtPrice: number;
  tickerIcon: string;
  tickerId: string | number;
  atomicalId: string;
  showTrade: () => void;
  changeTradeResult: (result: any) => void;
}): JSX.Element | null {
  const { t } = useI18n();
  const timerId = useRef<any>();
  const { signPsbt } = useWallet();
  const [step, setStep] = useState(1);
  const [selectSplitUtxo, setSelectSplitUtxo] = useState('');
  const [splitedInfo, setSplitedInfo] = useState<any>({});
  const [feeData, setFeeData] = useState<{ [x: string]: any }>({});
  const [selectValue, setSelectValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [options, setOptions] = useState<{ value: number; label: number }[]>([]);
  const [splitUtxoValues, setSplitUtxoValues] = useState<number[]>([]);
  const [splitedDetail, setSplitedDetail] = useState<any[]>([]);
  const [selectFee, setSelectFee] = useState('Fast');
  const [selectFeeValue, setSelectFeeValue] = useState(0);
  const [customFeeValue, setCustomFeeValue] = useState(0);
  const [splitList, setSplitList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [feeLoading, setFeeLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const feeList = [
    { speed: 'Slow', speedLabel: t('listing.slow'), icon: <MotorcycleIcon />, value: feeData.hourFee || 0 },
    { speed: 'Avg', speedLabel: t('listing.avg'), icon: <CarIcon />, value: feeData.halfHourFee || 0 },
    { speed: 'Fast', speedLabel: t('listing.fast'), icon: <RocketIcon />, value: feeData.fastestFee || 0 },
    {
      speed: 'Custom',
      speedLabel: t('listing.custom'),
      icon: <EditOutlined style={{ fontSize: 18 }} />,
      value: Math.ceil((feeData.fastestFee * 3) / 4) || 0,
    },
  ];

  const getEstimatefee = () => {
    setFeeLoading(true);
    estimatefee()
      .then((res: any) => {
        const { ProposeGasPrice, SafeGasPrice, FastGasPrice } = res.data;
        setFeeData({
          fastestFee: FastGasPrice,
          halfHourFee: ProposeGasPrice,
          hourFee: SafeGasPrice,
          economyFee: ProposeGasPrice,
        });
        setFeeLoading(false);
      })
      .catch((e: any) => {
        message.error(e.message);
        setFeeLoading(false);
      });
  };

  const getSplitListing = useCallback(() => {
    setLoading(true);
    getArc20split(atomicalId)
      .then((arc20: any) => {
        setSplitList(arc20.data.utxos);
        setOptions([{ value: arc20.data.mint_amount, label: arc20.data.mint_amount }]);
        setSelectValue(arc20.data.mint_amount);
        setMinValue(arc20.data.mint_amount);
        setLoading(false);
      })
      .catch((e: any) => {
        setSplitList([]);
        setLoading(false);
      });
  }, [atomicalId]);

  const onChange = (e: RadioChangeEvent) => {
    setSplitedInfo({});
    setSelectSplitUtxo(e.target.value);
  };

  const handleChange = (value: number) => {
    setSelectValue(value);
  };

  useEffect(() => {
    if (selectSplitUtxo && selectValue) {
      const selectSplitUtxoInfo = _.find(splitList, (o) => o.txid === selectSplitUtxo);
      const totalValue = selectSplitUtxoInfo.value;
      const newSplitUtxoValues: SetStateAction<number[]> = [];
      const splitUtxo = (totalValue: number) => {
        if (totalValue > selectValue) {
          newSplitUtxoValues.push(selectValue);
          splitUtxo(totalValue - selectValue);
        } else {
          newSplitUtxoValues.push(totalValue);
        }
      };
      splitUtxo(totalValue);
      setSplitUtxoValues(newSplitUtxoValues);
      const group = _.groupBy(newSplitUtxoValues, (num) => (num === selectValue ? 'equallyDivided' : 'remaining'));
      const newSplitedDetail: SetStateAction<any[]> = [];
      if (group['remaining']) {
        newSplitedDetail.push(`${group['remaining'][0]} * ${group['remaining'].length}`);
      }
      newSplitedDetail.push(`${group['equallyDivided'][0]} * ${group['equallyDivided'].length}`);
      setSplitedDetail(newSplitedDetail);
    }
  }, [selectSplitUtxo, selectValue, splitList]);

  useEffect(() => {
    if (selectSplitUtxo && minValue) {
      const selectSplitUtxoInfo = _.find(splitList, (o) => o.txid === selectSplitUtxo);
      const maxCount = selectSplitUtxoInfo.value / minValue;
      const newOptions = [];
      for (let i = 1; i < maxCount; i++) {
        newOptions.push({ value: i * minValue, label: i * minValue });
      }
      setOptions(newOptions);
    }
  }, [selectSplitUtxo, minValue, splitList]);

  useEffect(() => {
    if (feeData.fastestFee && selectFee !== 'Custom') {
      switch (selectFee) {
        case 'Fast':
          setSelectFeeValue(feeData.fastestFee || 0);
          break;
        case 'Avg':
          setSelectFeeValue(feeData.halfHourFee || 0);
          break;
        case 'Slow':
          setSelectFeeValue(feeData.hourFee || 0);
          break;
        default:
          break;
      }
    }
  }, [feeData, selectFee]);

  useEffect(() => {
    if (open) {
      getEstimatefee();
      timerId.current = setInterval(() => {
        getEstimatefee();
      }, 10000);
    } else {
      clearInterval(timerId.current);
    }

    return () => clearInterval(timerId.current);
  }, [open]);

  useEffect(() => {
    getSplitListing();
  }, [getSplitListing]);

  const customFeeValueOnchange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      if (value) {
        setCustomFeeValue(Number(value));
        setSelectFeeValue(Number(value));
      } else {
        setCustomFeeValue(value);
      }
    }
  };

  const splitBulk = () => {
    setPending(true);
    if (step === 1) {
      const selectSplitUtxoInfo = _.find(splitList, (o) => o.txid === selectSplitUtxo);
      splitutxo({
        atomical_number: Number(tickerId),
        atom_utxo_txid: selectSplitUtxoInfo.txid,
        atom_utxo_value: selectSplitUtxoInfo.value,
        atom_utxo_vout: selectSplitUtxoInfo.vout,
        fee_rate_sat_per_byte: selectFeeValue,
        split_utxo_values: splitUtxoValues,
      })
        .then((res: any) => {
          setSplitedInfo(res.data);
          setPending(false);
          setStep(2);
        })
        .catch((e: any) => {
          setPending(false);
          message.error(e.message);
        });
    } else {
      signPsbt(splitedInfo.unsigned_psbt, (psbt) => {
        if (psbt === 'error') {
          setPending(false);
        } else {
          confirmsplitutxo({
            id: splitedInfo.id,
            signed_pstb: psbt,
          })
            .then((confirmRes: any) => {
              setPending(false);
              changeTradeResult({
                ...confirmRes,
                title: `${t('listing.split')} ${tickerName}`,
                list: splitedDetail.map((item) => ({ atom_utxo_value: item })),
                tickerName: tickerName,
                tickerIcon: tickerIcon,
                tradeType: 'split',
              });
              showTrade();
              onCancel();
            })
            .catch((e: any) => {
              setPending(false);
              message.error(e.message);
            });
        }
      });
    }
  };

  return (
    <Modal
      title={
        !isMobile ? (
          <span style={{ color: '#D0E2EF', fontSize: 24, fontWeight: 700, textTransform: 'uppercase' }}>
            {`${t('listing.split')} ${tickerName}`}
          </span>
        ) : (
          ''
        )
      }
      closable={!isMobile}
      footer={null}
      {...(isMobile && { transitionName: '', maskTransitionName: '' })}
      width={752}
      focusTriggerAfterClose={false}
      className="smSplitModal"
      open={open}
      centered
      destroyOnClose={true}
      // afterOpenChange={(open) => {
      //   if (isMobile) {
      //     document.body.style.overflow = open ? 'hidden' : 'auto';
      //   } else {
      //     document.body.style.height = open ? 'var(--doc-height)' : 'unset';
      //   }
      // }}
      afterOpenChange={(open) => {
        if (!isMobile) {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
      onCancel={onCancel}
    >
      <Flex
        style={{
          height: 64,
          borderBottom: '0.5px solid #292D35',
          paddingInline: 16,
          display: isMobile ? 'flex' : 'none',
        }}
        align="center"
        justify="space-between"
      >
        <ConfigProvider theme={{ components: { Button: { colorText: '#848E9C' } } }}>
          <Button
            onClick={onCancel}
            style={{
              boxShadow: 'unset',
              borderColor: isMobile ? '#181A1F' : '',
              display: isMobile ? 'contents' : 'unset',
            }}
            shape="circle"
            icon={<LeftOutlined />}
          />
        </ConfigProvider>
        <span style={{ fontSize: 17, color: '#E3E5E9', fontWeight: 700, textTransform: 'uppercase' }}>{`${t(
          'listing.split',
        )} ${tickerName}`}</span>
        <div></div>
      </Flex>
      <Flex vertical={true} align="center" className="splitModalBox" style={{ display: step === 1 ? 'flex' : 'none' }}>
        <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: 500, alignSelf: 'flex-start' }}>
          {t('listing.selectUTXO')}
        </span>
        <Flex style={{ width: '100%', padding: isMobile ? '9px 0' : '16px 0' }}>
          <Spin indicator={<LoadingOutlined />} spinning={loading}>
            <Flex vertical className="broomList" gap={24}>
              {splitList.length > 0 ? (
                <Radio.Group name="splitRadiogroup" value={selectSplitUtxo} onChange={onChange}>
                  <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
                    {splitList.map((item) => (
                      <Radio value={item.txid} key={item.txid} style={{ alignItems: 'center' }}>
                        <Flex justify="space-between" align="center">
                          <Flex gap={isMobile ? 8 : 16} style={{ width: isMobile ? '27%' : '33%' }} align="center">
                            <Avatar
                              size={24}
                              {...(tickerIcon && { src: tickerIcon })}
                              style={{ fontSize: 12, textTransform: 'uppercase' }}
                            >
                              {tickerName.slice(0, 1)}
                            </Avatar>
                            <span style={{ fontSize: isMobile ? 10 : 14, textTransform: 'uppercase' }}>
                              {tickerName}
                            </span>
                          </Flex>
                          <span style={{ fontSize: isMobile ? 10 : 14 }}>{item.value}</span>
                        </Flex>
                      </Radio>
                    ))}
                  </Flex>
                </Radio.Group>
              ) : (
                <Flex style={{ height: isMobile ? 92 : 120, width: '100%' }} justify="center" align="center">
                  <Empty
                    image={loading ? null : empty}
                    imageStyle={{ height: isMobile ? 64 : 80 }}
                    description={
                      <span style={{ fontWeight: 700, color: '#798391' }}>{loading ? '' : t('global.noData')}</span>
                    }
                  />
                </Flex>
              )}
            </Flex>
          </Spin>
        </Flex>
        <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: 500, alignSelf: 'flex-start' }}>
          {t('listing.splitSize')}
        </span>
        <Select
          value={selectValue}
          style={{ width: '50%', borderRadius: 4, alignSelf: 'flex-start', marginTop: 16 }}
          listHeight={128}
          onChange={handleChange}
          options={options}
        />
        <Flex
          style={{ height: isMobile ? 122 : 135, width: '100%', position: 'relative' }}
          align="center"
          justify="space-between"
        >
          {feeList.map((item) => {
            if (item.speed === 'Custom') {
              return (
                <Flex
                  className={selectFee === item.speed ? 'gasCard check' : 'gasCard'}
                  justify="center"
                  align="center"
                  gap={8}
                  vertical={true}
                  key={item.speed}
                  onClick={() => {
                    setSelectFeeValue(Math.ceil(item.value));
                    setCustomFeeValue(Math.ceil(item.value));
                    setSelectFee(item.speed);
                  }}
                >
                  <Spin spinning={feeLoading} indicator={<LoadingOutlined />}>
                    <Flex align="center" justify="center" vertical>
                      <Flex align="center" gap={7} vertical={isMobile ? true : false}>
                        <EditOutlined style={{ fontSize: 18 }} />
                        <span style={{ fontSize: isMobile ? 10 : 17, fontWeight: 500 }}>{item.speedLabel}</span>
                      </Flex>
                      <Flex
                        align={isMobile ? 'center' : 'flex-end'}
                        style={{ height: isMobile ? 16 : 27, display: selectFee === item.speed ? 'flex' : 'none' }}
                        gap={2}
                      >
                        <Input
                          className="feeInput"
                          value={customFeeValue}
                          onBlur={() => {
                            if (customFeeValue < Math.ceil(item.value)) {
                              setSelectFeeValue(Math.ceil(item.value));
                              setCustomFeeValue(Math.ceil(item.value));
                            } else {
                              setSelectFeeValue(Math.ceil(customFeeValue));
                              setCustomFeeValue(Math.ceil(customFeeValue));
                            }
                          }}
                          onChange={customFeeValueOnchange}
                        />
                        <span
                          style={{ fontSize: isMobile ? 8 : 17, fontWeight: 500, height: isMobile ? '14px' : 'unset' }}
                        >{` sat / vB`}</span>
                      </Flex>
                    </Flex>
                  </Spin>
                </Flex>
              );
            } else {
              return (
                <Flex
                  className={selectFee === item.speed ? 'gasCard check' : 'gasCard'}
                  justify="center"
                  align="center"
                  gap={8}
                  vertical={true}
                  key={item.speed}
                  onClick={() => {
                    setSelectFeeValue(item.value);
                    setSelectFee(item.speed);
                  }}
                >
                  <Spin spinning={feeLoading} indicator={<LoadingOutlined />}>
                    <Flex align="center" justify="center" vertical>
                      <Flex align="center" gap={7} vertical={isMobile ? true : false}>
                        {item.icon}
                        <span style={{ fontSize: isMobile ? 10 : 17, fontWeight: 500 }}>{item.speedLabel}</span>
                      </Flex>
                      <span
                        style={{ fontSize: isMobile ? 8 : 17, fontWeight: 500, textAlign: 'center' }}
                      >{`${item.value} sat / vB`}</span>
                    </Flex>
                  </Spin>
                </Flex>
              );
            }
          })}
        </Flex>
      </Flex>
      <Flex vertical={true} align="center" className="splitModalBox" style={{ display: step === 2 ? 'flex' : 'none' }}>
        <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: 500, alignSelf: 'flex-start' }}>
          {t('listing.splitDetails')}
        </span>
        <Flex style={{ width: '100%', padding: isMobile ? '9px 0 15px' : '16px 0 24px' }}>
          <Spin indicator={<LoadingOutlined />} spinning={false}>
            <Flex vertical className="broomList" gap={24}>
              <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
                {splitedDetail.map((item) => (
                  <Flex justify="space-between" align="center" key={item}>
                    <Flex gap={isMobile ? 8 : 16} style={{ width: isMobile ? '27%' : '33%' }} align="center">
                      <Avatar
                        size={24}
                        {...(tickerIcon && { src: tickerIcon })}
                        style={{ fontSize: 12, textTransform: 'uppercase' }}
                      >
                        {tickerName.slice(0, 1)}
                      </Avatar>
                      <span style={{ fontSize: isMobile ? 10 : 14, textTransform: 'uppercase' }}>{tickerName}</span>
                    </Flex>
                    <span style={{ fontSize: isMobile ? 10 : 14 }}>{item}</span>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Spin>
        </Flex>
        <Flex className="gasInfoCard" justify="space-between" vertical={true}>
          <Flex align="center" justify="space-between">
            <span style={{ width: '30%', fontSize: isMobile ? 10 : 14 }}>{t('listing.networkFee')}</span>
            <span
              style={{ width: '30%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}
            >{`~${selectFeeValue}sats/vbytes`}</span>
          </Flex>
          <Divider style={{ margin: 0 }} />
          <Flex align="center" justify="space-between">
            <span style={{ fontSize: isMobile ? 10 : 16, fontWeight: 500, color: '#30BD65', width: '30%' }}>
              {t('listing.totalCost')}
            </span>
            <span
              style={{
                fontSize: isMobile ? 10 : 16,
                fontWeight: 500,
                color: '#30BD65',
                width: '30%',
                textAlign: 'end',
              }}
            >
              {`～${((splitedInfo.network_fee || 0) / 100000000).toFixed(8)} BTC`}
            </span>
            <span
              style={{
                fontSize: isMobile ? 10 : 16,
                fontWeight: 500,
                color: '#30BD65',
                width: '35%',
                textAlign: 'end',
              }}
            >
              {`$${formatSatoshiPrice(btcUsdtPrice, splitedInfo.network_fee || 0, 3)}`}
            </span>
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ height: 80, width: '100%' }} justify="center" align="center" gap={16}>
        {step === 2 && (
          <Button
            type="primary"
            onClick={() => setStep(1)}
            style={{
              color: '#1C2025',
              fontWeight: 700,
              fontSize: 16,
              width: isMobile ? 134 : 220,
              height: isMobile ? 32 : 48,
              textTransform: 'uppercase',
            }}
          >
            {t('global.previous')}
          </Button>
        )}
        <Button
          type="primary"
          onClick={splitBulk}
          loading={pending}
          disabled={!selectSplitUtxo}
          style={{
            color: '#1C2025',
            fontWeight: 700,
            fontSize: 16,
            width: isMobile ? 134 : 220,
            height: isMobile ? 32 : 48,
            textTransform: 'uppercase',
          }}
        >
          {step === 1 ? t('global.nextStep') : t('listing.split')}
        </Button>
      </Flex>
    </Modal>
  );
}
