import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import BaseLayout from 'layouts';
import { langData, antdLang } from 'locales';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { useAppSelector } from 'hooks/redux';
import MarketPlace from 'pages/marketPlace';
import Listing from 'pages/listing';
import ShelfInscription from 'pages/shelfInscription';
import Assets from 'pages/assets';
const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const { lang } = useAppSelector((state) => state.application);

  return (
    <ErrorBoundary>
      <Suspense>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#FDD535',
              borderRadius: 2,
              colorBgBase: '#181A1F',
              colorBgContainer: '#181A1F',
              colorBorder: '#202527',
              fontFamily: 'Harmonyos Sans',
            },
            algorithm: darkAlgorithm,
            components: {
              Progress: {
                circleTextColor: '#4CC675',
              },
            },
          }}
          locale={antdLang[lang]}
        >
          <I18nProvider i18n={createI18n(langData, { lang: lang })}>
            <Routes>
              <Route path="/" element={<BaseLayout />}>
                <Route index element={<Navigate to={'/marketplace'} />} />
                <Route path="assets/:address" element={<Assets />} />
                {/* <Route path="profile" element={<Profile />} /> */}
                <Route path="*" element={<Navigate to={'/marketplace'} />} />
                <Route path="marketplace" element={<MarketPlace />}>
                  <Route path="listing/:tickerId" element={<Listing />}>
                    {/* <Route path="shelf-Inscription" element={<ShelfInscription />} /> */}
                  </Route>
                  {/* <Route index element={<Navigate to={'/marketplace/market'} />} />
                  <Route path="market" element={<MarketPlace />}>
                    <Route path="listing" element={<Listing />} />
                  </Route> */}
                  {/* <Route path="stake" element={<Stake />}>
                    <Route path=":tokenId" element={<Detail />} />
                  </Route>
                  <Route path="invitation" element={<Invitatin />} />
                  <Route path="history" element={<History />} /> */}
                </Route>
              </Route>
            </Routes>
          </I18nProvider>
        </ConfigProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
