import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
export interface TxidState {
  errorTxids: string[];
}

const initialState: TxidState = {
  errorTxids: [],
};

export const txidSlice = createSlice({
  name: 'txid',
  initialState,
  reducers: {
    updateErrorTxids: (state, action) => {
      console.log(action);
      const txids = action.payload;
      state.errorTxids = [...state.errorTxids, ...txids];
    },
  },
});

export const { updateErrorTxids } = txidSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default txidSlice.reducer;
