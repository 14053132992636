import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useI18n } from 'react-simple-i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateLanguage } from 'store/features/applicationSlice';
import { showWalletModal } from 'store/features/globalSlice';
import { useWallet } from 'hooks/useWallet';
import './index.css';
import { langInfo } from 'locales';

type MenuItem = Required<MenuProps>['items'][number];

const MobileMenu = ({ onClose }: { onClose: () => void }) => {
  const { pathname } = useLocation();
  const { t } = useI18n();
  const navigate = useNavigate();
  const { address } = useWallet();
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.application);
  const [stateOpenKeys, setStateOpenKeys] = useState([pathname, lang]);
  const [selectedKeys, setSelectedKeys] = useState([pathname, lang]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[],
    icon?: React.ReactNode,
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(t('menu.marketpalce'), '/marketplace'),
    getItem(t('menu.assets'), '/assets'),
    getItem(
      t('menu.language'),
      'language',
      langInfo.map((item) => getItem(item.label, item.value)),
    ),
  ];

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    setStateOpenKeys(openKeys);
  };

  const onSelect: MenuProps['onSelect'] = ({ key }) => {
    if (key.includes('/')) {
      if (key === '/assets' && !address) {
        dispatch(showWalletModal());
      } else {
        if (key === '/assets') {
          navigate({ pathname: `${key}/${address}` });
        } else {
          navigate({ pathname: key });
        }
      }
      setSelectedKeys([key, lang]);
      onClose();
    } else {
      setSelectedKeys([pathname, key]);
      dispatch(updateLanguage(key));
    }
  };

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      className="mobileMenu"
      style={{ width: '100%', borderInlineEnd: 'none' }}
      items={items}
      onSelect={onSelect}
    />
  );
};

export default MobileMenu;
