export const router = [
  {
    title: 'menu.marketpalce',
    path: '/marketplace',
    parentPath: '/marketplace',
    children: [
      // {
      //   title: 'navMenu.tokenList',
      //   path: '/ecosystem/token-list',
      //   link: '',
      //   disabled: false,
      // },
      // {
      //   title: 'navMenu.supportedChains',
      //   path: '/ecosystem/supported-chains',
      //   link: '',
      //   disabled: false,
      // },
    ],
  },
  {
    title: 'menu.assets',
    path: '/assets',
    parentPath: '/assets',
    children: [],
  },
];

export const languages = [
  {
    title: 'English',
    lang: 'enUS',
  },
];
