import { useState } from 'react';
import { Layout, Flex, Image } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useI18n } from 'react-simple-i18n';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import discord from 'assets/common/discord.png';
import github from 'assets/common/github.png';
import m from 'assets/common/m.png';
import tg from 'assets/common/tg.png';
import twitter from 'assets/common/twitter.png';

const { Footer } = Layout;

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#181A1F',
  maxWidth: '1200px',
  padding: '0 16px',
  borderTop: '0.5px solid #2B3139',
  width: '100%',
};

const AppFooter = () => {
  const navigate = useNavigate();
  const { t } = useI18n();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [isVisible, setIsVisible] = useState(false);
  const images = [
    { icon: tg, title: 'tg', url: 'https://t.me/CN2140' },
    // { icon: discord, title: 'discord', url: '' },
    { icon: twitter, title: 'twitter', url: 'https://twitter.com/2140_com' },
    { icon: m, title: 'm', url: 'https://medium.com/@2140market' },
    // { icon: github, title: 'github', url: '' },
  ];
  return (
    <Footer
      style={{
        ...footerStyle,
        display: pathname.includes('/shelf-Inscription') ? 'none' : 'flex',
        height: isMobile ? 82 : 120,
      }}
    >
      <Flex vertical={true} align="center" justify="center" style={{ width: '100%' }} gap={isMobile ? 8 : 24}>
        <Flex gap={16} justify="center">
          {images.map(({ icon, title, url }) => (
            <Image
              onClick={() => window.open(url)}
              key={title}
              src={icon}
              preview={false}
              style={{ height: 32, width: 32, cursor: 'pointer' }}
            />
          ))}
        </Flex>
        <Flex gap={38} justify="center">
          <span style={{ color: '#B7BDC6', fontSize: 12 }}>{`© 2024 ${t('global.market')}`}</span>
          {/* <MediaQuery minWidth={576}>
            <span style={{ color: '#B7BDC6', fontSize: 12 }}>Privacy Policy</span>
            <span style={{ color: '#B7BDC6', fontSize: 12 }}>Disclaimer</span>
          </MediaQuery> */}
        </Flex>
      </Flex>
    </Footer>
  );
};

export default AppFooter;
