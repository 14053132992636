import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
export interface ApplicationState {
  readonly user: { readonly [key: string]: any };
  lang: string;
  token: string;
  walletName: string;
  selectWalletName: string;
  address: string;
  assetDisplay: boolean;
}

const initialState: ApplicationState = {
  user: {},
  lang: 'enUS',
  token: '',
  walletName: '',
  selectWalletName: '',
  address: '',
  assetDisplay: true,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { user } = action.payload;
      state.user = Object.keys(user).length === 0 ? {} : { ...state.user, ...user };
    },
    updateLanguage: (state, { payload }) => {
      state.lang = payload;
    },
    updateToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    changeWalletName: (state, action) => {
      state.walletName = action.payload;
    },
    changeSelctWalletName: (state, action) => {
      state.selectWalletName = action.payload;
    },
    changeAddress: (state, action) => {
      state.address = action.payload;
    },
    showAsset: (state) => {
      state.assetDisplay = true;
    },
    hideAsset: (state) => {
      state.assetDisplay = false;
    },
  },
});

export const {
  updateUser,
  updateLanguage,
  updateToken,
  changeWalletName,
  changeAddress,
  changeSelctWalletName,
  showAsset,
  hideAsset,
} = applicationSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default applicationSlice.reducer;
