import { useState, useEffect, memo } from 'react';
import { Flex, Avatar, Table, message, Spin } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, LoadingOutlined } from '@ant-design/icons';
import { useI18n } from 'react-simple-i18n';
import btc from 'assets/common/btc.png';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getTickerList, getMyFavorites } from 'api';
import { CustomLocale, indicator } from 'constant';
import CurrencyDetailsModal from 'components/CurrencyDetailsModal';
import { shortenAddress, addThousandSeparator, formatSatoshiPrice } from 'utils';
import { getTickerIcon } from 'utils/tickerIcon';
import { showWalletModal } from 'store/features/globalSlice';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useWallet } from 'hooks/useWallet';
import { getTickerDetail } from 'api';
import './index.css';
interface DataType {
  key: React.Key;
  atomical_id: string;
  atomical_number: number;
  ticker_name: string;
  floor_price: number;
  volume_24h: number;
  hourly_change_percentage: string;
  btc_usdt_price: number;
  market_cap: number;
  holders: number;
  listing_count: number;
  total_supply: number;
  total_volume: number;
  mint_bitworkc: string;
  deployed_at: number;
  deployed_by: string;
  mint_ed: string;
  ticker_icon: string;
}

const MarketPlaceTableList = memo(({ type }: { type: string }) => {
  const { t } = useI18n();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isPad = useMediaQuery({ query: '(max-width: 992px)' });
  const headerNumber = isMobile ? 3 : isPad ? 6 : 9;
  const [data, setData] = useState<DataType[]>([]);
  const [tickerDetail, setTickerDetail] = useState<{ [x: string]: any }>({});
  const { reloadFollowPage } = useAppSelector((state) => state.global);
  const { address } = useWallet();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (type === 'all') {
      setLoading(true);
      getTickerList({ type })
        .then((res: any) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((e: any) => {
          message.error(e.message);
          setLoading(false);
        });
    }
  }, [type]);

  useEffect(() => {
    if (type === 'follow') {
      if (address) {
        setLoading(true);
        getMyFavorites()
          .then((res: any) => {
            setData(res.data);
            setLoading(false);
          })
          .catch((e: any) => {
            message.error(e.message);
            setLoading(false);
          });
      } else {
        setData([]);
      }
    }
  }, [type, reloadFollowPage, address]);

  const getTickerDetailById = (tickerId: number) => {
    setSpinning(true);
    getTickerDetail(tickerId.toString())
      .then((res: any) => {
        setTickerDetail(res.data);
        setSpinning(false);
        setOpen(true);
      })
      .catch((e: any) => {
        message.error(e.message);
        setSpinning(false);
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t('marketpalce.token'),
      dataIndex: 'ticker_name',
      width: 130,
      render: (text, record) => {
        return (
          <Flex
            align="center"
            gap={8}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(
                {
                  pathname: `/marketplace/listing/${record.atomical_number}`,
                },
                { state: { atomicalId: record.atomical_id } },
              );
            }}
          >
            <Avatar
              size={24}
              {...(getTickerIcon(record.ticker_name) && { src: getTickerIcon(record.ticker_name) })}
              style={{ fontSize: 12, textTransform: 'uppercase' }}
            >
              {text.slice(0, 1)}
              {/* <span style={{ fontSize: 12, textTransform: 'uppercase', lineHeight: '16px' }}>{text.slice(0, 1)}</span> */}
            </Avatar>
            <span style={{ fontSize: isMobile ? 12 : 14, fontWeight: 500, textTransform: 'uppercase' }}>
              {text.length > 12 ? shortenAddress(text, 3, 4) : text}
            </span>
          </Flex>
        );
      },
    },
    {
      title: t('marketpalce.price'),
      dataIndex: 'floor_price',
      sorter: (a, b) => a.floor_price - b.floor_price,
      render: (text, record) => {
        return (
          <Flex vertical={true}>
            <span style={{ fontSize: isMobile ? 12 : 14, fontWeight: isMobile ? 500 : 700 }}>{`${text} sats`}</span>
            <span style={{ fontSize: 10, fontWeight: 400, color: '#858E9C' }}>{`$${formatSatoshiPrice(
              record.btc_usdt_price,
              text,
            )}`}</span>
          </Flex>
        );
      },
    },
    {
      title: t('marketpalce.dayChange'),
      dataIndex: 'hourly_change_percentage',
      sorter: (a, b) => {
        const percentageA = parseFloat(a.hourly_change_percentage.replace('%', ''));
        const percentageB = parseFloat(b.hourly_change_percentage.replace('%', ''));
        return percentageA - percentageB;
      },
      render: (text, record) => {
        return (
          <Flex align="center" gap={4}>
            {text.includes('-') ? (
              <CaretDownOutlined style={{ color: '#EB4B6D' }} />
            ) : (
              <CaretUpOutlined style={{ color: '#30BD65' }} />
            )}
            <span
              style={{
                fontSize: isMobile ? 12 : 14,
                fontWeight: isMobile ? 500 : 700,
                color: text.includes('-') ? '#EB4B6D' : '#30BD65',
              }}
            >
              {text}
            </span>
          </Flex>
        );
      },
      // filters: [
      //   {
      //     text: 'London',
      //     value: 'London',
      //   },
      //   {
      //     text: 'New York',
      //     value: 'New York',
      //   },
      // ],
      // onFilter: (value, record) => record.risesFallsDay.startsWith(value),
      filterSearch: true,
    },
    // {
    //   title: '24H Total Volume',
    //   dataIndex: 'volume_24h',
    //   width: 155,
    //   sorter: (a, b) => a.volume_24h - b.volume_24h,
    //   render: (text, record) => {
    //     return (
    //       <Flex vertical={true}>
    //         <Flex gap={4} align="center">
    //           <Avatar size={13} src={btc} />
    //           <span style={{ fontSize: 14, fontWeight: 700 }}>{(text / 100000000).toFixed(5)}</span>
    //         </Flex>
    //         <span style={{ fontSize: 10, fontWeight: 700, color: '#858E9C' }}>{`$${formatSatoshiPrice(
    //           record.btc_usdt_price,
    //           text,
    //         )}`}</span>
    //       </Flex>
    //     );
    //   },
    // },
    {
      title: t('marketpalce.totalVolume'),
      dataIndex: 'total_volume',
      sorter: (a, b) => a.total_volume - b.total_volume,
      render: (text, record) => {
        return (
          <Flex vertical={true}>
            <Flex gap={4} align="center">
              <Avatar size={13} src={btc} />
              <span style={{ fontSize: 14, fontWeight: 700 }}>{(text / 100000000).toFixed(5)}</span>
            </Flex>
            <span style={{ fontSize: 10, fontWeight: 700, color: '#858E9C' }}>{`$${formatSatoshiPrice(
              record.btc_usdt_price,
              text,
            )}`}</span>
          </Flex>
        );
      },
    },
    {
      title: t('marketpalce.marketCap'),
      dataIndex: 'market_cap',
      sorter: (a, b) => a.market_cap - b.market_cap,
      render: (text, record) => {
        return (
          <Flex vertical={true}>
            <Flex gap={4} align="center">
              <Avatar size={13} src={btc} />
              <span style={{ fontSize: 14, fontWeight: 700 }}>{(text / 100000000).toFixed(5)}</span>
            </Flex>
            <span style={{ fontSize: 10, fontWeight: 700, color: '#858E9C' }}>{`$${formatSatoshiPrice(
              record.btc_usdt_price,
              text,
            )}`}</span>
          </Flex>
        );
      },
    },
    {
      title: t('marketpalce.totalSupply'),
      dataIndex: 'total_supply',
      render: (text, record) => {
        return <span>{addThousandSeparator(text)}</span>;
      },
      sorter: (a, b) => a.total_supply - b.total_supply,
    },
    {
      title: t('marketpalce.holders'),
      dataIndex: 'holders',
      render: (text, record) => {
        return <span>{addThousandSeparator(text)}</span>;
      },
      sorter: (a, b) => a.holders - b.holders,
    },
    // {
    //   title: 'Details',
    //   dataIndex: 'operate',
    //   render: (text, record) => (
    //     <span
    //       onClick={() => getTickerDetailById(record.atomical_number)}
    //       style={{ fontSize: 14, borderBottom: '1px solid #fff', cursor: 'pointer' }}
    //     >
    //       {'Details'}
    //     </span>
    //   ),
    // },
  ];

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setPageSize(pagination.pageSize || 10);
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Flex vertical={true} gap={isMobile ? 0 : 24} className="marketPlaceTableListBpx">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} spinning={spinning} fullscreen />
      <CurrencyDetailsModal tickerDetail={tickerDetail} open={open} onCancel={() => setOpen(false)} />
      <Table
        bordered={false}
        showSorterTooltip={false}
        size="small"
        loading={{
          spinning: loading,
          ...indicator,
        }}
        pagination={{ pageSize }}
        columns={columns.slice(0, headerNumber)}
        dataSource={data}
        rowKey="atomical_id"
        locale={CustomLocale(loading)}
        onChange={onChange}
        sticky={{ offsetHeader: 64 }}
      />
    </Flex>
  );
});
MarketPlaceTableList.displayName = 'MarketPlaceTableList';

export default MarketPlaceTableList;
