import React, { useState } from 'react';
import Head from 'react-headroom';
import './index.css';

interface ScrollHeaderProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ScrollHeader: React.FC<ScrollHeaderProps> = ({ children, style }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <Head style={{ color: '#181a1f' }} onPin={() => setIsVisible(true)} onUnpin={() => setIsVisible(false)}>
        head
      </Head>
      <div className={`scroll-header ${isVisible ? '' : 'hidden'}`} style={style}>
        {children}
      </div>
    </>
  );
};

export default ScrollHeader;
