import { useState, memo } from 'react';
import { Flex, Row, Col, Card, Avatar, Image, Tabs, Button } from 'antd';
import { RightOutlined, CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { useI18n } from 'react-simple-i18n';
import bnb from 'assets/common/bnb.png';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { TabsProps } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import CurrencyDetailsModal from 'components/CurrencyDetailsModal';
import MarketPlaceTableList from 'components/MarketPlaceTableList';
import { showWalletModal } from 'store/features/globalSlice';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import logoMini from 'assets/common/logoMini.png';
import bannerH5 from 'assets/common/bannerH5.png';
import banner from 'assets/common/banner.png';
import { useWallet } from 'hooks/useWallet';
import './index.css';

interface DataType {
  key: React.Key;
  icon: string;
  token: string;
  price: number;
  risesFallsDay: string;
  totalTradingDay: string;
  totalValue: string;
  marketValue: string;
  totalSupply: string;
  tokenHolders: string;
  operate: string;
}

const overviewStyle: React.CSSProperties = {
  width: '100%',
  padding: '60px 0 40px',
};

const MarketOverview = () => {
  const [tab, setTab] = useState(1);
  const titles = [
    { title: 'Overview', value: 1 },
    { title: 'Protocols', value: 2 },
  ];
  const overviews = [
    {
      title: 'Hot',
      children: [
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
      ],
    },
    {
      title: 'Latest Listing',
      children: [
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
      ],
    },
    {
      title: 'Maximum Change',
      children: [
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
      ],
    },
    {
      title: 'Highest Transaction',
      children: [
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: false, number: '0.82%' },
        { icon: bnb, name: 'BNB', price: '$256.3', upwardTrend: true, number: '0.82%' },
      ],
    },
  ];
  return (
    <Flex style={overviewStyle} vertical={true} gap={24}>
      <Flex gap={32} align="center">
        {titles.map((item) => (
          <div
            key={item.title}
            style={{
              fontSize: tab === item.value ? 26 : 20,
              color: tab === item.value ? '#fff' : '#848E9C',
              fontWeight: 700,
              cursor: 'pointer',
            }}
            onClick={() => setTab(item.value)}
          >
            {item.title}
          </div>
        ))}
      </Flex>
      <Row gutter={[32, 32]}>
        {overviews.map((item) => (
          <Col xs={12} lg={6} key={item.title}>
            <Card>
              <Flex justify="space-between" style={{ padding: '12px 0' }}>
                <span style={{ fontWeight: 500 }}>{item.title}</span>
                {item.children.length > 3 ? (
                  <Flex align="center" gap={2} style={{ cursor: 'pointer' }}>
                    <span>More</span>
                    <RightOutlined />
                  </Flex>
                ) : null}
              </Flex>
              {item.children.slice(0, 3).map((childrenItem, index) => (
                <Flex justify="space-between" key={index}>
                  <Flex align="center" gap={24}>
                    <Flex align="center" gap={8}>
                      <Avatar size={24} src={childrenItem.icon} />
                      <span>{childrenItem.name}</span>
                    </Flex>
                    <span style={{ fontWeight: 700 }}>{childrenItem.price}</span>
                  </Flex>
                  <span style={{ fontWeight: 700, color: childrenItem.upwardTrend ? '#EB4B6D' : '#30BD65' }}>{`${
                    childrenItem.upwardTrend ? '+' : '-'
                  }${childrenItem.number}`}</span>
                </Flex>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

const MarketPlace = () => {
  const { pathname } = useLocation();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [tabValue, setTabValue] = useState('1');
  const { address } = useWallet();
  const onChange = (key: string) => {
    if (key === '2' && !address) {
      dispatch(showWalletModal());
    } else {
      setTabValue(key);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('marketpalce.all'),
      children: <MarketPlaceTableList type={'all'} />,
    },
    // {
    //   key: '1',
    //   label: 'Hot',
    //   children: <MarketPlaceTableList type={'hot'} />,
    // },
    {
      key: '2',
      label: t('marketpalce.myFavorites'),
      children: <MarketPlaceTableList type={'follow'} />,
    },
  ];

  const goSplitOrList = () => {
    if (address) {
      navigate({ pathname: `/assets/${address}` });
    } else {
      dispatch(showWalletModal());
    }
  };

  return (
    <>
      <Flex flex={1} vertical={true} style={{ width: '100%' }}>
        <Outlet />
      </Flex>
      <Flex
        flex={1}
        vertical={true}
        className="marketPlaceBox"
        style={{ width: '100%', display: pathname === '/marketplace' ? 'flex' : 'none' }}
      >
        <div className="topCard"></div>
        <MediaQuery maxWidth={576}>
          <div style={{ height: 64 }}></div>
        </MediaQuery>
        {/* <MediaQuery minWidth={576}>
          <MarketOverview />
        </MediaQuery> */}
        <Flex align="center" style={{ width: '100%', height: isMobile ? 140 : 250 }} justify="center">
          <Flex align="flex-start" className="banner" vertical justify="space-between">
            <MediaQuery minWidth={576}>
              <Flex align="center" gap={0} style={{ marginBottom: 8 }}>
                <Image style={{ cursor: 'pointer' }} width={56} src={logoMini} preview={false} />
                <span style={{ color: '#EAECEF', fontSize: 14 }}>{t('marketpalce.banner1')}</span>
              </Flex>
              <span style={{ color: '#FDD535', fontSize: 22, fontWeight: 700 }}>{t('marketpalce.banner2')}</span>
              <Flex align="flex-start" vertical gap={8}>
                <span style={{ color: '#ffffff99', fontSize: 12 }}>{t('marketpalce.banner3')}</span>
                <span style={{ color: '#ffffff99', fontSize: 12 }}>{t('marketpalce.banner4')}</span>
              </Flex>
            </MediaQuery>
            <MediaQuery maxWidth={576}>
              <span style={{ color: '#FDD535', fontSize: 12, fontWeight: 700 }}>{t('marketpalce.banner5')}</span>
              <span style={{ color: '#fff', fontSize: 10, fontWeight: 500, width: 153, textAlign: 'left' }}>
                {t('marketpalce.banner6')}
              </span>
            </MediaQuery>
            <Button
              style={{ color: '#000', width: isMobile ? 88 : 140, fontSize: isMobile ? 12 : 15, fontWeight: 700 }}
              type="primary"
              size="small"
              onClick={goSplitOrList}
            >
              {'List / Split'}
            </Button>
          </Flex>
        </Flex>
        <Tabs defaultActiveKey="1" activeKey={tabValue} items={items} onChange={onChange} />
      </Flex>
    </>
  );
};

export default MarketPlace;
