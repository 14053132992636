import { useI18n } from 'react-simple-i18n';
import { useAppDispatch } from 'hooks/redux';
import { Button, Modal, Flex, ConfigProvider, Input, Divider, Checkbox, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import './index.css';

type FieldType = {
  tokenname?: string;
  price?: string;
  amount?: string;
  recevieaddress?: string;
  remember?: string;
};

export default function BuyModal({ open, onCancel }: { open: boolean; onCancel: () => void }): JSX.Element | null {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={<span style={{ color: '#D0E2EF', fontSize: 18, fontWeight: 700 }}>BID</span>}
      footer={null}
      width={640}
      focusTriggerAfterClose={false}
      open={open}
      centered
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
      onCancel={onCancel}
    >
      <Flex className="buyModalBox">
        <Flex vertical={true} align="center" className="buyModalInfo">
          <ConfigProvider
            theme={{
              token: {
                colorBgBase: '#0e1011',
                colorBgContainer: '#101114',
              },
              components: {
                Input: {
                  colorBorder: '#101114',
                  colorText: '#fff',
                  paddingBlock: 14,
                  borderRadius: 8,
                },
                Form: {
                  labelColor: '#858E9C',
                },
              },
            }}
          >
            <Form
              name="bid"
              layout="vertical"
              style={{ width: '100%' }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label="Token name"
                name="tokenname"
                // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  prefix={<span style={{ fontSize: 16, paddingRight: 10 }}>AMOT</span>}
                  placeholder="Balance（0）"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label={
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    <span>Price</span>
                    <span>Floor Price（0.099Sats/Rast）</span>
                  </Flex>
                }
                labelCol={{ span: 24 }}
                labelAlign="right"
                colon={false}
                name="price"
              >
                <Input
                  placeholder="Price"
                  suffix={
                    <Flex align="center" gap={8}>
                      <span style={{ fontSize: 16, paddingLeft: 10 }}>sats / RAST</span>
                      <span style={{ fontWeight: 700, color: '#858E9C' }}>$0.0</span>
                    </Flex>
                  }
                />
              </Form.Item>

              <Form.Item<FieldType> label="Amount" name="amount">
                <Input placeholder="Amount" suffix={<span style={{ fontWeight: 500, color: '#858E9C' }}>RAST</span>} />
              </Form.Item>

              <Form.Item<FieldType> label="Receive Address" name="recevieaddress">
                <Input
                  placeholder="Receive Address"
                  suffix={<EditOutlined style={{ color: '#858E9C', fontSize: 22 }} />}
                />
              </Form.Item>
              <Flex className="gasInfoCard" vertical justify="space-between">
                <Flex justify="space-between">
                  <span>Total Cost</span>
                  <Flex vertical align="flex-end">
                    <span>10 sats</span>
                    <span style={{ fontSize: 12, fontWeight: 700, color: '#858E9C' }}>$1,4257.762</span>
                  </Flex>
                </Flex>
                <Divider style={{ margin: 0 }} />
                <Flex justify="space-between">
                  <span>Service Fee</span>
                  <Flex vertical align="flex-end">
                    <span>0.4% 1ATOM</span>
                    <span style={{ fontSize: 12, fontWeight: 700, color: '#858E9C' }}>$1,4257.762</span>
                    <span style={{ fontSize: 10, color: '#858E9C' }}>(Only charged when order filled)</span>
                  </Flex>
                </Flex>
              </Flex>

              <Form.Item<FieldType>
                name="remember"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                style={{ textAlign: 'center' }}
                className="checkApprove"
              >
                <Checkbox>Approve all balances</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
                <ConfigProvider theme={{ token: { colorPrimary: '#30BD65' } }}>
                  <Button type="primary" htmlType="submit" style={{ width: '220px', height: 48 }}>
                    BID
                  </Button>
                </ConfigProvider>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </Flex>
      </Flex>
    </Modal>
  );
}
