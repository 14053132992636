import { useRef, useState, useEffect } from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppDispatch } from 'hooks/redux';
import { Button, Modal, Flex, ConfigProvider, Spin, Input, Divider, Slider, Avatar, message } from 'antd';
import type { InputRef } from 'antd';
import { EditOutlined, ReloadOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { MotorcycleIcon, CarIcon, RocketIcon } from 'assets/icon';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { BroomIcon } from 'assets/icon';
import { formatSatoshiPrice, shortenAddress } from 'utils';
import { useWallet } from 'hooks/useWallet';
import { estimatefee, marketfeebp, bulkbuy, confirmbulkbuy, cancelpayment } from 'api';
import _ from 'lodash';
import './index.css';

export default function SweepModal({
  open,
  onCancel,
  isBuy,
  tickerList,
  tickerName,
  floorPrice,
  btcUsdtPrice,
  tickerIcon,
  tickerId,
  getSweepListing,
  showTrade,
  changeTradeResult,
  reloadTickerListing,
  buyType,
}: {
  open: boolean;
  onCancel: () => void;
  isBuy: boolean;
  tickerList: any[];
  tickerName: string;
  floorPrice: number;
  btcUsdtPrice: number;
  tickerIcon: string;
  tickerId: string;
  getSweepListing: (callback?: () => void) => void;
  showTrade: () => void;
  changeTradeResult: (result: any) => void;
  reloadTickerListing: () => void;
  buyType: string;
}): JSX.Element | null {
  const { t } = useI18n();
  const addressRef = useRef<InputRef>(null);
  const timerId = useRef<any>();
  const { address, signPsbt } = useWallet();
  const [recevieaddress, setRecevieaddress] = useState(address);
  const [sliderValue, setSliderValue] = useState(1);
  const [feeData, setFeeData] = useState<{ [x: string]: any }>({});
  const [selectFee, setSelectFee] = useState('Fast');
  const [selectFeeValue, setSelectFeeValue] = useState(0);
  const [customFeeValue, setCustomFeeValue] = useState(0);
  const [takerFee, setTakerFee] = useState(0);
  const [selectTickerList, setSelectTickerList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [feeLoading, setFeeLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const allSats = useRef(0);

  const feeList = [
    { speed: 'Slow', speedLabel: t('listing.slow'), icon: <MotorcycleIcon />, value: feeData.hourFee || 0 },
    { speed: 'Avg', speedLabel: t('listing.avg'), icon: <CarIcon />, value: feeData.halfHourFee || 0 },
    { speed: 'Fast', speedLabel: t('listing.fast'), icon: <RocketIcon />, value: feeData.fastestFee || 0 },
    {
      speed: 'Custom',
      speedLabel: t('listing.custom'),
      icon: <EditOutlined style={{ fontSize: 18 }} />,
      value: Math.ceil((feeData.fastestFee * 3) / 4) || 0,
    },
  ];

  useEffect(() => {
    setSelectTickerList(tickerList);
    setSliderValue(tickerList.length);
  }, [tickerList]);

  useEffect(() => {
    allSats.current = 0;
    selectTickerList.forEach((element) => {
      const sats = element.price * element.atom_utxo_value;
      allSats.current = allSats.current + sats;
    });
  }, [selectTickerList]);

  const getEstimatefee = () => {
    setFeeLoading(true);
    estimatefee()
      .then((res: any) => {
        const { ProposeGasPrice, SafeGasPrice, FastGasPrice } = res.data;
        setFeeData({
          fastestFee: FastGasPrice,
          halfHourFee: ProposeGasPrice,
          hourFee: SafeGasPrice,
          economyFee: ProposeGasPrice,
        });
        setFeeLoading(false);
      })
      .catch((e: any) => {
        message.error(e.message);
        setFeeLoading(false);
      });
  };

  useEffect(() => {
    if (feeData.fastestFee && selectFee !== 'Custom') {
      switch (selectFee) {
        case 'Fast':
          setSelectFeeValue(feeData.fastestFee || 0);
          break;
        case 'Avg':
          setSelectFeeValue(feeData.halfHourFee || 0);
          break;
        case 'Slow':
          setSelectFeeValue(feeData.hourFee || 0);
          break;
        default:
          break;
      }
    }
  }, [feeData, selectFee]);

  useEffect(() => {
    if (open) {
      getEstimatefee();
      timerId.current = setInterval(() => {
        getEstimatefee();
      }, 10000);
    } else {
      clearInterval(timerId.current);
    }

    return () => clearInterval(timerId.current);
  }, [open]);

  useEffect(() => {
    marketfeebp({ ticker_id: tickerId })
      .then((res: any) => {
        setTakerFee(res.data.taker_fee_bp / 100);
      })
      .catch((e: any) => {
        message.error(e.message);
      });
  }, [tickerId]);

  const reload = () => {
    setLoading(true);
    getSweepListing(() => {
      setLoading(false);
    });
  };

  const customFeeValueOnchange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      if (value) {
        setCustomFeeValue(Number(value));
        setSelectFeeValue(Number(value));
      } else {
        setCustomFeeValue(value);
      }
    }
  };

  const delTickers = (ticker: any[]) => {
    if (selectTickerList.length < 2) return;
    const newUtxo = _.differenceBy(selectTickerList, ticker, 'order_no');
    setSelectTickerList(newUtxo);
    setSliderValue(newUtxo.length);
  };

  const sweepBulk = () => {
    setPending(true);
    if (isBuy) {
      const orderNos = selectTickerList.map((item) => item.order_no);
      bulkbuy({ order_nos: orderNos, buyer_gas_price: selectFeeValue, buyer_receive_address: recevieaddress })
        .then((res: any) => {
          signPsbt(res.data.unsigned_psbt, (psbt) => {
            if (psbt === 'error') {
              setPending(false);
              cancelpayment({ id: res.data.id })
                .then(() => {
                  reloadTickerListing();
                })
                .catch(() => {});
            } else {
              confirmbulkbuy({
                id: res.data.id,
                signed_pstb: psbt,
              })
                .then((confirmRes: any) => {
                  reloadTickerListing();
                  setPending(false);
                  changeTradeResult({
                    ...confirmRes,
                    title: `${isBuy ? t('listing.buy') : t('listing.sell')} ${tickerName}`,
                    list: selectTickerList,
                    tickerName: tickerName,
                    tickerIcon: tickerIcon,
                    tradeType: 'sweep',
                  });
                  showTrade();
                  onCancel();
                })
                .catch((e: any) => {
                  setPending(false);
                  cancelpayment({ id: res.data.id })
                    .then(() => {
                      reloadTickerListing();
                    })
                    .catch(() => {});
                  message.error(e.message);
                });
            }
          });
          // confirmbulkbuy({
          //   id: res.data.id,
          //   signed_pstb: 'seller_signed_pstb',
          // })
          //   .then((confirmRes: any) => {
          //     console.log(confirmRes.data);
          //     // setOpenTradeResult(true);
          //   })
          //   .catch((e: any) => {
          //     message.error(e.message);
          //   });
        })
        .catch((e: any) => {
          if (buyType === 'sweep') {
            reload();
          } else {
            reloadTickerListing();
            onCancel();
          }
          setPending(false);
          message.error(e.message);
        });
    }
  };

  const isBitcoinMainnetAddress = (value: string) => {
    const bitcoinMainnetRegex = /^(1|3|bc1[qp]).*/i;
    return bitcoinMainnetRegex.test(value);
  };

  return (
    <Modal
      title={
        <span
          style={{
            color: '#D0E2EF',
            fontSize: isMobile ? 18 : 24,
            fontWeight: isMobile ? 400 : 700,
            textTransform: 'uppercase',
          }}
        >
          {`${isBuy ? t('listing.buy') : t('listing.sell')} ${tickerName}`}
        </span>
      }
      footer={null}
      width={752}
      focusTriggerAfterClose={false}
      open={open}
      centered
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if (isMobile) {
          document.body.style.overflow = open ? 'hidden' : 'auto';
        } else {
          document.body.style.height = open ? 'var(--doc-height)' : 'unset';
        }
      }}
      styles={{
        header: { background: '#17191D', textAlign: 'center', marginBottom: 0 },
        content: { background: '#17191D', borderRadius: 8, paddingBottom: 0 },
      }}
      onCancel={onCancel}
    >
      <Flex vertical={true} align="center" className="sweepModalBox">
        {tickerList.length > 1 ? (
          <Flex style={{ width: '100%' }} align="center" gap={8}>
            {/* <Image width={24} src={broom} preview={false} /> */}
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    handleColor: '#FDD535',
                    handleActiveColor: '#FDD535',
                    trackBg: '#FDD535',
                    trackHoverBg: '#FDD535',
                    railBg: '#848E9C',
                    railHoverBg: '#848E9C',
                    railSize: 2,
                  },
                },
              }}
            >
              <Button
                style={{
                  boxShadow: 'unset',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={reload}
                icon={<ReloadOutlined style={{ fontSize: 12 }} />}
              />
              {/* <span style={{ minWidth: 66, color: '#858E9C', fontWeight: 700 }}>{`Sweep ${sliderValue}`}</span> */}
              <Slider
                onChange={(value) => {
                  setSliderValue(value);
                  setSelectTickerList(tickerList.slice(0, value));
                }}
                tooltip={{ formatter: null }}
                defaultValue={1}
                value={sliderValue}
                max={tickerList.length}
                min={1}
                style={{ flex: 1 }}
              />
            </ConfigProvider>
            <span style={{ color: '#848E9C', fontWeight: 700, minWidth: 18, textAlign: 'center' }}>
              {selectTickerList.length}
            </span>
            <Button
              style={{ color: '#000', paddingInline: 6 }}
              size="small"
              type="primary"
              icon={<BroomIcon style={{ fontSize: 22 }} />}
            >
              {t('listing.sweep')}
            </Button>
          </Flex>
        ) : null}
        <Flex style={{ width: '100%', padding: isMobile ? '9px 0 15px' : '16px 0 24px' }}>
          <Flex vertical className="broomList" gap={24}>
            <Spin style={{ maxHeight: 122 }} indicator={<LoadingOutlined />} spinning={loading}>
              <Flex vertical gap={isMobile ? 10 : 24} className="broomListBox">
                {selectTickerList.slice(0, sliderValue).map((item) => (
                  <Flex key={item.atom_utxo_txid} justify="space-between" align="center">
                    <Flex gap={isMobile ? 8 : 16} style={{ width: isMobile ? '27%' : '33%' }}>
                      <Avatar
                        size={24}
                        {...(tickerIcon && { src: tickerIcon })}
                        style={{ fontSize: 12, textTransform: 'uppercase' }}
                      >
                        {tickerName.slice(0, 1)}
                      </Avatar>
                      <span style={{ fontSize: isMobile ? 10 : 14, textTransform: 'uppercase' }}>{tickerName}</span>
                    </Flex>
                    <span style={{ fontSize: isMobile ? 10 : 14 }}>{item.atom_utxo_value}</span>
                    <Flex gap={8} align="center" style={{ width: '33%', marginRight: 8 }} justify="flex-end">
                      <span
                        style={{
                          color: '#848E9C',
                          textAlign: 'right',
                          fontSize: isMobile ? 10 : 14,
                        }}
                      >
                        {`${item.price.toFixed(2)} sats / ${tickerName}`}
                      </span>
                      {selectTickerList.length > 1 ? (
                        <Button
                          size="small"
                          onClick={() => delTickers([item])}
                          style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
                          icon={<DeleteOutlined />}
                          type="default"
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Spin>
          </Flex>
        </Flex>
        <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: 500, alignSelf: 'flex-start' }}>
          {t('listing.receiveAddress')}
        </span>
        <Flex style={{ height: isMobile ? 34 : 68, width: '100%', borderBottom: '1px solid #2C2C2C' }} align="center">
          <Input
            style={{ fontSize: isMobile ? 14 : 18, fontWeight: 500, padding: 0 }}
            ref={addressRef}
            placeholder={t('listing.receiveAddressPlh')}
            variant="borderless"
            onChange={(e) => setRecevieaddress(e.target.value)}
            value={recevieaddress}
          />
          <MediaQuery minWidth={576}>
            <EditOutlined onClick={() => addressRef.current?.focus()} style={{ fontSize: 18, marginRight: 9.5 }} />
          </MediaQuery>
        </Flex>
        <Flex
          style={{ height: isMobile ? 122 : 135, width: '100%', position: 'relative' }}
          align="center"
          justify="space-between"
        >
          {!isBitcoinMainnetAddress(recevieaddress) && (
            <span style={{ fontSize: 12, position: 'absolute', top: 2, left: 0, color: '#ff4d4f' }}>
              {t('listing.receiveAddressTip')}
            </span>
          )}
          {feeList.map((item) => {
            if (item.speed === 'Custom') {
              return (
                <Spin key={item.speed} spinning={feeLoading} indicator={<LoadingOutlined />}>
                  <Flex
                    className={selectFee === item.speed ? 'gasCard check' : 'gasCard'}
                    justify="center"
                    align="center"
                    gap={8}
                    vertical={true}
                    // key={item.speed}
                    onClick={() => {
                      setSelectFeeValue(Math.ceil(item.value));
                      setCustomFeeValue(Math.ceil(item.value));
                      setSelectFee(item.speed);
                    }}
                  >
                    <Flex align="center" gap={7} vertical={isMobile ? true : false}>
                      {item.icon}
                      <span style={{ fontSize: isMobile ? 10 : 17, fontWeight: 500 }}>{item.speedLabel}</span>
                    </Flex>
                    <Flex
                      align="flex-end"
                      style={{ height: 27, display: selectFee === item.speed ? 'flex' : 'none' }}
                      gap={2}
                    >
                      <Input
                        className="feeInput"
                        value={customFeeValue}
                        onBlur={() => {
                          if (customFeeValue < Math.ceil(item.value)) {
                            setSelectFeeValue(Math.ceil(item.value));
                            setCustomFeeValue(Math.ceil(item.value));
                          } else {
                            setSelectFeeValue(Math.ceil(customFeeValue));
                            setCustomFeeValue(Math.ceil(customFeeValue));
                          }
                        }}
                        onChange={customFeeValueOnchange}
                      />
                      <span style={{ fontSize: 17, fontWeight: 500 }}>{' sat / vB'}</span>
                    </Flex>
                  </Flex>
                </Spin>
              );
            } else {
              return (
                <Spin key={item.speed} spinning={feeLoading} indicator={<LoadingOutlined />}>
                  <Flex
                    className={selectFee === item.speed ? 'gasCard check' : 'gasCard'}
                    justify="center"
                    align="center"
                    gap={8}
                    vertical={true}
                    // key={item.speed}
                    onClick={() => {
                      setSelectFeeValue(item.value);
                      setSelectFee(item.speed);
                    }}
                  >
                    <Flex align="center" gap={7} vertical={isMobile ? true : false}>
                      {item.icon}
                      <span style={{ fontSize: isMobile ? 10 : 17, fontWeight: 500 }}>{item.speedLabel}</span>
                    </Flex>
                    <span style={{ fontSize: isMobile ? 8 : 17, fontWeight: 500 }}>{`${item.value} sat / vB`}</span>
                  </Flex>
                </Spin>
              );
            }
          })}
        </Flex>
        <Flex className="gasInfoCard" justify="space-between" vertical={true}>
          {/* <Flex align="center" justify="space-between">
            <span style={{ width: '30%', fontSize: isMobile ? 10 : 14 }}>Network Fee:</span>
            <span
              style={{ width: '30%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}
            >{`~${selectFeeValue}sats/vbytes`}</span>
          </Flex> */}
          {takerFee !== 0 ? (
            <Flex align="center" justify="space-between">
              <span style={{ width: '30%', fontSize: isMobile ? 10 : 14 }}>{`Buyer Fee(${takerFee.toFixed(
                2,
              )}%):`}</span>
              <span style={{ width: '30%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}>{`${(
                (allSats.current * takerFee) /
                10000000000
              ).toFixed(8)} BTC`}</span>
              <span style={{ width: '35%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}>{`$${formatSatoshiPrice(
                btcUsdtPrice,
                (allSats.current * takerFee) / 100,
                3,
              )}`}</span>
            </Flex>
          ) : (
            <Flex align="center" justify="space-between">
              <Flex vertical style={{ width: '30%' }}>
                <span style={{ fontSize: isMobile ? 10 : 14 }}>{t('listing.serviceFee')}</span>
              </Flex>
              <span style={{ color: '#30BD65', width: '30%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}>
                {t('listing.timeLimitedFree')}
              </span>
              <span style={{ color: '#30BD65', width: '35%', textAlign: 'end', fontSize: isMobile ? 10 : 14 }}>
                {t('listing.timeLimitedFree')}
              </span>
            </Flex>
          )}
          <Divider style={{ margin: 0 }} />
          <Flex align="center" justify="space-between">
            <span style={{ fontSize: isMobile ? 10 : 16, fontWeight: 500, color: '#30BD65', width: '30%' }}>
              {t('listing.totalCost')}
            </span>
            <span
              style={{
                fontSize: isMobile ? 10 : 16,
                fontWeight: 500,
                color: '#30BD65',
                width: '30%',
                textAlign: 'end',
              }}
            >
              {`～${((allSats.current * 100 + allSats.current * takerFee) / 10000000000).toFixed(8)} BTC`}
            </span>
            <span
              style={{
                fontSize: isMobile ? 10 : 16,
                fontWeight: 500,
                color: '#30BD65',
                width: '35%',
                textAlign: 'end',
              }}
            >
              {`$${formatSatoshiPrice(btcUsdtPrice, allSats.current + (allSats.current * takerFee) / 100, 3)}`}
            </span>
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ height: 80, width: '100%' }} justify="center" align="center" gap={16}>
        {/* <ConfigProvider theme={{ token: { colorPrimary: '#3E444D' } }}>
          <Button
            type="primary"
            onClick={onCancel}
            style={{ boxShadow: 'unset', fontWeight: 700, fontSize: 16, width: 220, height: 48 }}
          >
            Cannel
          </Button>
        </ConfigProvider> */}
        <Button
          type="primary"
          onClick={sweepBulk}
          loading={pending}
          disabled={!isBitcoinMainnetAddress(recevieaddress)}
          style={{
            color: '#1C2025',
            fontWeight: 700,
            fontSize: 16,
            width: isMobile ? 134 : 220,
            height: isMobile ? 32 : 48,
          }}
        >
          {selectTickerList.length > 1 ? t('listing.sweep') : t('listing.buy')}
        </Button>
      </Flex>
    </Modal>
  );
}
